import agent from '../../Agent'
import { RootStore } from '../../stores/RootStore'
import { IBoardInvitationDTO } from '../dto/IBoardInvitationDTO'
import { ActionTypeEnum } from '../../actions/enum/ActionTypeEnum'
import { action } from 'mobx'

export class BoardInvitationsService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @action
  public save(dto: IBoardInvitationDTO) {
    // this.rootStore.boardInvitationsStore.updateFromServer(dto)
    this.rootStore.actionsStore.addAction(ActionTypeEnum.BOARDINVITATION_SAVE, dto)
  }

  @action
  public async saveOnServer(dto: IBoardInvitationDTO) {
    const savedDTO = await agent.BoardInvitations.save(dto)
    // this.rootStore.boardInvitationsStore.updateFromServer(savedDTO)
  }

}
