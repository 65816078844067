import { observable, makeObservable } from 'mobx'
import { serializable } from 'serializr'
import { ICategoryDTO } from '../dtos/ICategoryDTO'

export class Category implements ICategoryDTO {
  public static default(): Category {
    const cat = new Category()
    cat.CategoryGuid = '00000000-0000-0000-0000-000000000000'
    cat.Name = 'Uncategorized'
    return cat
  }

  constructor() {
    makeObservable(this)
  }

  @serializable @observable public CategoryGuid: string = ''
  @serializable @observable public Name: string = ''
  @serializable @observable public Color: string = ''
}
