import React from 'react'
import { observer } from 'mobx-react'
import { IonIcon } from '@ionic/react'
import { ItemNewRowVM } from '../view-models/ItemNewRowVM'
import { add } from 'ionicons/icons'

interface Props {
  row: ItemNewRowVM
  style: any
  index: number
}

const ItemNewRow: React.FC<Props> = ({ row, style }) => {
  return (
    <div style={style} id='ItemNewRow' onClick={() => row.openNewItemModal()}>
      <div className='btn'>
        <IonIcon icon={add} size='small' />
        <div>{row.displayName}</div>
      </div>
      <div className='filler'></div>
    </div>
  )
}

export default observer(ItemNewRow)
