import React, { useState, Fragment } from 'react'
import { IonPopover, IonButton, IonIcon, IonList, IonItem, IonItemDivider } from '@ionic/react'
import { inject, observer } from 'mobx-react'
import { MealsStore } from '../../store/MealsStore'
import { DayGroupVM } from 'src/meals/view-models/list/DayGroupVM'

interface Props {
  row: DayGroupVM
}

const MealSelectMenu: React.FC<Props> = ({ row }) => {

  const renderMenuItems = () => {
    return row.availableMealCategories.map(e => (
      <IonItem key={e.mealCategoryGuid} onClick={() => row.addMeal(e)}>
        &nbsp; {e.name}
      </IonItem>
    ))
  }

  const renderAddNewRow = () => {
    return <IonItem lines='none' onClick={() => row.goToMealsSetup()}>&nbsp; Setup Meals...</IonItem>
  }

  return (
    <>
      <IonPopover isOpen={row.mealMenuShown} event={event} onDidDismiss={e => row.hideMealMenu()}>
        <IonList>
          {renderMenuItems()}
          {renderAddNewRow()}
        </IonList>
      </IonPopover>
    </>
  )
}

export default inject('mealsStore')(observer(MealSelectMenu))
