import React from 'react'
import {
  IonIcon,
  IonMenu,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonMenuToggle,
  IonListHeader,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
} from '@ionic/react'
import './SideMenu.scss'
import { inject, observer } from 'mobx-react'
import { MaterialCommunityIcons, Octicons, AntDesign, EvilIcons } from 'react-web-vector-icons'
import { AppStore } from '../stores/AppStore'
import { faTasks } from '@fortawesome/free-solid-svg-icons'
import { faListAlt } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BackIcon from '../shared/BackIcon'
import Icon from '../assets/images/Icon_512.png'
import { peopleOutline, personCircleOutline } from 'ionicons/icons'

interface Props {
  appStore?: AppStore
}

const SideMenu: React.FC<Props> = ({ appStore }) => {
  const { sideMenuVM } = appStore
  const mainColor = 'medium'

  const renderBackButton = () => {
    if (appStore.isSplitPaneVisible) return null
    return (
      <IonHeader>
        <IonToolbar color={mainColor}>
          <IonButtons slot='start'>
            <IonButton onClick={() => appStore.closeSideMenu()}>
              <BackIcon />
            </IonButton>
          </IonButtons>
          <div id='LogoContainer'>
            <div>
              <div id='g'>Grocery</div>
              <div id='b'>Board</div>
            </div>
            <img src={Icon} alt='Grocery Board' />
          </div>
        </IonToolbar>
      </IonHeader>
    )
  }

  const renderLogo = () => {
    if (!appStore.isSplitPaneVisible) return null
    return (
      <div id='LogoContainer'>
        <img src={Icon} alt='Grocery Board' />
        <div>
          <div id='g'>Grocery</div>
          <div id='b'>Board</div>
        </div>
      </div>
    )
  }

  return (
    <IonMenu
      contentId='main'
      id='SideMenu'
      type={'overlay'}
      swipeGesture={false}
      onIonWillOpen={() => appStore.setMenuOpen(true)}
      onIonWillClose={() => appStore.setMenuOpen(false)}
      ref={(e) => appStore.setSideMenu(e)}
    >
      {renderBackButton()}
      <IonContent color={mainColor} ref={(e) => sideMenuVM.applyScrollStyles(e)}>
        {renderLogo()}

        <IonList color={mainColor}>
          <IonMenuToggle autoHide={false}>
            <IonItem
              lines={'none'}
              key={`shoppinglist-${sideMenuVM.isShoppingListSection}`}
              button
              className={sideMenuVM.isShoppingListSection ? 'active' : ''}
              color={sideMenuVM.isShoppingListSection ? 'tertiary' : mainColor}
              onClick={(e) => {
                e.currentTarget.classList.add('active')
                e.currentTarget.classList.add('ion-color-tertiary')
                appStore.navigateTo('/')
              }}
            >
              <FontAwesomeIcon icon={faTasks} style={{ fontSize: '20px', marginLeft: '2px' }} />
              <IonLabel>Shopping List</IonLabel>
            </IonItem>
          </IonMenuToggle>

          <IonMenuToggle autoHide={false}>
            <IonItem
              lines={'none'}
              key={`mealslist-${sideMenuVM.isRecipesSection}`}
              button
              className={sideMenuVM.isMealsSection ? 'active' : ''}
              color={sideMenuVM.isMealsSection ? 'tertiary' : mainColor}
              onClick={(e) => {
                e.currentTarget.classList.add('active')
                e.currentTarget.classList.add('ion-color-tertiary')
                appStore.navigateTo('/mealslist')
              }}
            >
              <AntDesign name='calendar' size={24} />
              <IonLabel>Planner</IonLabel>
            </IonItem>
          </IonMenuToggle>

          <IonMenuToggle autoHide={false}>
            <IonItem
              lines={'none'}
              button
              key={`recipes-${sideMenuVM.isRecipesSection}`}
              color={sideMenuVM.isRecipesSection ? 'tertiary' : mainColor}
              className={sideMenuVM.isRecipesSection ? 'active' : ''}
              onClick={(e) => {
                e.currentTarget.classList.add('active')
                e.currentTarget.classList.add('ion-color-tertiary')
                appStore.navigateTo('/recipeslist')
              }}
            >
              <Octicons name='book' size={24} />
              <IonLabel>Cookbook</IonLabel>
            </IonItem>
          </IonMenuToggle>

          <IonMenuToggle autoHide={false}>
            <IonItem
              lines={'none'}
              button
              key={`items-${sideMenuVM.isItemsSection}`}
              color={sideMenuVM.isItemsSection ? 'tertiary' : mainColor}
              className={sideMenuVM.isItemsSection ? 'active' : ''}
              onClick={(e) => {
                e.currentTarget.classList.add('active')
                e.currentTarget.classList.add('ion-color-tertiary')
                appStore.navigateTo('/itemslist')
              }}
            >
              <FontAwesomeIcon icon={faListAlt} style={{ fontSize: '20px', marginLeft: '2px' }} fontVariant='regular' />
              <IonLabel>All Items</IonLabel>
            </IonItem>
          </IonMenuToggle>

          <IonMenuToggle autoHide={false}>
            <IonItem
              lines={'none'}
              button
              key={`items-${sideMenuVM.isSettingsSection}`}
              color={sideMenuVM.isSettingsSection ? 'tertiary' : mainColor}
              className={sideMenuVM.isSettingsSection ? 'active' : ''}
              onClick={(e) => {
                e.currentTarget.classList.add('active')
                e.currentTarget.classList.add('ion-color-tertiary')
                appStore.navigateTo('/settings')
              }}
            >
              <EvilIcons name='gear' size={26} />
              <IonLabel style={{ marginLeft: '15px' }}>Settings</IonLabel>
            </IonItem>
          </IonMenuToggle>

          {/* <IonListHeader color={mainColor}>
            <IonLabel>Settings</IonLabel>
          </IonListHeader> */}

          {/* <IonMenuToggle autoHide={false}>
            <IonItem
              button
              lines={'none'}
              color={sideMenuVM.isCategoriesSettingsSection ? 'tertiary' : mainColor}
              className={'nav-boardsettings ' + (sideMenuVM.isCategoriesSettingsSection ? 'active' : '')}
              onClick={() => appStore.navigateTo('/categorieslist')}
            >
              <FontAwesomeIcon icon={faTasks} style={{ fontSize: '20px', marginLeft: '2px' }} />
              <IonLabel>Categories</IonLabel>
            </IonItem>
          </IonMenuToggle>

          <IonMenuToggle autoHide={false}>
            <IonItem
              button
              lines={'none'}
              color={sideMenuVM.isMealCategoriesSettingsSection ? 'tertiary' : mainColor}
              className={'nav-boardsettings ' + (sideMenuVM.isMealCategoriesSettingsSection ? 'active' : '')}
              onClick={() => appStore.navigateTo('/mealcategorieslist')}
            >
              <AntDesign name='calendar' size={24} />
              <IonLabel>Meal Slots</IonLabel>
            </IonItem>
          </IonMenuToggle>

          <IonMenuToggle autoHide={false}>
            <IonItem
              button
              lines={'none'}
              color={sideMenuVM.isRecipeCategoriesSettingsSection ? 'tertiary' : mainColor}
              className={'nav-boardsettings ' + (sideMenuVM.isRecipeCategoriesSettingsSection ? 'active' : '')}
              onClick={() => appStore.navigateTo('/recipecategorieslist')}
            >
              <Octicons name='book' size={24} />
              <IonLabel>Recipe Categories</IonLabel>
            </IonItem>
          </IonMenuToggle> */}

          {/* <IonMenuToggle autoHide={false}>
            <IonItem
              button
              lines={'none'}
              color={sideMenuVM.isAccountSettingsSection ? 'tertiary' : mainColor}
              className={'nav-accountsettings ' + (sideMenuVM.isAccountSettingsSection ? 'active' : '')}
              onClick={() => appStore.navigateTo('/accountsettings')}
            >
              <IonIcon icon={personCircleOutline} />
              <IonLabel>My Account</IonLabel>
            </IonItem>
          </IonMenuToggle>

          <IonMenuToggle autoHide={false}>
            <IonItem
              button
              lines={'none'}
              color={sideMenuVM.isBoardSettingsSection ? 'tertiary' : mainColor}
              className={'nav-boardsettings ' + (sideMenuVM.isBoardSettingsSection ? 'active' : '')}
              onClick={() => appStore.navigateTo('/boardsettings')}
            >
              <IonIcon icon={peopleOutline} />
              <IonLabel>Board Sharing</IonLabel>
            </IonItem>
          </IonMenuToggle>

          <IonMenuToggle autoHide={false}>
            <IonItem
              button
              lines={'none'}
              color={sideMenuVM.isThemesSection ? 'tertiary' : mainColor}
              className={sideMenuVM.isThemesSection ? 'active' : ''}
              onClick={() => appStore.navigateTo('/themeslist')}
            >
              <MaterialCommunityIcons name='brightness-6' size={24} />
              <IonLabel>Theme</IonLabel>
            </IonItem>
          </IonMenuToggle>

          {sideMenuVM.showThemeEditor && (
            <IonMenuToggle autoHide={false}>
              <IonItem
                button
                lines={'none'}
                color={sideMenuVM.isThemeEditSection ? 'tertiary' : mainColor}
                className={sideMenuVM.isThemeEditSection ? 'active' : ''}
                onClick={() => appStore.navigateTo('/themegen')}
              >
                <MaterialCommunityIcons name='pencil' size={24} />
                <IonLabel>Theme Editor</IonLabel>
              </IonItem>
            </IonMenuToggle>
          )} */}
        </IonList>
      </IonContent>
    </IonMenu>
  )
}

export default inject('appStore')(observer(SideMenu))
