import React from 'react'
import {
  IonModal,
  IonTitle,
  IonToolbar,
  IonHeader,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent,
  IonFab,
  IonFabButton,
} from '@ionic/react'
import { observer } from 'mobx-react'
import { close, checkmarkSharp } from 'ionicons/icons'
import { AntDesign } from 'react-web-vector-icons'
import { ItemsSelectListVM } from '../view-models/ItemsSelectListVM'
import BackIcon from '../../shared/BackIcon'
import ItemNewModal from '../../items/views/new/ItemNewModal'
import ItemsSelectList from './ItemsSelectList'

interface Props {
  itemsList: ItemsSelectListVM
}

const ItemsSelectModal: React.FC<Props> = ({ itemsList }) => {
  if (!itemsList) return null
  const vm = itemsList

  const renderFab = () => {
    if (!vm.showFab) return
    return (
      <IonFab vertical='bottom' horizontal='end'>
        <IonFabButton
          color='tertiary'
          onClick={() => vm.done()}
          style={{
            marginTop: '-60px',
            marginLeft: '-60px',
          }}
        >
          <IonIcon icon={checkmarkSharp} />
        </IonFabButton>
      </IonFab>
    )
  }

  const renderNewModal = () => {
    if (!vm.itemNewVM) return
    return <ItemNewModal vm={vm.itemNewVM} />
  }

  return (
    <IonModal
      id='ItemsSelectModal'
      isOpen={vm.shown}
      showBackdrop={true}
      onDidPresent={() => vm.onModalShown()}
      onDidDismiss={() => vm.hide('did dismiss')}
    >
      <IonHeader>
        <IonToolbar color='secondary'>
          <IonButtons slot='start'>
            <IonButton onClick={() => vm.goBack()}>
              <BackIcon />
            </IonButton>
          </IonButtons>
          <IonTitle>{vm.modalTitle}</IonTitle>
          <IonButtons slot='end'>
            <IonButton onClick={() => vm.cancel()}>
              <IonIcon icon={close} />
            </IonButton>
            <IonButton onClick={() => vm.done()}>
              <IonIcon icon={checkmarkSharp} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonToolbar id='ItemsSelect_Toolbar' color='medium'>
        <input
          type='text'
          className='ion-input-md'
          placeholder='Search...'
          value={vm.typedFilter}
          onChange={(e: any) => vm.setFilter(e.target.value)}
        />

        {vm.hasFilter && (
          <div
            slot='end'
            onClick={() => {
              vm.clearFilter()
            }}
          >
            <AntDesign name='closecircleo' color='white' size={18} />
          </div>
        )}
      </IonToolbar>
      <IonContent scrollY={false}>
        <ItemsSelectList itemsList={vm} />
        {renderNewModal()}
      </IonContent>
      {renderFab()}
    </IonModal>
  )
}

export default observer(ItemsSelectModal)
