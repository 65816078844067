import React from 'react'
import { inject, observer } from 'mobx-react'
import { IonItem, IonLabel, IonIcon, IonToggle, IonButton, IonCard, IonCardContent } from '@ionic/react'
import { closeCircle, eye } from 'ionicons/icons'
import { MealCategoryEditVM } from 'src/meal-categories/view-models/edit/MealCategoryEditVM'
import { TimePicker } from '@material-ui/pickers'
import { DayRowVM } from 'src/meal-categories/view-models/edit/DayRowVM'

interface Props {
  editVM: MealCategoryEditVM
}

const VisibleRow: React.FC<Props> = ({ editVM }) => {
  return (
    <IonCard id='DayRow'>
      <IonItem lines='none' onClick={() => editVM.toggleVisible()}>
        <IonToggle slot='start' color='tertiary' checked={editVM.visible} />
        <IonLabel>Visible on My Planner</IonLabel>
        <IonIcon icon={eye} className={editVM.visible ? 'visible' : 'not-visible'} />
      </IonItem>
    </IonCard>
  )
}

export default inject('mealCategoriesStore')(observer(VisibleRow))
