import React from 'react'
import { inject, observer } from 'mobx-react'
import { MealsStore } from '../../store/MealsStore'
import { IonLabel, IonSegment, IonSegmentButton, IonActionSheet } from '@ionic/react'

interface Props {
  mealsStore?: MealsStore
}

const ItemActionSheet: React.FC<Props> = ({ mealsStore }) => {
  const { editVM } = mealsStore
  if (!editVM) return null

  return (
    <IonActionSheet
      isOpen={editVM.itemActionSheetShown}
      onDidDismiss={() => editVM.hideItemActionSheet()}
      buttons={[
        {
          text: 'Delete',
          role: 'destructive',
          icon: 'trash',
          handler: () => {
            console.log('Delete clicked')
          },
        },
        {
          text: 'Share',
          icon: 'share',
          handler: () => {
            console.log('Share clicked')
          },
        },
        {
          text: 'Play (open modal)',
          icon: 'arrow-dropright-circle',
          handler: () => {
            console.log('Play clicked')
          },
        },
        {
          text: 'Favorite',
          icon: 'heart',
          handler: () => {
            console.log('Favorite clicked')
          },
        },
        {
          text: 'Cancel',
          icon: 'close',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked')
          },
        },
      ]}
    />
  )
}

export default inject('mealsStore')(observer(ItemActionSheet))
