import { RecipesService } from '../../services/RecipesService'
import { RecipeCategoryNewVM } from './RecipeCategoryNewVM'
import { RootStore } from '../../../stores/RootStore'
import { computed, observable, action, makeObservable } from 'mobx'
import { Recipe } from '../../aggregate/Recipe'
import { StepRowVM } from './StepRowVM'
import { CategoryRowVM } from './CategoryRowVM'
import { RecipeItemRowVM } from './RecipeItemRowVM'
import { arrayMove } from 'react-sortable-hoc'
import { IRecipeJobResult } from '../../interfaces/IRecipeItemJobResult'

export class RecipeEditVM {
  private rootStore: RootStore
  public isNewRecipe: boolean = false
  private contentRef: HTMLIonContentElement

  constructor(rootStore: RootStore, recipe: Recipe, isNew: boolean = false) {
    makeObservable(this)
    this.rootStore = rootStore
    this.recipe = recipe
    this.isNewRecipe = isNew
    this.categoryNewVM = new RecipeCategoryNewVM(this.rootStore, this)
    this.svc = new RecipesService(rootStore)
    this.rootStore.appStore.listenForSignalRUpdate('recipejobresult', (e) => this.processJobResult(e), true)
    this.loadRecipeItems()
    this.recipeItems.forEach((e) => e.dispatchParseRecipeItem())
    if (window.location.search.includes('fromimport')) this.toggleViewLinkedItems()
  }

  @observable public addCategoryEvent: any = null
  @observable public categoryMenuShown: boolean = false
  @observable public categoryNewVM: RecipeCategoryNewVM = null
  @observable public recipe: Recipe = null
  @observable public deleteConfirmShown: boolean = false
  @observable public currentTabIndex: string = '0'
  @observable public editingRecipeItemGuid: string = null
  @observable public bulkEditMode: boolean = false
  public type: string = 'Recipe'
  @observable public autoSave: boolean = false
  @observable public viewLinkedItems: boolean = false
  public svc: RecipesService
  @observable public recipeItems: RecipeItemRowVM[] = []

  private processJobResult(result: IRecipeJobResult) {
    if (result.JobType === 'Parse') {
      const item = this.recipeItems.find((e) => e.recipeItemGuid === result.RecipeItemGuid)
      if (!item) return
      item.updateParseResult(result)
    }
    if (result.JobType === 'SuggestCategory') {
      const item = this.recipeItems.find((e) => e.recipeItemGuid === result.RecipeItemGuid)
      if (!item) return
      item.updateSuggestedCategoryResult(result)
    }
  }

  @computed
  public get isEditingItem() {
    return Boolean(this.recipeItems.find((e) => e.isEditingItem))
  }

  @action
  public hideItemEditModal() {
    this.recipeItems.find((e) => e.isEditingItem).hideItemEditModal()
  }

  @action
  public setEditingRecipeItem(recipeItemGuid: string) {
    this.editingRecipeItemGuid = recipeItemGuid
  }

  @action
  public clearEditingRecipeItem() {
    this.editingRecipeItemGuid = null
  }

  @action
  public enableAutoSave() {
    this.autoSave = true
  }

  @action
  public toggleViewLinkedItems() {
    this.viewLinkedItems = !this.viewLinkedItems
  }

  @computed
  public get editingRecipeItem(): RecipeItemRowVM {
    return this.recipeItems.find((e) => e.recipeItemGuid === this.editingRecipeItemGuid)
  }

  public setContentRef(e: HTMLIonContentElement) {
    this.contentRef = e
  }

  @computed
  public get steps(): Array<StepRowVM> {
    return this.recipe.Steps.filter((e) => !e.IsDeleted)
      .sort((a, b) => (a.StepNumber < b.StepNumber ? -1 : 0))
      .map((e) => new StepRowVM(this.rootStore, this, e))
  }

  @computed
  public get categories(): Array<CategoryRowVM> {
    return this.rootStore.recipeCategoriesStore.currentBoardRecords
      .sort((a, b) => (a.Name < b.Name ? -1 : 0))
      .map((e) => new CategoryRowVM(this.rootStore, this, e))
  }

  @computed
  public get sortListHeight(): number {
    return this.rootStore.appStore.listHeight - 128
  }

  @computed
  public get selectedCategoriesCount(): number {
    return this.categories.filter((e) => e.isChecked).length
  }

  @action
  private loadRecipeItems() {
    this.recipeItems = this.recipe.RecipeItems.filter((e) => !e.IsDeleted)
      .sort((a, b) => (a.Rank < b.Rank ? -1 : 0))
      .map((e) => new RecipeItemRowVM(this.rootStore, this, e))
  }

  @computed
  public get name(): string {
    if (!this.recipe) return ''
    return this.recipe.Name
  }

  @action
  public setName(val: string) {
    this.recipe.setName(val)
  }

  @computed
  public get isValid(): boolean {
    if (!this.name || this.name === '') return false
    return true
  }

  @computed
  public get linkUrl(): string {
    if (!this.recipe) return ''
    return this.recipe.LinkUrl
  }

  @action
  public setLinkUrl(val: string) {
    this.recipe.setLinkUrl(val)
  }

  @computed
  public get notes(): string {
    if (!this.recipe) return ''
    return this.recipe.Notes
  }

  @action
  public setNotes(val: string) {
    this.recipe.setNotes(val)
  }

  @action
  public toggleCategoryMenu(e) {
    this.addCategoryEvent = e
    this.categoryMenuShown = true
  }

  @action
  public addRecipeItem(description = '', rank: number = 1000) {
    const newItem = this.recipe.addRecipeItem(null, rank)
    newItem.setDescription(description)
    this.recipeItems.push(new RecipeItemRowVM(this.rootStore, this, newItem))
    const newItemRow = this.recipeItems.find((e) => e.recipeItemGuid === newItem.RecipeItemGuid)
    newItemRow.dispatchParseRecipeItem()
    newItemRow.setFocus()
  }

  @computed
  public get lastRecipeItem(): RecipeItemRowVM {
    return this.recipeItems[this.recipeItems.length - 1]
  }

  @action
  public back() {
    this.rootStore.appStore.handleGoBack()
  }

  @computed
  public get recipesStore() {
    return this.rootStore.recipesStore
  }

  public getItemQuantity(itemGuid: string) {
    return 0
  }

  public isGotten(): boolean {
    return false
  }

  public toggleGotten() {}
  public markAsCleared() {}

  @action
  public showCategoryNewModal() {
    this.categoryNewVM.toggleShown()
  }

  @action
  public addStep(description = '', rank: number = 1000) {
    const newStep = this.recipe.addStep(rank)
    newStep.setDescription(description)
    const newStepRow = this.steps.find((e) => e.stepGuid === newStep.StepGuid)
    newStepRow.setFocus()
  }

  @action
  public save() {
    this.svc.save(this.recipe.toDTO())
    if (!this.autoSave) {
      this.rootStore.mealsStore.attachRecipeToDraftMeal(this.recipe.RecipeGuid)
      this.rootStore.appStore.handleGoBack()
    }
  }

  @computed
  public get selectedRecipeItems(): Array<RecipeItemRowVM> {
    const list = this.recipeItems.filter((e) => !e.isDeleted)
    return list
  }

  @action
  public deleteCategory(categoryGuid: string) {
    this.recipe.deleteCategory(categoryGuid)
  }

  @action
  public deleteRecipeItem(recipeItemGuid: string) {
    this.recipe.deleteRecipeItem(recipeItemGuid)
    if (this.recipeItems.length === 0) this.addRecipeItem()
    this.recipeItems.splice(
      this.recipeItems.findIndex((e) => e.recipeItemGuid === recipeItemGuid),
      1
    )
  }

  @action
  public deleteStep(stepGuid: string) {
    this.recipe.deleteStep(stepGuid)
    if (this.steps.length === 0) this.addStep()
  }

  @computed
  public get pageTitle(): string {
    return this.isNewRecipe ? 'Add Recipe' : this.recipe.Name
  }

  @action
  public showDeleteConfirm() {
    this.deleteConfirmShown = true
  }

  @action
  public hideDeleteConfirm() {
    this.deleteConfirmShown = false
  }

  @action
  public setCurrentTab(val: string) {
    if (this.currentTabIndex === val) return
    this.currentTabIndex = val
    this.contentRef.scrollToTop()
  }

  public doneAddingItems() {}

  @action
  public delete() {
    this.svc.delete(this.recipe.toDTO())
    this.rootStore.appStore.handleGoBack()
  }

  @computed
  public get selectedRecipeItemsOrdered() {
    return this.selectedRecipeItems.sort((a, b) => (a.rank < b.rank ? -1 : 0))
  }

  @action
  public reorderRecipeItems(oldIndex: number, newIndex: number) {
    const oldRows = this.selectedRecipeItemsOrdered.map((e) => e)
    const newRows = arrayMove(oldRows, oldIndex, newIndex)
    newRows.forEach((e, idx) => e.setRank(idx + 1))
  }

  @action
  public reorderSteps(oldIndex: number, newIndex: number) {
    const oldRows = this.steps.map((e) => e)
    const newRows = arrayMove(oldRows, oldIndex, newIndex)
    newRows.forEach((e, idx) => e.setRank(idx + 1))
  }

  @action
  public async disableAutoComplete(e: any) {
    if (!e) return
    const txt = await e.getInputElement()
    txt.setAttribute('autocomplete', 'something-new')
  }

  @action
  public async openLink() {
    window.open(this.linkUrl)
  }
}
