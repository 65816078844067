import React from 'react'
import { inject, observer } from 'mobx-react'
import { MealCategoriesStore } from '../../store/MealCategoriesStore'
import { IonGrid } from '@ionic/react'
import DayRow from './DayRow'

interface Props {
  mealCategoriesStore?: MealCategoriesStore
}

const DaysGrid: React.FC<Props> = ({ mealCategoriesStore }) => {
  const { editVM } = mealCategoriesStore
  if (!editVM) return null

  const renderRows = () => {
    return editVM.days.map(e => <DayRow editVM={editVM} row={e} key={e.dayIndex} />)
  }

  return <IonGrid id='DaysGrid'>{renderRows()}</IonGrid>
}

export default inject('mealCategoriesStore')(observer(DaysGrid))
