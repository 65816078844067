import React from 'react'
import { observer } from 'mobx-react'
import { MealCategoryRowVM } from '../../view-models/list/MealCategoryRowVM'
import { IonChip, IonTitle, IonCardHeader, IonCard, IonCardContent, IonIcon, IonButtons } from '@ionic/react'
import { create, eye } from 'ionicons/icons'

interface Props {
  row: MealCategoryRowVM
  style?: any
}

const MealCategoryRow: React.FC<Props> = ({ row }) => {
  const renderDays = () => {
    if (row.listVM.sortMode) return null
    if (!row.days) return null
    return row.days.map(e => (
      <div className='day' key={e.DayIndex}>
        <IonChip
          className={e.Enabled ? 'enabled1' : 'disabled'}
          color={e.Enabled ? 'tertiary' : 'tertiary'}
          onClick={() => row.openMealCategory()}
        >
          <div>{e.DayLetter}</div>
        </IonChip>
        <div className='time'>{e.Time}</div>
      </div>
    ))
  }

  const renderCardContent = () => {
    if (row.listVM.sortMode) return null
    return (
      <IonCardContent>
        <div className='days-row'>{renderDays()}</div>
      </IonCardContent>
    )
  }

  return (
    <IonCard style={{ borderLeft: 'solid ' + row.color + ' 4px' }}>
      <IonCardHeader color='light' mode='md'>
        <IonTitle className='name' onClick={() => row.openMealCategory()}>
          {row.Name}
        </IonTitle>
        <div className='buttons'>
          {!row.listVM.sortMode && (
            <IonIcon
              icon={eye}
              className={row.visible ? 'visible' : 'not-visible'}
              onClick={() => row.toggleVisible()}
            />
          )}
          {!row.listVM.sortMode && <IonIcon icon={create} onClick={() => row.openMealCategory()} />}
        </div>
      </IonCardHeader>
      {renderCardContent()}
    </IonCard>
  )
}

export default observer(MealCategoryRow)
