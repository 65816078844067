import React from 'react'
import {
  IonList,
  IonItem,
  IonButton,
  IonModal,
  IonLabel,
  IonInput,
  IonSpinner,
  IonToolbar,
  IonTitle,
  IonCardHeader,
  IonCardContent,
  IonCard,
  IonText,
} from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { BoardsStore } from '../store/BoardsStore'

interface Props {
  boardsStore?: BoardsStore
}

const BoardJoinModal: React.FC<Props> = ({ boardsStore }) => {
  const { joinVM: vm } = boardsStore
  if (!vm) return null

  const handleSaveClick = (e) => {
    e.preventDefault()
    vm.joinBoardOnServer()
  }

  const handleCancelClick = (e) => {
    e.preventDefault()
    vm.toggle()
  }

  const handleDismiss = () => {
    if (!vm.isOpen) return
    vm.toggle()
  }

  const renderSpinner = () => {
    if (!vm.isProcessing) return
    return <div id='spinner-container'>{renderActualSpinner()}</div>
  }

  const renderActualSpinner = () => {
    if (vm.isIos) return <IonSpinner />
    return <IonSpinner name='crescent' />
  }

  const renderButtons = () => {
    if (vm.isProcessing) return
    return (
      <div id='buttons'>
        <IonButton type='button' size='small' color='secondary' onClick={handleCancelClick}>
          Cancel
        </IonButton>
        <IonButton type='submit' size='small' color='tertiary' disabled={!vm.isValid}>
          Join
        </IonButton>
      </div>
    )
  }

  return (
    <IonModal id='BoardJoinModal' isOpen={vm.isOpen} showBackdrop={true} onDidDismiss={handleDismiss}>
      <IonCard>
        <IonCardHeader>
          <IonTitle>Join Board</IonTitle>
        </IonCardHeader>
        <IonCardContent>
          <form onSubmit={handleSaveClick}>
            <IonList>
              <IonItem lines={'inset'}>
                <IonLabel position='floating'>Enter Invite Code</IonLabel>
                <IonInput
                  type='text'
                  className='ion-input-md'
                  maxlength={7}
                  value={vm.invitationCode}
                  onIonChange={(e: any) => vm.setInvitationCode(e.target.value)}
                  ref={(e) => vm.setInput(e)}
                />
              </IonItem>
              {renderButtons()}
              {renderSpinner()}
            </IonList>
          </form>
          <IonText>
            To join another person's board, ask the other user to provide you with their board's{' '}
            <span>6-Digit Invite Code</span>. This can be found on <span>Board Sharing</span>.
            <br />
            <br />
            Alternatively from Board Sharing they can invite you by your email address:
            <br />
            <span>{vm.userEmailAddress}</span>
            <br />
            <br />
            Once they do, you should see a red invite alert at the top of Grocery Board.
          </IonText>
        </IonCardContent>
      </IonCard>
    </IonModal>
  )
}

export default inject('boardsStore')(observer(BoardJoinModal))
