import { RootStore } from 'src/stores/RootStore'
import { computed, action } from 'mobx'
import { MealCategory } from '../../aggregate/MealCategory'
import { MealCategoriesListVM } from './MealCategoriesListVM'
import { MealCategoriesService } from '../../services/MealCategoriesService'
import { DayVM } from './DayVM'

export class MealCategoryRowVM {
  private rootStore: RootStore
  private mealCategory: MealCategory
  public listVM: MealCategoriesListVM

  constructor(rootStore: RootStore, listVM: MealCategoriesListVM, mealCategory: MealCategory) {
    this.rootStore = rootStore
    this.mealCategory = mealCategory
    this.listVM = listVM
  }

  @computed
  public get Name(): string {
    return this.mealCategory.Name
  }

  @computed
  public get specificTime(): boolean {
    return this.mealCategory.SpecificTime
  }

  @computed
  public get MealCategoryGuid(): string {
    return this.mealCategory.MealCategoryGuid
  }

  @computed
  public get color(): string {
    return this.mealCategory.Color
  }

  @action
  public setRank(val: number) {
    this.mealCategory.setRank(val)
  }

  @computed
  public get Rank(): number {
    return this.mealCategory.Rank
  }

  @computed
  public get time12Hr(): string {
    return this.mealCategory.Time12Hr
  }

  @computed
  public get days(): DayVM[] {
    return this.mealCategory.Days.map(e => new DayVM(this.rootStore, this, e)).sort((a, b) => a.DayIndex - b.DayIndex)
  }

  @computed
  public get visible(): boolean {
    const found = this.mealCategory.VisibleUsers.find(e => e.AppUserId === this.rootStore.userStore.user.AppUserId)
    if (!found) return false
    return found.Enabled
  }

  @action
  public async toggleVisible() {
    this.mealCategory.toggleVisible(this.rootStore.userStore.user.AppUserId)
    const svc = new MealCategoriesService(this.rootStore)
    await svc.save(this.mealCategory.toDTO())
  }

  @action
  public openMealCategory() {
    this.rootStore.appStore.history.push('/mealcategoryedit/' + this.mealCategory.MealCategoryGuid)
  }

  public toDTO() {
    return this.mealCategory.toDTO()
  }
}
