import React from 'react'
import Tab from '@material/react-tab'
import TabBar from '@material/react-tab-bar'
import { inject, observer } from 'mobx-react'
import { AppStore } from 'src/stores/AppStore'
import { IonLabel, IonToolbar } from '@ionic/react'
import { AntDesign, Octicons } from 'react-web-vector-icons'
import { faTasks } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  appStore?: AppStore
}

const BottomTabs: React.FC<Props> = ({ appStore }) => {
  if (appStore.isSplitPaneVisible) return null
  if (!appStore.showAppHeader) return null

  return (
    <IonToolbar id='BottomTabs' color='primary' ref={() => setTimeout(() => appStore.computeBottomNavBarHeight(), 500)}>
      <TabBar activeIndex={appStore.currentTabIndex} handleActiveIndexUpdate={(idx) => appStore.setCurrentTab(idx)}>
        <Tab>
          <FontAwesomeIcon icon={faTasks} />
          <IonLabel color='white'>Shopping List</IonLabel>
        </Tab>
        <Tab>
          <AntDesign name='calendar' size={20} color={'white'} />
          <IonLabel color='white'>Planner</IonLabel>
        </Tab>
        <Tab>
          <Octicons name='book' size={20} color={'white'} />
          <IonLabel color='white'>Cookbook</IonLabel>
        </Tab>
      </TabBar>
    </IonToolbar>
  )
}

export default inject('appStore')(observer(BottomTabs))
