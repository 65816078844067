import { RootStore } from '../../stores/RootStore'
import generateUUID from '../../utils/UUID'
import Agent from '../../Agent'
import { IRecipeImportResult } from '../interfaces/IRecipeImportResult'

export class RecipeImportsService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public async import(url: string): Promise<IRecipeImportResult> {
    const jobId = generateUUID()
    const options = { JobId: jobId, Url: url, BoardId: this.rootStore.boardsStore.currentBoardId }
    const result = await Agent.RecipeImports.import(options)
    return result
  }
}
