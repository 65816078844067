import React from 'react'
import { inject, observer } from 'mobx-react'
import RecipeRow from './RecipeRow'
import { AppStore } from 'src/stores/AppStore'
import RecipeGroupRow from './RecipeGroupRow'
import { MealsStore } from 'src/meals/store/MealsStore'
import { RecipeRowVM } from '../../view-models/edit/RecipeRowVM'
import { RecipeGroupRowVM } from '../../view-models/edit/RecipeGroupRowVM'
import { List } from '@elexient/elexiapp.bits.shared'
import RecipeNewRow from './RecipeNewRow'
import { RecipeNewRowVM } from '../../view-models/edit/RecipeNewRowVM'

interface Props {
  mealsStore?: MealsStore
  appStore?: AppStore
}

const RecipeSelectList: React.FC<Props> = ({ mealsStore, appStore }) => {
  const { editVM } = mealsStore
  if (!editVM) return null
  const listVM = editVM.recipeSelectList
  if (!listVM) return null
  if (appStore.bodyHeight === 0) return null

  const renderNormalRow = (row: RecipeRowVM, index, style) => {
    return <RecipeRow key={'rr' + index} row={row} style={style} />
  }

  const renderGroupRow = (row: RecipeGroupRowVM, index, style) => {
    return <RecipeGroupRow key={'rgrp' + index} row={row} style={style} />
  }

  const renderNewRow = (row: RecipeNewRowVM, index, style) => {
    return <RecipeNewRow key={'rgrp' + index} row={row} style={style} />
  }

  return (
    <List
      listVM={listVM}
      className='list'
      normalRow={(row, index, style) => renderNormalRow(row, index, style)}
      groupRow={(row, index, style) => renderGroupRow(row, index, style)}
      newRow={(row, index, style) => renderNewRow(row, index, style)}
      onRef={(e) => appStore.applyScrollStyles(e)}
    />
  )
}

export default inject('mealsStore', 'appStore')(observer(RecipeSelectList))
