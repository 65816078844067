import React from 'react'
import { IonContent, IonList, IonItem, IonLabel, IonInput, IonButton, IonPage, IonText } from '@ionic/react'
import { inject, observer } from 'mobx-react'
import { AppStore } from 'src/stores/AppStore'
import './ResetPasswordPage.scss'
import { match } from 'react-router'
import { AuthStore } from 'src/login/store/AuthStore'
import Logo512 from '../assets/images/Logo_512.png'
import { UserStore } from '../user/store/UserStore'

interface Props {
  appStore: AppStore
  authStore: AuthStore
  userStore: UserStore
  history: any
  match: match
}

@inject('appStore')
@inject('userStore')
@inject('authStore')
@observer
export default class ResetPasswordPage extends React.Component<Props, any> {
  constructor(props) {
    super(props)
    this.props.userStore.loadResetPasswordForm(this.props.match.params['token'])
  }

  componentDidMount() {
    this.props.authStore.logout()
    this.props.appStore.setCurrentRoute('/reset-password')
    this.props.appStore.themeGenVM.loadTheme('default')
  }

  async handleSubmit(e) {
    e.preventDefault()
    const success = await this.props.userStore.resetPasswordForm.save()
    if (success) {
      alert('success')
      this.props.history.push('/login')
    }
  }

  handlePasswordChange(e) {
    this.props.userStore.resetPasswordForm.password = e.target.value
  }

  handlePasswordConfirmChange(e) {
    this.props.userStore.resetPasswordForm.passwordConfirm = e.target.value
  }

  handleBackClick() {
    this.props.history.push('/login')
  }

  renderErrorMessages() {
    const { resetPasswordForm: form } = this.props.userStore
    const el = []
    form.errorMessages.forEach((e, idx) => {
      el.push(
        <span className='error-message' key={'msg' + idx}>
          {e}
        </span>
      )
      el.push(<br key={'br' + idx} />)
    })
    return el
  }

  render() {
    const { resetPasswordForm: form } = this.props.userStore

    return (
      <IonPage id='ResetPasswordPage'>
        <IonContent>
          <div id='LogoContainer'>
            <img src={Logo512} alt='Grocery Board' />
          </div>

          <IonText><strong>Enter your new password</strong></IonText>

          <br />

          <form onSubmit={this.handleSubmit.bind(this)} autoComplete='off'>
            <IonList>
              <IonItem>
                <IonLabel position='floating'>New Password</IonLabel>
                <IonInput
                  type='text'
                  name='NewPassword'
                  autocomplete='off'
                  value={form.password}
                  onIonChange={this.handlePasswordChange.bind(this)}
                />
              </IonItem>
              <IonItem>
                <IonLabel position='floating'>Confirm Password</IonLabel>
                <IonInput
                  type='text'
                  value={form.passwordConfirm}
                  autocomplete='off'
                  name='NewPasswordConfirm'
                  onIonChange={this.handlePasswordConfirmChange.bind(this)}
                />
              </IonItem>
            </IonList>

            {this.renderErrorMessages()}

            <IonButton expand='full' type='submit' color='tertiary' disabled={!form.isValid}>
              Change Password
            </IonButton>
            <br />
            <IonButton expand='full' type='button' color='secondary' onClick={() => this.handleBackClick()}>
              Back to Login
            </IonButton>
          </form>
        </IonContent>
      </IonPage>
    )
  }
}
