import { computed, observable, action, reaction, makeObservable, override } from 'mobx'
import { ItemRowVM } from './ItemRowVM'
import { ItemGroupRowVM } from './ItemGroupRowVM'
import { Capacitor } from '@capacitor/core'
import { ListVM } from '@elexient/elexiapp.bits.shared'
import { RootStore } from '../../../stores/RootStore'
import { Item } from '../../aggregate/Item'
import { ItemNewVM } from '../new/ItemNewVM'
import { ItemNewRowVM } from './ItemNewRowVM'

export class ItemsListVM extends ListVM<RootStore, Item, ItemGroupRowVM, ItemRowVM, ItemNewRowVM> {
  private searchTextbox: HTMLInputElement

  constructor(rootStore: RootStore) {
    super(rootStore, ItemRowVM, ItemGroupRowVM, ItemNewRowVM)
    this.rootStore = rootStore
    this.autoGrow = true
    this.startCollapsed = true
    this.stickyHeaders = true
    this.endOptionsWidth = 78
    this.didScrollOffset = 20
    this.setGroups('categoryName', '')
    this.addReaction(
      reaction(
        () => this.rootStore.appStore.listHeight,
        () => this.setHeight(this.rootStore.appStore.listHeight),
        { delay: 200 }
      )
    )
    this.addReaction(
      reaction(
        () => this.rootStore.itemsStore.recordJustUpdated,
        () => this.reloadRows()
      )
    )
    this.addReaction(
      reaction(
        () => this.rootStore.itemsStore.currentBoardRecords.length,
        () => this.reloadRows()
      )
    )
    this.setHeight(this.rootStore.appStore.listHeight)

    // if (!backClosesImmediately) {
    // window.addEventListener(
    //   'hashchange',
    //   () => {
    //     if (this.filter !== '') {
    //       if (window.location.hash.indexOf('/hasfilter') === -1 && window.location.hash.indexOf('/newitem') === -1) {
    //         this.clearFilter() // can this be goBack()?
    //       }
    //     }
    //   },
    //   true
    // )
    // }
  }

  protected aggregateSortFunction = (a: Item, b: Item): number => {
    const aVal = a.Name.toLowerCase()
    const bVal = b.Name.toLowerCase()
    if (aVal < bVal) return -1
    return 0
  }

  protected getRecords() {
    return this.rootStore.itemsStore.currentBoardRecords
  }

  @observable public shown: boolean = false
  @observable public showFab: boolean = true
  public lastScrollTop: number = 0
  @observable public shouldShowToTopFab: boolean = false
  @observable public itemMenuShown: boolean = false
  @observable public itemNewVM: ItemNewVM = null

  @computed
  public get searchPromptText() {
    if (this.rootStore.boardsStore.currentBoard?.BoardUsers?.length > 1) return 'We need...'
    return 'I need...'
  }

  @action
  public goBack(force: boolean = false) {
    if (this.hasFilter) {
      this.clearFilter()
      if (!force) return
    }
    this.rootStore.appStore.handleGoBack()
  }

  @override
  public setFilter(val) {
    this.startCollapsed = !Boolean(val)
    this.group1CollapsedNames = []
    super.setFilter(val)
  }

  @override
  public clearFilter() {
    this.startCollapsed = true
    this.group1CollapsedNames = []
    super.clearFilter()
  }

  @action
  public done() {
    if (this.filteredRows.length === 0) {
      const row: ItemNewRowVM = this.allRows[0] as any
      row.openNewItemModal()
    } else {
      this.clearFilter()
      this.rootStore.appStore.history.push('/')
    }
  }

  @action
  public goToCategoriesSetup() {
    this.rootStore.appStore.history.push('/categorieslist')
  }

  @action
  public setSearchTextbox(e: HTMLInputElement) {
    if (!e) return
    let doFocus = false
    if (!this.searchTextbox) doFocus = true
    this.searchTextbox = e
  }

  @action
  public checkForNoItems() {
    if (this.rootStore.itemsStore.currentBoardRecords.length === 0) {
      alert('GO TO NEW ITEM PAGE DUDE')
      // this.newItemRow.openNewItemModal()
    }
  }

  public focusSearchTextbox() {
    if (!this.searchTextbox) return
    if (this.rootStore.appStore.backJustPressed) return
    this.searchTextbox.focus()
  }

  @action
  public showToTopFab() {
    this.shouldShowToTopFab = true
  }

  @action
  public hideToTopFab() {
    this.shouldShowToTopFab = false
  }

  @action
  public scrollToTop() {
    this.listRef.scrollTo(0)
    this.atTop = true
  }

  @action
  public clearListRef() {
    this.listRef = null
  }

  @action
  public openItemMenu() {
    this.itemMenuShown = true
  }

  @action
  public hideItemMenu() {
    this.itemMenuShown = false
  }

  @action
  public deleteItem() {
    this.itemMenuShown = false
  }
}
