import React from 'react'
import {
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonPage,
  IonText,
  IonCard,
  IonCardContent,
  IonSpinner,
} from '@ionic/react'
import { inject, observer } from 'mobx-react'
import './ForgotPasswordPage.scss'
import { UserStore } from '../user/store/UserStore'
import Logo512 from '../assets/images/Logo_512.png'
import { AppStore } from '../stores/AppStore'

interface Props {
  appStore: AppStore
  userStore: UserStore
  history: any
}

@inject('appStore')
@inject('userStore')
@observer
export default class ForgotPasswordPage extends React.Component<Props, any> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.appStore.setCurrentRoute('/forgot-password')
    if (this.props.appStore.isLoggedIn) this.props.history.push('/')
    this.props.appStore.themeGenVM.loadTheme('default')
  }

  async handleSubmit(e) {
    e.preventDefault()
    const { forgotPasswordVM: vm } = this.props.userStore
    vm.save()
  }

  handleEmailChange(e) {
    const { forgotPasswordVM: form } = this.props.userStore
    form.setEmail(e.target.value)
  }

  handleBackClick() {
    this.props.history.push('/login')
  }

  renderErrorMessages() {
    const { forgotPasswordVM: form } = this.props.userStore
    const el = []
    form.errorMessages.forEach((e, idx) => {
      el.push(
        <span className='error-message' key={'msg' + idx}>
          {e}
        </span>
      )
      el.push(<br key={'br' + idx} />)
    })
    return el
  }

  renderSuccess() {
    const { forgotPasswordVM: vm } = this.props.userStore
    if (!vm.success) return
    return <span className='success-message'>Success</span>
  }

  renderSpinner() {
    const { forgotPasswordVM: vm } = this.props.userStore
    if (!vm.isProcessing) return
    if (vm.isIos) return <IonSpinner />
    return <IonSpinner name='crescent' />
  }

  render() {
    const { forgotPasswordVM: vm } = this.props.userStore
    if (!vm) return null

    return (
      <IonPage id='ForgotPasswordPage'>
        <IonContent>
          <div id='LogoContainer'>
            <img src={Logo512} alt='Grocery Board' />
          </div>

          <IonCard>
            <IonCardContent>
              <form onSubmit={this.handleSubmit.bind(this)}>
                <IonList>
                  <IonItem>
                    <IonLabel position='floating'>Email</IonLabel>
                    <IonInput
                      type='text'
                      id='email2'
                      value={vm.email}
                      onIonChange={this.handleEmailChange.bind(this)}
                    />
                  </IonItem>
                </IonList>

                {this.renderErrorMessages()}
                {this.renderSuccess()}
                {this.renderSpinner()}

                <IonButton expand='full' type='submit' id='ResetPasswordButton'>
                  Reset Password
                </IonButton>
                <br />
                <IonButton expand='full' type='button' id='BackButton' onClick={() => this.handleBackClick()}>
                  Back to Login
                </IonButton>
              </form>
            </IonCardContent>
          </IonCard>
        </IonContent>
      </IonPage>
    )
  }
}
