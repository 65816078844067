import React from 'react'
import { Observer, observer } from 'mobx-react'
import { IonItem, IonIcon, IonRippleEffect, IonLabel, IonCheckbox } from '@ionic/react'
import { add, cart, remove, checkmark } from 'ionicons/icons'
import { faTasks } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Feather } from 'react-web-vector-icons'
import { ItemRowVM } from '../../view-models/list/ItemRowVM'
import ItemDeleteConfirm from '../edit/ItemDeleteConfirm'

interface Props {
  row: ItemRowVM
  style: any
  index: number
}

const ItemRow: React.FC<Props> = ({ row, style, index }) => {
  const style2 = {
    borderLeftColor: row.categoryColor,
    ...style,
  }

  const renderCartIcon = () => {
    if (row.iconName !== 'cart') return
    return (
      <div
        className='icon-container checked'
        onClick={(e) => {
          e.stopPropagation()
          row.toggle()
        }}
      >
        <Feather name='circle' size={22} />
        <FontAwesomeIcon
          onClick={(e) => {
            e.stopPropagation()
            row.toggle()
          }}
          icon={faTasks}
          className='cart for-checkbox'
        />
      </div>
    )
  }

  const renderNotes = () => {
    if (!row.hasNotes) return
    return <div className='notes'>{row.notes}</div>
  }

  const renderGottenIcon = () => {
    if (!row.isGotten) return
    if (row.iconName === 'checkmark') return
    return (
      <div
        className='icon-container checked'
        unselectable='on'
        onClick={(e) => {
          e.stopPropagation()
          row.toggle()
        }}
      >
        <Feather name='check-circle' size={22} />
      </div>
    )
  }

  const renderCheckmarkIcon = () => {
    if (row.iconName !== 'checkmark') return
    return (
      <div
        className='icon-container checked'
        unselectable='on'
        onClick={(e) => {
          e.stopPropagation()
          row.toggle()
        }}
      >
        <Feather name='check-circle' size={22} />
      </div>
    )
  }

  const renderNotGottenIcon = () => {
    if (row.iconName === 'cart') return
    if (row.iconName === 'checkmark') return
    if (row.isGotten) return
    return (
      <div
        className='icon-container'
        unselectable='on'
        onClick={(e) => {
          e.stopPropagation()
          row.toggle()
        }}
      >
        <Feather name='plus-circle' size={22} />
      </div>
    )
  }

  const renderDeleteConfirm = () => {
    return (
      <ItemDeleteConfirm
        isOpen={row.deleteConfirmShown}
        onDidDismiss={() => row.hideDeleteConfirm()}
        onDeleteConfirmed={(shoppingListOnly) => row.delete(shoppingListOnly)}
        hasQuantity={row.hasQuantity}
        itemName={row.name}
      />
    )
  }

  const renderRightOptions = () => {
    return (
      <div id='DeleteContainer' onClick={() => row.showDeleteConfirm()}>
        DELETE
      </div>
    )
  }

  return (
    <div style={style2} id='ItemRow'>
      <div id='MainContainer' ref={(e) => row.setSlidingRef(e)} onClick={() => row.click()} className={row.className}>
        {renderGottenIcon()}
        {renderNotGottenIcon()}
        {renderCheckmarkIcon()}
        {renderCartIcon()}
        <div className='name' unselectable='on'>
          {row.name}
          {renderNotes()}
        </div>
      </div>
      {renderRightOptions()}
      {renderDeleteConfirm()}
    </div>
  )
}

export default observer(ItemRow)
