import React from 'react'
import { IonList, IonItem, IonButton, IonLabel, IonItemDivider, IonInput, IonIcon } from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { BoardsStore } from '../store/BoardsStore'
import { copy, refresh } from 'ionicons/icons'

interface Props {
  boardsStore?: BoardsStore
}

const InvitationCodeRow: React.FC<Props> = ({ boardsStore }) => {
  const { editVM: vm } = boardsStore
  if (!vm) return null

  const handleSave = e => {
    e.preventDefault()
    vm.save()
  }

  const renderNoInvitationCode = () => {
    if (vm.hasInvitationCode) return
    return (
      <IonItem lines={'inset'}>
        <IonLabel>
          <IonButton color='primary' onClick={() => vm.generateInvitationCode()}>
            Generate
          </IonButton>
        </IonLabel>
      </IonItem>
    )
  }

  const renderInvitationCode = () => {
    if (!vm.hasInvitationCode) return
    return (
      <IonItem lines={'inset'}>
        <IonInput
          type='text'
          readonly
          placeholder='Enter Code'
          name='jsakasdflfhdjkl'
          value={vm.invitationCode}
          autocomplete='off'
          onFocus={(e: any) => e.nativeEvent.target.select()}
        />
        <IonButton color='primary' onClick={() => vm.generateInvitationCode()}>
          <IonIcon icon={refresh} />
        </IonButton>
        <IonButton color='tertiary' onClick={() => vm.copyInvitationCode()}>
          <IonIcon icon={copy} />
        </IonButton>
      </IonItem>
    )
  }

  return (
    <IonList mode='md'>
      <IonItemDivider color='medium' mode='md'>
        Invite Code
      </IonItemDivider>
      {renderNoInvitationCode()}
      {renderInvitationCode()}
    </IonList>
  )
}

export default inject('boardsStore')(observer(InvitationCodeRow))
