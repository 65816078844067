import React from 'react'
import { inject, observer } from 'mobx-react'
import { IonItem, IonLabel, IonIcon, IonToggle, IonButton, IonCard, IonCardContent } from '@ionic/react'
import { closeCircle } from 'ionicons/icons'
import { MealCategoryEditVM } from 'src/meal-categories/view-models/edit/MealCategoryEditVM'
import { TimePicker } from '@material-ui/pickers'
import { DayRowVM } from 'src/meal-categories/view-models/edit/DayRowVM'

interface Props {
  row: DayRowVM
  editVM: MealCategoryEditVM
}

const DayRow: React.FC<Props> = ({ row }) => {
  const renderTimePicker = () => {
    if (!row.enabled) return
    if (!row.specificTime) return
    return (
      <TimePicker
        slot='end'
        minutesStep={15}
        disabled={!row.enabled}
        value={row.timeAsDate}
        onChange={e => row.setTime(e.format('H:mm'))}
        format='hh:mm a'
      />
    )
  }

  const renderSetSpecificTime = () => {
    if (!row.enabled) return
    if (row.specificTime) return
    return (
      <IonButton
        slot='end'
        size='small'
        color='tertiary'
        onClick={e => {
          e.stopPropagation()
          row.toggleSpecificTime()
        }}
      >
        Set Special Time
      </IonButton>
    )
  }

  const renderClearIcon = () => {
    if (!row.enabled) return
    if (!row.specificTime) return
    return (
      <IonIcon
        slot='end'
        onClick={e => {
          e.stopPropagation()
          row.clearTime()
        }}
        color='tertiary'
        icon={closeCircle}
      />
    )
  }

  const renderContent = () => {
    if (!row.enabled) return
    if (!row.specificTime) return
    return (
      <div className='end' onClick={e => e.stopPropagation()} slot='end'>
        {renderTimePicker()}
        {renderClearIcon()}
      </div>
    )
  }

  return (
    <IonCard id='DayRow'>
      <IonItem lines='none' onClick={() => row.toggleChecked()}>
        <IonToggle slot='start' color='tertiary' checked={row.enabled} />
        <IonLabel>{row.name}</IonLabel>
        {renderSetSpecificTime()}
        {renderContent()}
      </IonItem>
    </IonCard>
  )
}

export default inject('mealCategoriesStore')(observer(DayRow))
