import React, { useState } from 'react'
import { IonAlert } from '@ionic/react'

interface Props {
  hasQuantity: boolean
  isOpen: boolean
  itemName: string
  onDidDismiss: () => void
  onDeleteConfirmed: (shoppingListOnly: boolean) => void
}

const ItemDeleteConfirm: React.FC<Props> = ({ hasQuantity, onDeleteConfirmed, isOpen, onDidDismiss, itemName }) => {
  const [shoppingListOnly, setshoppingListOnly] = useState(hasQuantity)

  const renderRemoveFromShoppingListConfirm = () => {
    if (!hasQuantity) return null
    return (
      <IonAlert
        isOpen={isOpen}
        onDidDismiss={onDidDismiss}
        header={'Delete Item'}
        inputs={[
          {
            type: 'radio',
            label: 'Remove from Shopping List',
            value: 'remove',
            checked: shoppingListOnly === true,
            handler: () => setshoppingListOnly(true),
          },
          {
            type: 'radio',
            label: 'Delete item Permanently',
            value: 'delete',
            checked: shoppingListOnly === false,
            handler: () => setshoppingListOnly(false),
          },
        ]}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => onDidDismiss,
          },
          {
            text: 'Ok',
            handler: () => onDeleteConfirmed(shoppingListOnly),
          },
        ]}
      />
    )
  }

  const renderDeleteConfirm = () => {
    if (hasQuantity) return null
    return (
      <IonAlert
        isOpen={isOpen}
        onDidDismiss={onDidDismiss}
        header={'Delete Item'}
        message={'Are you sure you wish to delete ' + itemName}
        buttons={[
          {
            text: 'No',
            role: 'cancel',
            cssClass: 'primary',
            handler: onDidDismiss,
          },
          {
            text: 'Yes',
            handler: () => onDeleteConfirmed(shoppingListOnly),
          },
        ]}
      />
    )
  }

  return (
    <>
      {renderDeleteConfirm()}
      {renderRemoveFromShoppingListConfirm()}
    </>
  )
}

export default ItemDeleteConfirm
