import { computed, action, makeObservable } from 'mobx'
import { QuantityType } from 'src/quantities/service/QuantityType'
import { RecipeItemRowVM } from './RecipeItemRowVM'

export class QuantityTypeVM {
  private quantityType: QuantityType
  private rowVM: RecipeItemRowVM

  constructor(rowVM: RecipeItemRowVM, quantityType: QuantityType) {
    makeObservable(this)
    this.quantityType = quantityType
    this.rowVM = rowVM
  }

  @computed
  public get name(): string {
    return this.quantityType.abbr
  }

  @computed
  public get abbr(): string {
    return this.quantityType.abbr
  }

  @action
  public setValue(val: number) {
    this.quantityType.setValue(val)
  }

  @computed
  public get isSelected(): boolean {
    return this.abbr === this.rowVM.quantityTypeAbbr
  }
}
