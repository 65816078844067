import { Recipe } from '../../recipes/aggregate/Recipe'
import { RootStore } from 'src/stores/RootStore'
import { computed } from 'mobx'
import { RecipeItem } from 'src/recipes/aggregate/RecipeItem'
import { Meal } from 'src/meals/aggregate/Meal'
import { Item } from '../../items/aggregate/Item'

export class FutureItemVM {
  private rootStore: RootStore
  private recipeItem: RecipeItem
  private recipe: Recipe
  private meal: Meal
  private item: Item

  constructor(rootStore: RootStore, meal: Meal, recipe?: Recipe, recipeItem?: RecipeItem, item?: Item) {
    this.rootStore = rootStore
    this.meal = meal
    this.recipe = recipe
    this.recipeItem = recipeItem
    this.item = item
  }

  @computed
  public get fullDescription(): string {
    if (this.recipeItem) {
      return (
        this.recipeItemDescription +
        ' ' +
        this.recipe.Name +
        ' ' +
        this.mealCategoryName +
        ' on ' +
        this.plannedDate
      ).trimLeft()
    }
    return this.mealCategoryName + ' on ' + this.plannedDate
  }

  @computed
  public get mealDescription(): string {
    if (this.meal.Description.length > 20) return this.meal.Description.substr(0, 20) + '...'
    return this.meal.Description
  }

  @computed
  public get mealCategoryName(): string {
    const foundMealCategory = this.rootStore.mealCategoriesStore.get(this.meal.MealCategoryGuid)
    if (!foundMealCategory) return ''
    return foundMealCategory.Name
  }

  @computed
  public get itemDescription(): string {
    if (!this.item) return ''
    return this.item.Name
  }

  @computed
  public get recipeItemDescription(): string {
    if (this.recipeItem.Description === this.itemDescription) return ''
    return this.recipeItem.Description + ' for '
  }

  @computed
  public get plannedDate(): string {
    return this.meal.localPlannedDateTime.format('ddd M/D')
  }
}
