import React from 'react'
import { observer } from 'mobx-react'
import { CategoryRowVM } from '../../view-models/CategoryRowVM'
import { IonItem, IonCard, IonCardContent } from '@ionic/react'

interface Props {
  row: CategoryRowVM
}

const CategoryRow: React.FC<Props> = ({ row }) => {
  return (
    <IonCard
      id='CategoryRow'
      onClick={() => row.openCategory()}
      style={{ borderLeftColor: row.color ? row.color : '#777' }}
    >
      <IonCardContent>{row.name}</IonCardContent>
    </IonCard>
  )
}

export default observer(CategoryRow)
