import { RootStore } from '../../../stores/RootStore'
import { computed, observable, action, makeObservable } from 'mobx'
import { MealCategory } from '../../aggregate/MealCategory'
import { MealCategoriesService } from '../../services/MealCategoriesService'
import moment from 'moment'
import { DayRowVM } from './DayRowVM'

export class MealCategoryEditVM {
  private rootStore: RootStore
  private mealCategory: MealCategory

  constructor(rootStore: RootStore, mealCategory: MealCategory) {
    this.rootStore = rootStore
    this.mealCategory = mealCategory
    makeObservable(this)
  }

  @observable public deleteConfirmShown: boolean = false

  @computed
  public get days(): DayRowVM[] {
    return this.mealCategory.Days.slice().sort((a, b) => a.DayIndex - b.DayIndex).map(
      e => new DayRowVM(this.rootStore, this, e)
    )
  }

  @computed
  public get showDeleteButton(): boolean {
    return !this.mealCategory.isNew
  }

  @computed
  public get color(): string {
    if (!this.mealCategory.Color) return ''
    return this.mealCategory.Color
  }

  @action
  public setColor(val) {
    this.mealCategory.setColor(val)
  }

  @action
  public setName(val) {
    this.mealCategory.setName(val)
  }

  @computed
  public get name(): string {
    return this.mealCategory.Name
  }

  @computed
  public get isValid(): boolean {
    if (!this.name || this.name === '') return false
    return true
  }

  @action
  public async save() {
    const svc = new MealCategoriesService(this.rootStore)
    await svc.save(this.mealCategory.toDTO())
    this.rootStore.appStore.history.push('/mealcategorieslist')
  }

  @action
  public showDeleteConfirm() {
    this.deleteConfirmShown = true
  }

  @action
  public hideDeleteConfirm() {
    this.deleteConfirmShown = false
  }

  public delete() {
    const svc = new MealCategoriesService(this.rootStore)
    svc.delete(this.mealCategory.toDTO())
    this.rootStore.appStore.handleGoBack()
  }

  @computed
  public get pageTitle(): string {
    if (this.mealCategory.isNew) return 'New Meal Slot'
    return 'Edit Meal Slot'
  }

  @computed
  public get time(): string {
    if (!this.mealCategory.Time) return ''
    return this.mealCategory.Time
  }

  @computed
  public get timeAsDate(): Date {
    return moment(this.time, 'H:mm a').toDate()
  }

  @action
  public setTime(val: string) {
    this.mealCategory.setTime(val)
  }

  @computed
  public get specificTime(): boolean {
    return this.mealCategory.SpecificTime
  }

  @action
  public toggleSpecificTime() {
    this.mealCategory.toggleSpecificTime()
  }

  @computed
  public get visible(): boolean {
    const found = this.mealCategory.VisibleUsers.find(e => e.AppUserId === this.rootStore.userStore.user.AppUserId)
    if (!found) return false
    return found.Enabled
  }

  @action
  public async toggleVisible() {
    this.mealCategory.toggleVisible(this.rootStore.userStore.user.AppUserId)
  }
}
