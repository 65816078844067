import { observable, action, computed, makeObservable } from 'mobx'
import { persist } from 'mobx-persist'
import { IDayDTO } from '../dtos/IDayDTO'
import moment from 'moment'
import { serializable, serialize } from 'serializr'

export class Day implements IDayDTO {
  public static create(name: string, idx: number): Day {
    const day = new Day()
    day.Name = name
    day.DayIndex = idx
    return day
  }

  constructor() {
    makeObservable(this)
  }

  @serializable @observable public Name: string = ''
  @serializable @observable public Enabled: boolean = true
  @serializable @observable public DayIndex: number = 0
  @serializable @observable public Time: string = ''
  @serializable @observable public SpecificTime: boolean = false

  @computed
  public get Time12Hr(): string {
    if (this.Time === '') return ''
    let val = moment(this.Time, 'H:mm').format('h:mma')
    val = val.replace(':00', '')
    val = val.replace('m', '')
    val = val.replace('p', '')
    return val
  }

  @computed
  public get DayLetter(): string {
    return this.Name.substr(0, 1)
  }

  @action
  public setEnabled(val) {
    this.Enabled = val
  }

  @action
  public updateFromServer(day: IDayDTO) {
    this.Enabled = day.Enabled
    this.Time = day.Time
    this.SpecificTime = day.SpecificTime
  }

  @action
  public toggleChecked() {
    this.Enabled = !this.Enabled
  }

  @action
  public setTime(val: string) {
    if (val === '') this.Time = ''
    if (val === '') return
    this.Time = val
  }

  @action
  public toggleSpecificTime() {
    this.SpecificTime = !this.SpecificTime
    if (this.SpecificTime && !this.Time) this.Time = '12:00 pm'
  }

  @action
  public clearTime(): void {
    this.Time = ''
  }

  public toDTO(): IDayDTO {
    return serialize(this)
  }
}
