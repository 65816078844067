import { RootStore } from '../../stores/RootStore'
import { computed, observable, action, reaction, override } from 'mobx'
import { ItemRowVM } from './ItemRowVM'
import { ItemGroupRowVM } from './ItemGroupRowVM'
import { IItemSelectableVM } from '../interfaces/IItemSelectableVM'
import { ItemNewRowVM } from './ItemNewRowVM'
import { ItemNewVM } from '../../items/view-models/new/ItemNewVM'
import { Capacitor } from '@capacitor/core'
import { ListVM } from '@elexient/elexiapp.bits.shared'
import { Item } from '../../items/aggregate/Item'

export class ItemsSelectListVM extends ListVM<RootStore, Item, ItemGroupRowVM, ItemRowVM, ItemNewRowVM> {
  private searchTextbox: HTMLInputElement

  constructor(rootStore: RootStore, parentVM: IItemSelectableVM, showQty: boolean, showFab: boolean = true) {
    super(rootStore, ItemRowVM, ItemGroupRowVM, ItemNewRowVM)
    this.rootStore = rootStore
    this.parentVM = parentVM
    this.showQty = showQty
    this.showFab = showFab
    this.autoGrow = true
    this.startCollapsed = true
    this.stickyHeaders = true
    this.didScrollOffset = 20
    this.setGroups('categoryName', '')
    this.setHeightForModal()
    this.addReaction(
      reaction(
        () => this.rootStore.itemsStore.recordJustUpdated,
        () => this.reloadRows()
      )
    )
    this.setHeight(this.rootStore.appStore.listHeight)

    if (Capacitor.getPlatform() === 'web') {
      window.addEventListener(
        'hashchange',
        () => {
          if (this.filter !== '') {
            if (window.location.hash.indexOf('/hasfilter') === -1 && window.location.hash.indexOf('/newitem') === -1) {
              this.clearFilter()
              return
            }
          }
          if (
            window.location.hash.indexOf('/hasfilter') === -1 &&
            window.location.hash.indexOf('/newitem') === -1 &&
            window.location.hash.indexOf('/itemslist') === -1
          ) {
            this.done()
          }
        },
        true
      )
    }
  }

  private setHeightForModal() {
    this.addReaction(
      reaction(
        () => this.rootStore.appStore.listHeightForModal,
        () => this.setHeight(this.rootStore.appStore.listHeightForModal),
        { delay: 200 }
      )
    )
  }

  protected aggregateSortFunction = (a: Item, b: Item): number => {
    const aVal = a.Name.toLowerCase()
    const bVal = b.Name.toLowerCase()
    if (aVal < bVal) return -1
    return 0
  }

  protected getRecords() {
    return this.rootStore.itemsStore.currentBoardRecords
  }

  @observable public shown: boolean = false
  @observable public parentVM: IItemSelectableVM
  @observable public itemNewVM: ItemNewVM = null
  @observable public showQty: boolean = false
  @observable public showFab: boolean = true
  public lastScrollTop: number = 0
  @observable public shouldShowToTopFab: boolean = false
  @observable public itemMenuShown: boolean = false

  @action
  public show() {
    this.shown = true
    this.rootStore.appStore.setActiveModal('ItemsSelectModal', 56 * 2)
    window.location.hash = '/itemslist'
  }

  @action
  public onModalShown() {
    this.rootStore.appStore.setActiveModal('ItemsSelectModal', 56 * 2)
  }

  @action
  public hide(reason: string = undefined) {
    this.shown = false
  }

  @action
  public goBack() {
    if (this.hasFilter) {
      this.clearFilter()
      return
    }
    this.hide('goback on vm called')
  }

  @override
  public setFilter(val) {
    this.startCollapsed = !Boolean(val)
    this.group1CollapsedNames = []
    super.setFilter(val)
  }

  @override
  public clearFilter() {
    this.startCollapsed = true
    this.group1CollapsedNames = []
    super.clearFilter()
  }

  @computed
  public get hasItemWithQuantity(): boolean {
    return false
  }

  @computed
  private get newItemRow(): ItemNewRowVM {
    return this.allRows.find((e) => e.rowType === 'new') as any as ItemNewRowVM
  }

  public get scrollToIndex(): number {
    return Math.round(this.lastScrollTop / 45)
  }

  @computed
  public get showAddNewButton(): boolean {
    return true
  }

  @action
  public done() {
    this.hide('done called')
  }

  @action
  public goToCategoriesSetup() {
    this.rootStore.appStore.history.push('/categorieslist')
  }

  @action
  public cancel() {
    this.hide('cancel called')
    this.parentVM.doneAddingItems()
  }

  @computed
  public get modalTitle(): string {
    return 'Add to ' + this.parentVM.type
  }

  @action
  public setSearchTextbox(e: HTMLInputElement) {
    if (!e) return
    let doFocus = false
    if (!this.searchTextbox) doFocus = true
    this.searchTextbox = e
  }

  @action
  public checkForNoItems() {
    if (this.rootStore.itemsStore.currentBoardRecords.length === 0) this.newItemRow.openNewItemModal()
  }

  public focusSearchTextbox() {
    if (!this.searchTextbox) return
    if (this.rootStore.appStore.backJustPressed) return
    this.searchTextbox.focus()
  }

  public captureScrollTop(top) {
    this.lastScrollTop = top
    if (top > 45 && !this.shouldShowToTopFab) {
      this.showToTopFab()
    } else if (top <= 45 && this.shouldShowToTopFab) {
      this.hideToTopFab()
    }
  }

  @action
  public showToTopFab() {
    this.shouldShowToTopFab = true
  }

  @action
  public hideToTopFab() {
    this.shouldShowToTopFab = false
  }

  public scrollToTop() {
    this.listRef.scrollTo(0)
  }

  @action
  public clearListRef() {
    this.listRef = null
  }

  @action
  public openItemMenu() {
    this.itemMenuShown = true
  }

  @action
  public hideItemMenu() {
    this.itemMenuShown = false
  }

  @action
  public deleteItem() {
    this.itemMenuShown = false
  }
}
