import { RootStore } from 'src/stores/RootStore'
import { computed, action, makeObservable } from 'mobx'
import { RecipeEditVM } from './RecipeEditVM'
import { RecipeCategory } from 'src/recipe-categories/aggregate/RecipeCategory'

export class CategoryRowVM {
  private rootStore: RootStore
  private recipeCategory: RecipeCategory
  private editVM: RecipeEditVM

  constructor(rootStore: RootStore, editVM: RecipeEditVM, recipeCategory: RecipeCategory) {
    makeObservable(this)
    this.rootStore = rootStore
    this.recipeCategory = recipeCategory
    this.editVM = editVM
  }

  @computed
  public get name(): string {
    return this.recipeCategory.Name
  }

  @computed
  public get isChecked(): boolean {
    const foundCat = this.editVM.recipe.getCategoryForRecipeCategory(this.recipeCategoryGuid)
    if (!foundCat) return false
    return !foundCat.IsDeleted
  }

  @computed
  public get recipeCategoryGuid(): string {
    return this.recipeCategory.RecipeCategoryGuid
  }

  @action
  public toggleChecked() {
    const foundCat = this.editVM.recipe.getCategoryForRecipeCategory(this.recipeCategoryGuid)
    if (!foundCat) this.editVM.recipe.addCategory(this.recipeCategoryGuid)
    else foundCat.toggleDeleted()
  }
}
