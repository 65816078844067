import { RecipeCategoriesStore } from 'src/recipe-categories/store/RecipeCategoriesStore';
import { RootStore } from 'src/stores/RootStore'
import { autorun, reaction, computed, observable, action } from 'mobx'
import { RecipeCategory } from '../aggregate/RecipeCategory';

export class RecipeCategoryRowVM {
  private rootStore: RootStore
  private recipeCategory: RecipeCategory;

  constructor(rootStore: RootStore, recipeCategory: RecipeCategory) {
    this.rootStore = rootStore
    this.recipeCategory = recipeCategory
  }

  @computed
  public get Name() : string {
    return this.recipeCategory.Name
  }

  @computed
  public get RecipeCategoryGuid() : string {
    return this.recipeCategory.RecipeCategoryGuid
  }

  @action
  public rowClick() {
    // setTimeout(() => {
      this.rootStore.appStore.history.push('/recipecategoryedit/' + this.recipeCategory.RecipeCategoryGuid)
    // }, 500)
  }

}
