import { observable, action, computed, makeObservable } from 'mobx'
import { MealsListVM } from '../view-models/list/MealsListVM'
import { MealEditVM } from '../view-models/edit/MealEditVM'
import { Meal } from '../aggregate/Meal'
import { IMealDTO } from '../dtos/IMealDTO'
import moment from 'moment'
import { DataStore } from '@elexient/elexiapp.bits.shared'
import { deserialize } from 'serializr'
import { makePersistable } from 'mobx-persist-store'
import AgentV2 from '../../AgentV2'
import { RootStore } from '../../stores/RootStore'
import * as DataStoreServiceWorker from '../../shared/DataStoreService.worker'

export class MealsStore extends DataStore<RootStore, Meal, IMealDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, Meal, AgentV2, 'Meals', 'Meal')
    super.setRecords(this.persistedRecords)
    makeObservable(this)
    makePersistable(this, { name: 'MealsStore', properties: ['persistedRecords'] }).then(
      action((st) => this.onHydrationCompleted(st?.isHydrated))
    )
  }

  @action
  public onHydrationCompleted(isHydated?: boolean) {
    this.persistedRecords = observable.array(this.persistedRecords.map((e) => makeObservable(deserialize(Meal, e))))
    this.setRecords(this.persistedRecords)
    this.isHydrated = Boolean(isHydated)
    if (process.env.NODE_ENV === 'test') this.isHydrated = true
    this.listVM = new MealsListVM(this.rootStore)
  }

  @observable.shallow public persistedRecords: Array<Meal> = []

  @observable public isHydrated: boolean = false
  @observable public editVM: MealEditVM = null
  @observable public listVM: MealsListVM = null
  private draftMeal: Meal = null
  private draftDate: string = ''
  private draftMealCategoryGuid: string = ''

  @action
  public lazyLoadEditVM(mealGuid: string, date: string = '', mealCategoryGuid: string = '', attempts: number = 0) {
    if (attempts === 20) return
    if (mealCategoryGuid) {
      const foundMealCat = this.rootStore.mealCategoriesStore.get(mealCategoryGuid)
      if (!foundMealCat) {
        setTimeout(() => this.lazyLoadEditVM(mealGuid, date, mealCategoryGuid, attempts++), 500)
        return
      }
    }
    if (mealGuid === 'new') {
      this.loadEditVM(Meal.create(this.rootStore.boardsStore.currentBoardId), date, mealCategoryGuid)
      this.draftDate = date
      this.draftMealCategoryGuid = mealCategoryGuid
      return
    }
    if (mealGuid === 'empty') {
      if (this.editVM && this.editVM.isNewMeal) this.draftMeal = this.editVM.meal.clone()
      else this.draftMeal = null
      this.editVM = null
      return
    }
    const foundMeal = this.get(mealGuid)
    if (!foundMeal) {
      setTimeout(() => this.lazyLoadEditVM(mealGuid, date, mealCategoryGuid, attempts++), 500)
      return
    }
    this.loadEditVM(foundMeal.clone(), date, mealCategoryGuid)
  }

  private loadEditVM(meal: Meal, date: string, mealCategoryGuid: string) {
    if (this.checkForDraftMeal(date, mealCategoryGuid)) return
    this.editVM = new MealEditVM(this.rootStore, meal)
    if (date) this.editVM.setPlannedDateTime(moment(date, 'MMDDYYYY').local().startOf('day').utc())
    if (mealCategoryGuid) this.editVM.setMealCategory(mealCategoryGuid)
  }

  private checkForDraftMeal(date, mealCategoryGuid) {
    if (!date) return false
    if (this.draftDate !== date) return false
    if (!mealCategoryGuid) return false
    if (this.draftMealCategoryGuid !== mealCategoryGuid) return false
    if (!this.draftMeal) return false
    this.editVM = new MealEditVM(this.rootStore, this.draftMeal)
    return true
  }

  public deleteDraftMeal() {
    this.draftMeal = null
  }

  @action
  public attachRecipeToDraftMeal(recipeGuid: string) {
    if (!this.draftMeal) return
    const vm = new MealEditVM(this.rootStore, this.draftMeal)
    vm.addRecipe(recipeGuid)
    this.draftMeal = vm.meal.clone()
  }

  @computed
  public get currentBoardRecords() {
    return this.persistedRecords.filter((e) => e.BoardId === this.rootStore.boardsStore.currentBoardId)
  }

  @computed
  public get sortedCurrentBoardRecords() {
    return this.persistedRecords
      .filter((e) => e.BoardId === this.rootStore.boardsStore.currentBoardId)
      .sort((a, b) => {
        if (b.PlannedDateAsDateForQuickSort < a.PlannedDateAsDateForQuickSort) return -1
        return 0
      })
  }

  protected getWorker() {
    return (DataStoreServiceWorker as any)() as typeof DataStoreServiceWorker
  }
}
