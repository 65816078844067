import React from 'react'
import {
  IonContent,
  IonIcon,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonAlert,
  IonFab,
  IonFabButton,
} from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { AppStore } from '../../../stores/AppStore'
import { trash, checkmark, arrowBack, trashOutline, checkmarkSharp } from 'ionicons/icons'
import { RootStore } from '../../../stores/RootStore'
import { MealCategoriesStore } from '../../store/MealCategoriesStore'
import { match } from 'react-router'
import DetailsForm from './DetailsForm'
import BackIcon from 'src/shared/BackIcon'

interface Props {
  mealCategoriesStore: MealCategoriesStore
  appStore: AppStore
  rootStore: RootStore
  history: any
  match: match
}

@inject('mealCategoriesStore')
@inject('appStore')
@inject('rootStore')
@observer
export default class MealCategoryEditPage extends React.Component<Props> {
  constructor(props) {
    super(props)
    this.props.appStore.setCurrentRoute('/mealcategoryedit/' + this.props.match.params['id'])
  }

  componentDidMount() {
    this.props.appStore.setCurrentRoute('/mealcategoryedit/' + this.props.match.params['id'])
    this.props.mealCategoriesStore.lazyLoadEditVM(this.props.match.params['id'])
  }

  componentWillUnmount() {
    this.props.mealCategoriesStore.lazyLoadEditVM('empty')
  }

  handleGoBack() {
    this.props.appStore.handleGoBack(this.props.history)
  }

  renderDeleteConfirm() {
    const { editVM } = this.props.mealCategoriesStore
    return (
      <IonAlert
        isOpen={editVM.deleteConfirmShown}
        onDidDismiss={() => editVM.hideDeleteConfirm()}
        header={'Delete Meal Slow'}
        message={'Are you sure you wish to delete ' + editVM.name}
        buttons={[
          {
            text: 'No',
            role: 'cancel',
            cssClass: 'primary',
            handler: () => editVM.hideDeleteConfirm(),
          },
          {
            text: 'Yes',
            handler: () => editVM.delete(),
          },
        ]}
      />
    )
  }

  render() {
    const { editVM } = this.props.mealCategoriesStore
    if (!editVM) return null

    return (
      <>
        {this.props.appStore.showAppHeader && (
          <IonHeader>
            <IonToolbar />
          </IonHeader>
        )}

        <IonHeader id='MealCategoryEditPage_Header'>
          <IonToolbar color='secondary'>
            <IonButtons slot='start'>
              <IonButton onClick={() => this.handleGoBack()}>
                <BackIcon />
              </IonButton>
            </IonButtons>

            <IonTitle>{editVM.pageTitle}</IonTitle>
            <IonButtons slot='end'>
              {editVM.showDeleteButton && (
                <IonButton onClick={() => editVM.showDeleteConfirm()}>
                  <IonIcon icon={trashOutline} />
                </IonButton>
              )}
              <IonButton onClick={() => editVM.save()}>
                <IonIcon icon={checkmarkSharp} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent
          id='MealCategoryEditPage_Content'
          scrollY
          ref={e => this.props.appStore.applyScrollStyles(e)}
          style={{ height: this.props.appStore.listHeightPx }}
        >
          <DetailsForm />
          <br />
          <br />
          <br />
          <br />
          <br />
        </IonContent>

        <IonFab vertical='bottom' horizontal='end'>
          <IonFabButton
            color='tertiary'
            onClick={() => editVM.save()}
            disabled={!editVM.isValid}
            style={{
              marginTop: '-60px',
              marginLeft: '-60px',
            }}
          >
            <IonIcon icon={checkmarkSharp} />
          </IonFabButton>
        </IonFab>

        {this.renderDeleteConfirm()}
      </>
    )
  }
}
