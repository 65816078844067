import { observable, action, computed, makeObservable } from 'mobx'
import generateUUID from '../../utils/UUID'
import { IRecipeItemDTO } from '../dto/IRecipeItemDTO'
import { QuantityTypesService } from '../../quantities/service/QuantyTypesService'
import { serializable, serialize } from 'serializr'

export class RecipeItem implements IRecipeItemDTO {
  public static create(itemGuid: string, recipeItemGuid: string = null) {
    const ri = new RecipeItem()
    ri.RecipeItemGuid = recipeItemGuid
    if (!ri.RecipeItemGuid) ri.RecipeItemGuid = generateUUID()
    ri.ItemGuid = itemGuid
    return ri
  }

  constructor() {
    makeObservable(this)
  }

  @serializable @observable public RecipeItemGuid: string = ''
  @serializable @observable public ItemGuid: string = ''
  @serializable @observable public Quantity: number = 1
  @serializable @observable public Rank: number = 0
  @serializable @observable public IsDeleted: boolean = false
  @serializable @observable public QuantityTypeAbbr: string = ''
  @serializable @observable public Note: string = ''
  @serializable @observable public Description: string = ''

  @action
  public setQuantity(val) {
    this.Quantity = val
  }

  @action
  public clearItem() {
    this.ItemGuid = null
  }

  @action
  public setItem(itemGuid: string) {
    this.ItemGuid = itemGuid
  }

  @action
  public setQuantityType(val) {
    var svc = new QuantityTypesService()
    val = val && val.toLowerCase()
    const foundType = svc.getQuantityTypes().find((e) => e.name.toLowerCase() === val)
    if (foundType) val = foundType.abbr
    else val = ''
    this.QuantityTypeAbbr = val
  }

  @action
  public setNote(val) {
    this.Note = val
  }

  @action
  public setDescription(val) {
    this.Description = val.replace(/\r\n/g, '').replace(/\n/g, '')
  }

  @action
  public increaseQuantity(qty: number = 0) {
    if (qty === 0) this.Quantity += 1
    else this.Quantity = qty
  }

  @action
  public decreaseQuantity() {
    this.Quantity -= 1
  }

  @action
  public setRank(val) {
    this.Rank = val
  }

  @computed
  public get hasItem(): boolean {
    return this.ItemGuid !== null
  }

  @computed
  public get hasDescription(): boolean {
    if (!this.Description) return false
    if (this.Description === '') return false
    return true
  }

  @action
  public updateFromServer(serverRecItem: IRecipeItemDTO) {
    this.Quantity = serverRecItem.Quantity
    this.Rank = serverRecItem.Rank
    this.QuantityTypeAbbr = serverRecItem.QuantityTypeAbbr
    this.Description = serverRecItem.Description
  }

  @action
  public markAsDeleted() {
    this.IsDeleted = true
  }

  public toDTO(): IRecipeItemDTO {
    return serialize(this)
  }
}
