import React from 'react'
import {
  IonList,
  IonItem,
  IonButton,
  IonModal,
  IonLabel,
  IonInput,
  IonCard,
  IonCardHeader,
  IonTitle,
  IonCardContent,
} from '@ionic/react'
import { observer } from 'mobx-react'
import { CategoryNewVM } from 'src/items-select/view-models/CategoryNewVM'

interface Props {
  categoryNewVM: CategoryNewVM
}

const CategoryNewModal: React.FC<Props> = ({ categoryNewVM }) => {
  const vm = categoryNewVM
  if (!vm) return null

  const handleSave = (e) => {
    e.preventDefault()
    vm.save()
  }

  return (
    <IonModal
      id='ItemsSelect_CategoryNewModal'
      isOpen={vm.shown}
      showBackdrop={vm.showBackdrop}
      onDidDismiss={() => vm.hide()}
    >
      <IonCard>
        <IonCardHeader>
          <IonTitle>New Category</IonTitle>
        </IonCardHeader>
        <IonCardContent>
          <form onSubmit={(e) => handleSave(e)}>
            <IonList>
              <IonItem lines={'inset'}>
                <IonLabel position='floating'>Name</IonLabel>
                <IonInput
                  type='text'
                  autofocus
                  value={vm.name}
                  onIonChange={(e: any) => vm.setName(e.target.value)}
                  clearOnEdit={true}
                />
              </IonItem>

              <div id='buttons'>
                <IonButton
                  type='button'
                  size='small'
                  color='secondary'
                  onClick={(e) => {
                    e.stopPropagation()
                    vm.cancel()
                  }}
                >
                  Cancel
                </IonButton>
                <IonButton type='submit' size='small' color='tertiary' disabled={!vm.isValid}>
                  Save
                </IonButton>
              </div>
            </IonList>
          </form>
        </IonCardContent>
      </IonCard>
    </IonModal>
  )
}

export default observer(CategoryNewModal)
