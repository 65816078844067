import React from 'react'
import {
  IonContent,
  IonIcon,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonFab,
  IonFabButton,
  IonButton,
  IonButtons,
  IonRefresher,
  IonRefresherContent,
  IonToast,
  IonFabList,
} from '@ionic/react'
import { observer, inject, Observer } from 'mobx-react'
import { AppStore } from 'src/stores/AppStore'
import {
  add,
  checkmarkCircleOutline,
  eggOutline,
  enterOutline,
  exitOutline,
  linkOutline,
  repeatOutline,
  sparklesOutline,
} from 'ionicons/icons'
import { RootStore } from 'src/stores/RootStore'
import { RecipesStore } from '../../store/RecipesStore'
import RecipesList from './RecipesList'
import { EvilIcons, AntDesign } from 'react-web-vector-icons'
import RecipesListPageMoreMenu from './RecipesListPageMoreMenu'
import OfflineToast from '../../../shared/OfflineToast'

interface Props {
  recipesStore: RecipesStore
  appStore: AppStore
  rootStore: RootStore
  history: any
}

@inject('recipesStore')
@inject('appStore')
@inject('rootStore')
@observer
export default class RecipesListPage extends React.Component<Props> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.appStore.setCurrentRoute('/recipeslist')
  }

  handleGoBack() {
    this.props.appStore.handleGoBack(this.props.history)
  }

  handleRefresh(event) {
    const { listVM } = this.props.recipesStore
    listVM.refresh().then(() => {
      setTimeout(() => {
        if (event) event.target.complete()
      }, 1000)
    })
  }

  renderNoRecipesMessage() {
    const { listVM } = this.props.recipesStore
    if (!listVM) return null
    if (!listVM.hasZeroRecipes) return null
    return (
      <div className='no-items'>
        Your cookbook is empty.
        <br />
        Start adding your favorite recipes...
        {/* <br />
        <br />
        <IonButton slot='icon-only' color='tertiary' type='button' onClick={() => listVM.goToAddNew()}>
          <IonIcon icon={add} />
        </IonButton> */}
      </div>
    )
  }

  renderNoRecipesFoundMessage() {
    const { listVM } = this.props.recipesStore
    if (!listVM) return null
    if (listVM.hasRows) return null
    if (listVM.hasZeroRecipes) return null
    return (
      <div className='no-items'>
        No recipes found.
        {/* <br />
        <br />
        <IonButton slot='icon-only' color='tertiary' type='button' onClick={() => listVM.goToAddNew()}>
          <IonIcon icon={add} />
        </IonButton> */}
      </div>
    )
  }

  renderRefresher() {
    const listVM = this.props.recipesStore.listVM
    return (
      <IonRefresher
        style={{ zIndex: 2 }}
        slot='fixed'
        onIonRefresh={(e) => this.handleRefresh(e)}
        disabled={!listVM.ptrEnabled}
      >
        <IonRefresherContent />
      </IonRefresher>
    )
  }

  renderSearchbar() {
    const listVM = this.props.recipesStore.listVM
    if (!listVM.filterShown) return
    return (
      <IonToolbar id='RecipesListPage_Toolbar' color='secondary'>
        <input
          type='text'
          className='ion-input-md'
          placeholder='Search...'
          value={listVM.typedFilter}
          onChange={(e: any) => listVM.setFilter(e.target.value)}
          ref={(e) => listVM.setFilterTextbox(e)}
        />

        {listVM.hasFilter && (
          <div slot='end' onClick={() => listVM.clearFilter()}>
            <AntDesign name='closecircleo' size={18} />
          </div>
        )}
      </IonToolbar>
    )
  }

  renderRecipesList() {
    const { listVM } = this.props.recipesStore
    if (!listVM.hasRows) return null
    return <RecipesList />
  }

  renderFab() {
    return (
      <IonFab vertical='bottom' horizontal='end'>
        <IonFabButton
          color='tertiary'
          className='recipe-fab-button'
          style={{ marginTop: this.props.appStore.fabOffset }}
        >
          <IonIcon icon={add} />
        </IonFabButton>
        <IonFabList
          id='RecipesListPage_FabList'
          side='top'
        >
          <IonFabButton color='tertiary' className='small' onClick={() => this.props.recipesStore.listVM.openImport()}>
            <IonIcon icon={linkOutline} />
            &nbsp; Import from URL
          </IonFabButton>

          <IonFabButton
            color='tertiary'
            className='smaller'
            onClick={() => this.props.recipesStore.listVM.goToAddNew()}
          >
            <IonIcon icon={eggOutline} />
            &nbsp; Blank
          </IonFabButton>
          {/* <IonFabButton
            color='tertiary'
            className='recipe-small-fab-button'
            // onClick={() => this.addReminder(ReminderTypeEnum.Bill)}
          >
            <IonIcon icon={sparklesOutline} />
            &nbsp; Generate from AI
          </IonFabButton> */}
        </IonFabList>
      </IonFab>
    )
  }

  render() {
    const { listVM } = this.props.recipesStore
    if (!listVM) return null

    return (
      <>
        {this.props.appStore.showAppHeader && (
          <IonHeader>
            <IonToolbar />
          </IonHeader>
        )}

        <IonHeader id='RecipesListPage_Header'>
          <IonToolbar color='secondary'>
            <IonTitle>Cookbook</IonTitle>
            <IonButtons slot='end'>
              <RecipesListPageMoreMenu />
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        {this.renderSearchbar()}
        <IonContent id='RecipesListPage_Content' style={{ height: listVM.finalListHeightPx }} scrollY={false}>
          {this.renderRefresher()}
          {this.renderRecipesList()}
          {this.renderNoRecipesMessage()}
          {this.renderNoRecipesFoundMessage()}
        </IonContent>

        {this.renderFab()}

        {/* <IonFab vertical='bottom' horizontal='end'>
          <IonFabButton
            color='tertiary'
            onClick={() => listVM.goToAddNew()}
            style={{ marginTop: this.props.appStore.fabOffset }}
          >
            <IonIcon icon={add} />
          </IonFabButton>
        </IonFab> */}
      </>
    )
  }
}
