import React from 'react'
import { observer } from 'mobx-react'
import { RecipeRowVM } from '../../view-models/list/RecipeRowVM'

interface Props {
  row: RecipeRowVM
  style: any
}

const RecipeRow: React.FC<Props> = ({ row, style }) => {
  return (
    <div style={style} id='RecipeRow' onClick={() => row.click()}>
      <span>{row.name}</span>
    </div>
  )
}

export default observer(RecipeRow)
