import React from 'react'
import { observer } from 'mobx-react'
import { IonIcon } from '@ionic/react'
import { RecipeGroupRowVM } from '../../view-models/list/RecipeGroupRowVM'
import { chevronDown, chevronForward } from 'ionicons/icons'

interface Props {
  row: RecipeGroupRowVM
  style: any
}

const RecipeGroupRow: React.FC<Props> = ({ row, style }) => {
  const renderRightIcon = () => {
    if (!row.isCollapsed) return
    return <IonIcon icon={chevronForward} />
  }

  const renderDownIcon = () => {
    if (row.isCollapsed) return
    return <IonIcon icon={chevronDown} />
  }

  return (
    <div style={style} id='RecipeGroupRow' onClick={() => row.click()}>
      <div className='row'>
        <div className='name'>{row.name}</div>
        {renderRightIcon()}
        {renderDownIcon()}
      </div>
    </div>
  )
}

export default observer(RecipeGroupRow)
