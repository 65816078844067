import { observable, makeObservable, computed, action } from 'mobx'
import { serializable, object } from 'serializr'
import { IItemDTO } from '../dtos/IItemDTO'
import { Category } from './Category'

export class Item implements IItemDTO {
  constructor() {
    makeObservable(this)
  }

  @serializable @observable public ItemGuid: string = ''
  @serializable @observable public Name: string = ''
  @serializable @observable public Notes: string = ''
  @serializable @observable public CategoryGuid: string = ''
  @serializable(object(Category)) @observable public Category: Category = undefined

  @action
  public clearCategory() {
    this.Category = Category.default()
  }

  @computed
  public get hasNotes(): boolean {
    return this.Notes && this.Notes !== ''
  }
}
