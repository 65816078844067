import agent from '../../Agent'
import { RootStore } from '../../stores/RootStore'
import { IMealDTO } from '../dtos/IMealDTO'
import { ActionTypeEnum } from '../../actions/enum/ActionTypeEnum'
import { action } from 'mobx'

export class MealsService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @action
  public save(dto: IMealDTO) {
    this.rootStore.mealsStore.updateFromServer({ ...dto, IsNew: false })
    this.rootStore.actionsStore.addAction(ActionTypeEnum.MEAL_SAVE, dto)
  }

  @action
  public async saveOnServer(dto: IMealDTO) {
    const savedDTO = await agent.Meals.save(dto)
    this.rootStore.mealsStore.updateFromServer(savedDTO)
  }

  public delete(dto: IMealDTO) {
    dto.IsDeleted = true
    this.save(dto)
  }
}
