import { observable, action, computed, makeObservable } from 'mobx'
import { RootStore } from 'src/stores/RootStore'
import { BoardsService } from '../service/BoardsService'

export class BoardJoinModalVM {
  private rootStore: RootStore
  private emailInputEl: HTMLIonInputElement
  private focused: boolean = false

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeObservable(this)
  }

  @observable public isOpen: boolean = false
  @observable public isProcessing: boolean = false
  @observable public invitationCode: string = ''

  @action
  public setInvitationCode(val: string) {
    this.invitationCode = this.formatCode(val)
  }

  private formatCode(val: string): string {
    val = val.replace(/-/g, '')
    if (val.length <= 2) return val.substr(0, 3).toUpperCase()
    if (val.length >= 3) return val.substr(0, 3).toUpperCase() + '-' + val.substr(3, 3).toUpperCase()
  }

  @computed
  public get isIos(): boolean {
    return this.rootStore.appStore.isIos
  }

  public focusEmailInput() {
    if (!this.emailInputEl) return
    this.emailInputEl.setFocus()
  }

  @action
  public async setInput(e: HTMLIonInputElement) {
    // if (this.focused) return
    if (!e) return
    setTimeout(() => e.setFocus(), 1000)
    this.focused = true
    const txt = await e.getInputElement()
    txt.setAttribute('autocomplete', 'something-new-2' + new Date().getTime())
    this.emailInputEl = e
  }

  @computed
  public get isValid(): boolean {
    if (!this.invitationCode || this.invitationCode === '') return false
    return true
  }

  @computed
  public get userEmailAddress(): string {
    return this.rootStore.userStore.user?.EmailAddress
  }

  @action
  public async joinBoardOnServer() {
    this.isProcessing = true
    const svc = new BoardsService(this.rootStore)
    const result = await svc.joinBoardOnServer(this.invitationCode)
    if (!result.Success) {
      alert('Board not found. Please check your code and try again.')
      this.isProcessing = false
      return
    }
    await this.rootStore.loadData('joined new board')
    this.rootStore.appStore.navigateTo('/')
    this.rootStore.boardsStore.setCurrentBoard(result.BoardId)
    this.toggle()
    this.isProcessing = false
  }

  @action
  public toggle() {
    this.isOpen = !this.isOpen
    if (this.isOpen) setTimeout(() => this.focusEmailInput(), 1000)
  }
}
