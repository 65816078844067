import React from 'react'
import { observer, inject } from 'mobx-react'
import { RecipeItemRowVM } from '../../view-models/edit/RecipeItemRowVM'
import { IonIcon, IonSpinner, IonTextarea } from '@ionic/react'
import { reorderTwo, trashOutline, sparklesSharp, pencil, search } from 'ionicons/icons'
import { RecipesStore } from 'src/recipes/store/RecipesStore'
import { EvilIcons } from 'react-web-vector-icons'
import { faUnlink } from '@fortawesome/free-solid-svg-icons'
import { faEdit } from '@fortawesome/free-regular-svg-icons'
import { SortableHandle } from 'react-sortable-hoc'
import ItemsSelectModal from '../../../items-select/views/ItemsSelectModal'
import { IItemDTO } from '../../../items/dtos/IItemDTO'
import ItemNewModal from '../../../items/views/new/ItemNewModal'
import ItemEditModal from '../../../items/views/edit/ItemEditModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  row: RecipeItemRowVM
  recipesStore?: RecipesStore
}

const RecipeItemRow: React.FC<Props> = ({ row, recipesStore }) => {
  const renderDeleteIngredientButton = () => {
    if (!row.editable) return
    return (
      <div className='del-btn' onClick={() => row.delete()}>
        <IonIcon icon={trashOutline} size='small' />
      </div>
    )
  }

  const renderDescriptionInput = () => {
    return (
      <div className='name'>
        <IonTextarea
          disabled={!row.editable}
          className='description-input'
          placeholder='Type or paste ingredients'
          value={row.description}
          rows={1}
          autoGrow={false}
          onIonChange={(e: any) => row.setDescription(e.target.value)}
          ref={(e) => row.setTextbox(e)}
          onKeyDown={(e) => {
            if (e.keyCode === 37 && row.cursorAtStart && row.previousRow) {
              row.previousRow.setFocus()
              row.previousRow.moveCursorToEnd()
              e.preventDefault()
              e.stopPropagation()
            }
            if (e.keyCode === 39 && row.cursorAtEnd && row.nextRow) {
              row.nextRow.setFocus()
              row.nextRow.moveCursorToStart()
              e.preventDefault()
              e.stopPropagation()
            }
            // if (e.charCode === 13) {
            //   editVM.addItem()
            //   e.preventDefault()
            //   e.stopPropagation()
            // }
            if (e.keyCode === 8 && row.cursorAtStart) {
              row.markShouldCombineWithPrevious()
            }
          }}
          onKeyUp={(e) => {
            if (e.keyCode === 8 && row.cursorAtStart && row.previousRow && row.hasDescription) {
              if (row.combineWithPreviousRow()) {
                // row.combineWithPreviousRow()
                setTimeout(() => {
                  row.previousRow.setFocus()
                  row.previousRow.moveCursorTo(row.previousRow.description.length - row.description.length)
                }, 200)
                e.preventDefault()
                e.stopPropagation()
              }
              // row.previousRow.setFocus()
              // row.previousRow.moveCursorToEnd()
              // row.combineWithPreviousRow()
              // e.preventDefault()
              // e.stopPropagation()
            }
            // if (e.keyCode === 8 && row.cursorAtStart && row.previousRow && row.hasDescription) {
            //   row.previousRow.setFocus()
            //   row.previousRow.moveCursorToEnd()
            //   row.combineWithPreviousRow()
            //   e.preventDefault()
            //   e.stopPropagation()
            // }
            // else if (e.keyCode === 8 && !row.hasDescription) {
            //   if (row.previousRow) {
            //     row.previousRow.setFocus()
            //     row.previousRow.moveCursorToEnd()
            //     row.delete()
            //     e.preventDefault()
            //     e.stopPropagation()
            //   }
            // }
          }}
          onKeyPress={(e) => {
            // console.log(e.keyCode)
            if (e.charCode === 13) {
              // editVM.addItem()
              // e.preventDefault()
              // e.stopPropagation()
            }
            // if (e.charCode === 8 && !row.hasDescription) row.delete()
          }}
        />
      </div>
    )
  }

  const Handle = SortableHandle(() => {
    return (
      <div className='move-btn'>
        <IonIcon icon={reorderTwo} size='small' />
      </div>
    )
  })

  const renderSuggestedItems = () => {
    if (!row.viewLinkedItem) return
    // if (!row.hasSuggestedItems) return
    // if (!row.hasSuggestedNewItem) return
    if (row.hasItem) return
    // if (row.hasResultMatchedItem && !row.hasSuggestedItemsResult && row.hasDescription) {
    //   return <div className='suggestion-loading'>{renderSpinner()}</div>
    // }
    if (row.spinnerShown) {
      return <div className='suggestion-loading'>{renderSpinner()}</div>
    }
    return (
      <>
        <div className='break' />
        <div className='suggested-items'>
          {renderSuggestedNewItem()}
          {row.suggestedItems.map((e) => renderSuggestedItem(e))}
        </div>
      </>
    )
  }

  const renderNoSuggestedItems = () => {
    if (!row.viewLinkedItem) return
    if (row.hasItem) return
    if (!row.hasResultError) return
    return (
      <>
        <div className='no-suggested-items'>
          <IonIcon icon={sparklesSharp} size='small' />
          <span>No suggestions</span>
        </div>
      </>
    )
  }

  const renderSuggestedItem = (item: IItemDTO) => {
    return (
      <React.Fragment key={item.ItemGuid}>
        {/* <div className=''> */}
        <div className='item-btn' onClick={() => row.acceptSuggestedItem(item.ItemGuid)}>
          <IonIcon icon={sparklesSharp} size='small' />
          <div className='name'>{item.Name}</div>
          {/* <div className='cat'>{row.getSuggestedItemCategoryName(item)}</div> */}
        </div>
        {/* <div className='spacer' /> */}
        {/* </div> */}
      </React.Fragment>
    )
  }

  const renderLinkedItem = () => {
    if (!row.viewLinkedItem) return
    if (!row.hasItem) return
    return (
      <>
        <div className='break' />
        {/* <div className='item-row'> */}
        <div className='item-btn' onClick={() => row.clearItem()}>
          {/* <IonIcon icon={unlink} size='small' /> */}
          <FontAwesomeIcon icon={faUnlink} />
          <div className='name'>{row.itemName}</div>
          {/* <div className='cat'>({row.categoryName})</div> */}
        </div>
        &nbsp;
        <div className='item-btn edit' onClick={() => row.editItem()}>
          {/* <IonIcon icon={pencil} size='small' /> */}
          <FontAwesomeIcon icon={faEdit} />
        </div>
        {/* <div className='spacer' /> */}
        {/* </div> */}
      </>
    )
  }

  const renderLinkedIndicator = () => {
    if (!row.hasItem) return
    return (
      <div className='linked-indicator'>
        <EvilIcons name='link' size={19} />
      </div>
    )
  }

  const renderSpinner = () => {
    if (row.isIos) return <IonSpinner name='dots' />
    return <IonSpinner name='dots' />
  }

  const renderSuggestedNewItem = () => {
    if (!row.viewLinkedItem) return
    if (row.hasItem) return
    if (row.hasResultMatchedItem) return
    if (!row.hasSuggestedNewItem) return
    return (
      <>
        {/* <div className='break' /> */}
        {/* <div className='item-row'> */}
        <div className='item-btn' onClick={() => row.acceptSuggestedNewItem()}>
          <IonIcon icon={sparklesSharp} size='small' />
          <div className='name'>{row.suggestedNewItemName}</div>
          {/* <div className='cat'>({row.suggestedNewItemCategoryName})</div> */}
        </div>
        {/* &nbsp; */}
        {/* <div className='item-btn edit' onClick={() => row.editSuggestedNewItem()}> */}
        {/* <IonIcon icon={pencil} size='small' /> */}
        {/* </div> */}
        {/* <div className='spacer' /> */}
        {/* </div> */}
      </>
    )
  }

  const renderSelectItem = () => {
    if (!row.viewLinkedItem) return
    if (row.hasItem) return
    return (
      <>
        <div className='break' />
        {/* <div className='item-row'> */}
        <div className='item-btn select-item' onClick={() => row.openItemSelect()}>
          <IonIcon icon={search} size='small' />
          <div>{row.selectItemPrompt}</div>
        </div>
        <div className='spacer' />
        {/* </div> */}
        <ItemsSelectModal itemsList={row.itemsList} />
      </>
    )
  }

  const renderHandle = () => {
    if (row.viewLinkedItem) return
    return <Handle />
  }

  const renderItemNewModal = () => {
    if (!row.itemNewVM) return
    return <ItemNewModal vm={row.itemNewVM} />
  }

  const renderItemEditModal = () => {
    if (!row.itemEditVM) return
    return <ItemEditModal vm={row.itemEditVM} />
  }

  return (
    <>
      <div id='RecipeItemRow' className={row.viewLinkedItem ? 'link-mode' : ''}>
        {renderDescriptionInput()}
        {renderLinkedIndicator()}
        {renderHandle()}
        {renderDeleteIngredientButton()}
        {renderSuggestedItems()}
        {renderNoSuggestedItems()}
        {/* {renderSuggestedNewItem()} */}
        {renderSelectItem()}
        {renderLinkedItem()}
        {renderItemNewModal()}
        {renderItemEditModal()}
      </div>
    </>
  )
}

export default inject('recipesStore')(observer(RecipeItemRow))
