import React from 'react'
import { inject, observer } from 'mobx-react'
import { RecipeItemRowVM } from '../../../meals/view-models/edit/RecipeItemRowVM'
import { faTasks } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Feather, MaterialCommunityIcons } from 'react-web-vector-icons'

interface Props {
  row: RecipeItemRowVM
}

const RecipeItemRow: React.FC<Props> = ({ row }) => {
  const renderSelectItemButton = () => {
    if (row.hasItem) return
    return (
      <div className='row-btn-container'>
        <div className='row-btn link-btn' onClick={() => row.showEditModal(true)}>
          <div>Link Item</div>
        </div>
      </div>
    )
  }

  const renderSelectedItemButton = () => {
    if (!row.hasItem) return
    return (
      <div className='row-btn-container'>
        <div className='row-btn'>
          <div className={row.iconName === 'add' ? 'name' : 'name checked'}>{row.itemName}</div>
        </div>
      </div>
    )
  }

  const renderGottenIcon = () => {
    if (!row.isGotten) return
    if (row.iconName === 'checkmark') return
    return (
      <div
        className='icon-container checked'
        onClick={e => {
          e.stopPropagation()
          row.toggle()
        }}
      >
        <Feather name='check-circle' size={22} />
      </div>
    )
  }

  const renderCheckmarkIcon = () => {
    if (row.iconName !== 'checkmark') return
    return (
      <div
        className='icon-container checked'
        onClick={e => {
          e.stopPropagation()
          row.toggle()
        }}
      >
        <Feather name='check-circle' size={22} />
      </div>
    )
  }

  const renderLinkIcon = () => {
    if (row.iconName !== 'link') return
    return (
      <div
        className='icon-container checked'
        onClick={e => {
          e.stopPropagation()
          row.toggle()
        }}
      >
        <MaterialCommunityIcons name='link' size={18} />
      </div>
    )
  }

  const renderPlusIcon = () => {
    if (row.iconName === 'cart') return
    if (row.iconName === 'checkmark') return
    if (row.iconName === 'link') return
    if (row.isGotten) return
    return (
      <div
        className='icon-container'
        onClick={e => {
          e.stopPropagation()
          row.toggle()
        }}
      >
        <Feather name='plus-circle' size={22} />
      </div>
    )
  }

  const renderInListIcon = () => {
    if (row.iconName !== 'cart') return
    return (
      <div
        className='icon-container checked'
        onClick={e => {
          e.stopPropagation()
          row.toggle()
        }}
      >
        <Feather name='circle' size={22} />
        <FontAwesomeIcon
          onClick={e => {
            e.stopPropagation()
            row.toggle()
          }}
          icon={faTasks}
          className='cart for-checkbox'
        />
      </div>
    )
  }

  const renderCheckbox = () => {
    return (
      <>
        {renderGottenIcon()}
        {renderPlusIcon()}
        {renderCheckmarkIcon()}
        {renderInListIcon()}
        {renderLinkIcon()}
      </>
    )
  }

  const renderDescription = () => {
    return (
      <div className='description' onClick={() => row.showEditModal()}>
        {row.description}
      </div>
    )
  }

  return (
    <div id='RecipeItemRow' style={{ borderLeftColor: row.categoryColor }} className={row.hasQuantity && !row.isCleared ? 'checked' : ''}>
      {renderDescription()}
      <div className='item-row'>
        {renderCheckbox()}
        {renderSelectItemButton()}
        {renderSelectedItemButton()}
      </div>
    </div>
  )
}

export default inject('mealsStore')(observer(RecipeItemRow))
