import React from 'react'
import { inject, observer } from 'mobx-react'
import RecipeRow from './RecipeRow'
import { RecipesStore } from '../../store/RecipesStore'
import { AppStore } from 'src/stores/AppStore'
import RecipeGroupRow from './RecipeGroupRow'
import { List } from '@elexient/elexiapp.bits.shared'
import { RecipeRowVM } from '../../view-models/list/RecipeRowVM'
import { RecipeGroupRowVM } from '../../view-models/list/RecipeGroupRowVM'
import { RecipeNewRowVM } from '../../view-models/list/RecipeNewRowVM'

interface Props {
  recipesStore?: RecipesStore
  appStore?: AppStore
}

const RecipesList: React.FC<Props> = ({ recipesStore, appStore }) => {
  const { listVM } = recipesStore

  const renderNormalRow = (row: RecipeRowVM, index, style) => {
    return <RecipeRow key={'rr' + index} row={row} style={style} />
  }

  const renderGroupRow = (row: RecipeGroupRowVM, index, style) => {
    return <RecipeGroupRow key={'rgrp' + index} row={row} style={style} />
  }

  const renderNewRow = (row: RecipeNewRowVM, index, style) => {
    return null
  }

  return (
    <List
      listVM={listVM}
      className='list'
      normalRow={(row, index, style) => renderNormalRow(row, index, style)}
      groupRow={(row, index, style) => renderGroupRow(row, index, style)}
      newRow={(row, index, style) => renderNewRow(row, index, style)}
      onRef={(e) => appStore.applyScrollStyles(e)}
    />
  )
}

export default inject('recipesStore', 'appStore')(observer(RecipesList))
