import { observable, action, computed, makeObservable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Category } from '../aggregate/Category'
import { CategoryEditVM } from '../view-models/CategoryEditVM'
import { CategoriesListVM } from '../view-models/CategoriesListVM'
import { ICategoryDTO } from '../dtos/ICategoryDTO'
import { DataStore } from '@elexient/elexiapp.bits.shared'
import AgentV2 from '../../AgentV2'
import { makePersistable } from 'mobx-persist-store'
import { deserialize } from 'serializr'
import * as DataStoreServiceWorker from '../../shared/DataStoreService.worker'

export class CategoriesStore extends DataStore<RootStore, Category, ICategoryDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, Category, AgentV2, 'Categories', 'Category')
    super.setRecords(this.persistedRecords)
    makeObservable(this)
    makePersistable(this, { name: 'CategoriesStore', properties: ['persistedRecords'] }).then(
      action((st) => this.onHydrationCompleted(st?.isHydrated))
    )
  }

  @action
  public onHydrationCompleted(isHydated?: boolean) {
    this.persistedRecords = observable.array(this.persistedRecords.map((e) => makeObservable(deserialize(Category, e))))
    this.setRecords(this.persistedRecords)
    this.isHydrated = Boolean(isHydated)
    this.listVM = new CategoriesListVM(this.rootStore)
    if (process.env.NODE_ENV === 'test') this.isHydrated = true
  }

  @observable public isHydrated: boolean = false
  @observable.shallow public persistedRecords: Array<Category> = []

  @observable public editVM: CategoryEditVM = null
  @observable public listVM: CategoriesListVM = null

  public async loadData() {
    await super.loadData()
    super.updateFromServer(Category.create(0, '00000000-0000-0000-0000-000000000000', 'Uncategorized').toDTO())
  }

  @action
  public lazyLoadEditVM(categoryGuid: string, attempts: number = 0) {
    if (attempts === 10) return
    if (categoryGuid === 'new') {
      this.editVM = new CategoryEditVM(this.rootStore, Category.create(this.rootStore.boardsStore.currentBoardId), true)
    } else if (categoryGuid === 'empty') {
      this.editVM = null
    } else {
      const foundCat = this.get(categoryGuid)
      if (!foundCat) setTimeout(() => this.lazyLoadEditVM(categoryGuid, attempts++), 500)
      else this.editVM = new CategoryEditVM(this.rootStore, foundCat.clone())
    }
  }

  @computed
  public get currentBoardRecords() {
    return this.persistedRecords.filter(
      (e) => e.BoardId === this.rootStore.boardsStore.currentBoardId || e.BoardId === 0
    )
  }

  protected getWorker() {
    return (DataStoreServiceWorker as any)() as typeof DataStoreServiceWorker
  }
}
