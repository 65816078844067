import React from 'react'
import { inject, observer } from 'mobx-react'
import { MealsStore } from '../../store/MealsStore'
import { AppStore } from 'src/stores/AppStore'
import DayGroup from './DayGroup'

interface Props {
  mealsStore?: MealsStore
  appStore?: AppStore
}

const MealsList: React.FC<Props> = ({ mealsStore, appStore }) => {
  const { listVM } = mealsStore
  if (!listVM) return null
  if (appStore.bodyHeight === 0) return null
  if (!listVM.hasVisibleMeals) return null

  const renderDayGroups = () => {
    return listVM.dayGroups.map((grp, idx) => <DayGroup key={'mpr' + grp.forDate.format() + idx} row={grp} />)
  }

  return <div id='DayGroupsContainer'>{renderDayGroups()}</div>
}

export default inject('mealsStore', 'appStore')(observer(MealsList))
