import React from 'react'
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { AppStore } from 'src/stores/AppStore'
import { RootStore } from 'src/stores/RootStore'
import MealsList from './MealsList'
import { MealsStore } from '../../store/MealsStore'
import { EvilIcons, MaterialCommunityIcons } from 'react-web-vector-icons'
import MealsListPageMoreMenu from './MealsListPageMoreMenu'

interface Props {
  mealsStore: MealsStore
  appStore: AppStore
  rootStore: RootStore
  history: any
}

@inject('mealsStore')
@inject('appStore')
@inject('rootStore')
@observer
export default class MealsListPage extends React.Component<Props> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.appStore.setCurrentRoute('/mealslist')
  }

  handleGoBack() {
    this.props.appStore.handleGoBack(this.props.history)
  }

  handleRefresh(event) {
    const { listVM } = this.props.mealsStore
    listVM.refresh().then(() => {
      setTimeout(() => {
        if (event) event.target.complete()
      }, 1000)
    })
  }

  renderRefresher() {
    return (
      <IonRefresher slot='fixed' onIonRefresh={e => this.handleRefresh(e)}>
        <IonRefresherContent />
      </IonRefresher>
    )
  }

  renderNoMeals() {
    const { listVM } = this.props.mealsStore
    if (listVM.hasVisibleMeals) return null
    return (
      <div className='no-items'>
        No meal slots available for planning.
        <br />
        Adjust your meal slots...
        <br />
        <br />
        <IonButton slot='icon-only' color='tertiary' type='button' onClick={() => listVM.goToSetup()}>
          <EvilIcons name='gear' size={20} />
        </IonButton>
      </div>
    )
  }

  render() {
    const { listVM } = this.props.mealsStore
    if (!listVM) return null

    const renderTodayButton = pos => {
      if (pos === 'right' && listVM.isIos) return
      if (pos === 'left' && !listVM.isIos) return
      return (
        <IonButton onClick={() => listVM.goToToday()}>
          <MaterialCommunityIcons name='calendar-today' size={20} />
        </IonButton>
      )
    }

    return (
      <>
        {this.props.appStore.showAppHeader && (
          <IonHeader>
            <IonToolbar />
          </IonHeader>
        )}

        <IonHeader id='MealsListPage_Header'>
          <IonToolbar color='secondary'>
            <IonButtons slot='start'>{renderTodayButton('left')}</IonButtons>
            <IonTitle>Planner</IonTitle>
            <IonButtons slot='end'>
              {renderTodayButton('right')}
              <IonButton onClick={() => listVM.decreaseStartDate()}>
                <EvilIcons name='chevron-left' size={20} />
              </IonButton>
              <IonButton onClick={() => listVM.inccreaseStartDate()}>
                <EvilIcons name='chevron-right' size={20} />
              </IonButton>
              <MealsListPageMoreMenu />
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent
          id='MealsListPage_Content'
          ref={e => this.props.appStore.applyScrollStyles(e)}
          style={{ height: this.props.appStore.listHeightPx }}
          scrollY
        >
          {this.renderRefresher()}
          {this.renderNoMeals()}
          <MealsList />
        </IonContent>
      </>
    )
  }
}
