import { RootStore } from 'src/stores/RootStore'
import { computed, action } from 'mobx'
import { Recipe } from '../../aggregate/Recipe'
import { Category } from '../../aggregate/Category'
import { RowVM } from '@elexient/elexiapp.bits.shared'
import { RecipesListVM } from './RecipesListVM'

export class RecipeRowVM extends RowVM<RootStore, RecipesListVM, Recipe> {
  public get height(): number {
    if (this.isHidden) return 0
    return 45
  }

  public get recipe() {
    return this.record
  }

  public get keyword(): string {
    return this.name + this.recipeCategoryNames
  }

  @computed
  public get name(): string {
    return this.recipe.Name
  }

  @computed
  public get recipeGuid(): string {
    return this.recipe.RecipeGuid
  }

  @action
  public click() {
    this.rootStore.appStore.history.push('/recipeedit/' + this.recipe.RecipeGuid)
  }

  @computed
  public get sortName(): string {
    if (!this.name) return ''
    return this.name.toLowerCase()
  }

  @computed
  public get recipeCategoryNames(): string[] {
    const names = []
    this.recipe.Categories.filter((e) => !e.IsDeleted).forEach((e) => {
      const foundCat = this.rootStore.recipeCategoriesStore.get(e.RecipeCategoryGuid)
      if (foundCat) names.push(foundCat.Name)
    })
    if (names.length === 0) names.push('(No Category)')
    return names
  }

  @computed
  public get categories(): Array<Category> {
    return this.recipe.Categories.filter((e) => !e.IsDeleted)
  }

  public hasCategory(recipeCategoryGuid: string): boolean {
    return (
      this.recipe.Categories.filter((e) => !e.IsDeleted).findIndex(
        (e) => e.RecipeCategoryGuid === recipeCategoryGuid
      ) !== -1
    )
  }
}
