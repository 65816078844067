import React from 'react'
import { inject, observer } from 'mobx-react'
import { RecipesStore } from '../../store/RecipesStore'
import { IonList, IonIcon, IonRippleEffect, IonChip, IonLabel } from '@ionic/react'
import CategoryRow from './CategoryRow'
import { CategoryRowVM } from '../../view-models/edit/CategoryRowVM'
import { add } from 'ionicons/icons'

interface Props {
  recipesStore?: RecipesStore
}

const CategoriesList: React.FC<Props> = ({ recipesStore }) => {
  const { editVM } = recipesStore
  if (!editVM) return null
  if (!editVM.steps) return null
  if (editVM.currentTabIndex !== '3') return null

  const renderCategories = () => {
    return editVM.categories.map((e: CategoryRowVM) => <CategoryRow row={e} key={e.recipeCategoryGuid} />)
  }

  const renderAddButton = () => {
    return (
      <IonChip color='light' onClick={() => editVM.showCategoryNewModal()}>
        <IonIcon color='tertiary' icon={add} />
        <IonLabel color='tertiary' style={{ marginLeft: '-6px' }}>
          New Category
        </IonLabel>
      </IonChip>
    )
  }

  return (
    <div id='CategoriesList'>
      <IonList>{renderCategories()}</IonList>
      {renderAddButton()}
    </div>
  )
}

export default inject('recipesStore', 'appStore')(observer(CategoriesList))
