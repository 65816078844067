import React from 'react'
import { inject, observer } from 'mobx-react'
import { List } from '@elexient/elexiapp.bits.shared'
import { ListItemsStore } from '../../store/ListItemsStore'
import { ListItemRowVM } from '../../view-models/ListItemRowVM'
import { ListItemGroupRowVM } from '../../view-models/ListItemGroupRowVM'
import ListItemRow from './ListItemRow'
import ListItemGroupRow from './ListItemGroupRow'
import { AppStore } from '../../../stores/AppStore'

interface Props {
  listItemsStore?: ListItemsStore
  appStore?: AppStore
}

const ListItemsList: React.FC<Props> = ({ listItemsStore, appStore }) => {
  const { listVM } = listItemsStore
  if (!listVM.hasRows) return null

  const renderNormalRow = (row: ListItemRowVM, index, style) => {
    return <ListItemRow key={'rr' + index} row={row} style={style} />
  }

  const renderGroupRow = (row: ListItemGroupRowVM, index, style) => {
    return <ListItemGroupRow key={'rgrp' + index} row={row} style={style} />
  }

  return (
    <List
      listVM={listVM}
      normalRow={(row, index, style) => renderNormalRow(row, index, style)}
      groupRow={(row, index, style) => renderGroupRow(row, index, style)}
      onRef={(e) => appStore.applyScrollStyles(e)}
    />
  )
}

export default inject('listItemsStore', 'appStore')(observer(ListItemsList))
