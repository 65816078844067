import React from 'react'
import { observer } from 'mobx-react'
import { IonIcon } from '@ionic/react'
import { chevronDown, chevronForward } from 'ionicons/icons'
import { ListItemGroupRowVM } from '../../view-models/ListItemGroupRowVM'

interface Props {
  row: ListItemGroupRowVM
  style: any
}

const ListItemGroupRow: React.FC<Props> = ({ row, style }) => {
  const rowStyle = {
    borderLeftColor: row.color,
  }

  const renderRightIcon = () => {
    if (!row.isCollapsed) return
    return <IonIcon icon={chevronForward} />
  }

  const renderDownIcon = () => {
    if (row.isCollapsed) return
    return <IonIcon icon={chevronDown} />
  }

  return (
    <div style={style} id='ListItemGroupRow' onClick={() => row.click()}>
      <div className='row' style={rowStyle}>
        <div className='name'>{row.name}</div>
        {renderRightIcon()}
        {renderDownIcon()}
      </div>
    </div>
  )
}

export default observer(ListItemGroupRow)
