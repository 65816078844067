import { RootStore } from '../../../stores/RootStore'
import { computed, observable, action, makeObservable } from 'mobx'
import { MealCategoryRowVM } from './MealCategoryRowVM'
import { arrayMove } from 'react-sortable-hoc'
import { MealCategoriesService } from '../../services/MealCategoriesService'

export class MealCategoriesListVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeObservable(this)
  }

  @action
  public handleGoBack() {
    if (this.sortMode) {
      this.cancelSort()
      return
    }
    this.rootStore.appStore.handleGoBack()
  }

  @observable
  public clonedRows: Array<MealCategoryRowVM> = []

  @computed
  public get rows() {
    if (this.sortMode) return this.clonedRows
    return this.rootStore.mealCategoriesStore.currentBoardRecords.map(
      e => new MealCategoryRowVM(this.rootStore, this, e)
    )
  }

  @observable
  public sortMode: boolean = false

  @action
  private cloneRows() {
    this.clonedRows = this.rootStore.mealCategoriesStore.currentBoardRecords.map(
      e => new MealCategoryRowVM(this.rootStore, this, e.clone())
    )
  }

  @computed
  public get rowsOrdered(): Array<MealCategoryRowVM> {
    return this.rows.slice().sort((a, b) => a.Rank - b.Rank)
  }

  @action
  public goToAddNew() {
    this.rootStore.appStore.history.push('/mealcategoryedit/new')
  }

  @action
  public enterSortMode() {
    this.sortMode = true
    this.cloneRows()
  }

  @action
  public saveSort() {
    const svc = new MealCategoriesService(this.rootStore)
    this.rows.forEach(e => svc.save(e.toDTO()))
    this.sortMode = false
  }

  @action
  public cancelSort() {
    this.sortMode = false
  }

  @action
  public sortRows(oldIndex: number, newIndex: number) {
    const oldRows = this.rowsOrdered.map(e => e)
    const newRows = arrayMove(oldRows, oldIndex, newIndex)
    newRows.forEach((e, idx) => e.setRank(idx + 1))
  }
}
