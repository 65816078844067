import React from 'react'
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonTextarea,
  IonInput,
  IonButton,
  IonLabel,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonGrid,
  IonRow,
  IonCol,
  IonItemDivider,
  IonButtons,
  IonIcon,
} from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { AppStore } from 'src/stores/AppStore'
import { SketchPicker } from 'react-color'
import BackIcon from 'src/shared/BackIcon'
import { checkmarkSharp } from 'ionicons/icons'

interface Props {
  appStore: AppStore
  history: any
}

@inject('appStore')
@observer
export default class ThemeGenPage extends React.Component<Props> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.appStore.setCurrentRoute('/themegen')
  }

  handleGoBack() {
    this.props.appStore.handleGoBack(this.props.history)
    const { themeGenVM: vm } = this.props.appStore
    vm.hideColorPicker()
  }

  render() {
    const { themeGenVM: vm } = this.props.appStore
    if (!vm) return null

    return (
      <>
        {this.props.appStore.showAppHeader && (
          <IonHeader>
            <IonToolbar />
          </IonHeader>
        )}

        <IonHeader id='ThemeGenPage_Header'>
          <IonToolbar color='secondary'>
            <IonButtons slot='start'>
              <IonButton onClick={() => this.handleGoBack()}>
                <BackIcon />
              </IonButton>
            </IonButtons>
            <IonTitle>Theme Generator</IonTitle>
            <IonButtons slot='end'>
              <IonButton onClick={() => this.handleGoBack()}>
                <IonIcon icon={checkmarkSharp} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent
          id='ThemeGenPage_Content'
          style={{ height: this.props.appStore.listHeightPx }}
          ref={e => this.props.appStore.applyScrollStyles(e)}
        >
          <IonItem>
            <IonLabel position='floating'>Theme</IonLabel>
            <IonSelect
              interface='popover'
              value={vm.currentTheme}
              onIonChange={(e: any) => vm.loadTheme(e.target.value)}
            >
              <IonSelectOption value='default'>Default</IonSelectOption>
              <IonSelectOption value='dark'>Dark</IonSelectOption>
              <IonSelectOption value='custom'>(Custom)</IonSelectOption>
            </IonSelect>
          </IonItem>

          <IonGrid>
            <IonRow>
              <IonCol sizeXs='6'>
                <div className='color-demo' style={{ backgroundColor: vm.primaryColor }} />
                <IonItem>
                  <IonLabel position='floating'>Primary</IonLabel>
                  <IonInput
                    disabled={!vm.isCustom}
                    value={vm.primaryColor}
                    onIonFocus={() => vm.setFocused('primary', true)}
                    onIonChange={(e: any) => vm.setColor('primary', e.target.value)}
                  />
                </IonItem>
              </IonCol>
              <IonCol sizeXs='6'>
                <div className='color-demo' style={{ backgroundColor: vm.secondaryColor }} />
                <IonItem>
                  <IonLabel position='floating'>Secondary</IonLabel>
                  <IonInput
                    disabled={!vm.isCustom}
                    value={vm.secondaryColor}
                    onIonFocus={() => vm.setFocused('secondary', true)}
                    onIonChange={(e: any) => vm.setColor('secondary', e.target.value)}
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol sizeXs='6'>
                <div className='color-demo' style={{ backgroundColor: vm.tertiaryColor }} />
                <IonItem>
                  <IonLabel position='floating'>Tertiary</IonLabel>
                  <IonInput
                    disabled={!vm.isCustom}
                    value={vm.tertiaryColor}
                    onIonFocus={() => vm.setFocused('tertiary', true)}
                    onIonChange={(e: any) => vm.setColor('tertiary', e.target.value)}
                  />
                </IonItem>
              </IonCol>
              <IonCol sizeXs='6'>
                <div className='color-demo' style={{ backgroundColor: vm.boardColor }} />
                <IonItem>
                  <IonLabel position='floating'>Board</IonLabel>
                  <IonInput
                    disabled={!vm.isCustom}
                    value={vm.boardColor}
                    onIonFocus={() => vm.setFocused('board', true)}
                    onIonChange={(e: any) => vm.setColor('board', e.target.value)}
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol sizeXs='6'>
                <div className='color-demo' style={{ backgroundColor: vm.mediumColor }} />
                <IonItem>
                  <IonLabel position='floating'>Medium</IonLabel>
                  <IonInput
                    disabled={!vm.isCustom}
                    value={vm.mediumColor}
                    onIonFocus={() => vm.setFocused('medium', true)}
                    onIonChange={(e: any) => vm.setColor('medium', e.target.value)}
                  />
                </IonItem>
              </IonCol>
              <IonCol sizeXs='6'>
                <div className='color-demo' style={{ backgroundColor: vm.lightColor }} />
                <IonItem>
                  <IonLabel position='floating'>Light</IonLabel>
                  <IonInput
                    disabled={!vm.isCustom}
                    value={vm.lightColor}
                    onIonFocus={() => vm.setFocused('light', true)}
                    onIonChange={(e: any) => vm.setColor('light', e.target.value)}
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow style={{ display: 'flex', justifyContent: 'center' }}>
              {vm.pickerShown && (
                <SketchPicker
                  color={vm.getColor(vm.focusedColor)}
                  disableAlpha={true}
                  onChange={c => {
                    vm.setColor(vm.focusedColor, c.hex)
                    vm.apply(true)
                  }}
                />
              )}
            </IonRow>
          </IonGrid>
          <br />
          <br />
          <br />
        </IonContent>
      </>
    )
  }
}
