import React from 'react'
import { Observer, observer } from 'mobx-react'
import { IonIcon, IonRippleEffect, IonLabel } from '@ionic/react'
import { add, remove } from 'ionicons/icons'
import { ItemRowVM } from '../view-models/ItemRowVM'
import { faTasks } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Feather } from 'react-web-vector-icons'

interface Props {
  row: ItemRowVM
  style: any
  index: number
}

const ItemRow: React.FC<Props> = ({ row, style, index }) => {
  const style2 = {
    borderLeftColor: row.categoryColor,
    ...style,
  }

  const renderQtySection = () => {
    if (!row.showQty) return null
    return (
      <div slot='end' className='qty-section'>
        <div
          className='ion-activatable qty-button minus-button'
          hidden={!row.hasQuantity}
          onClick={(e) => {
            e.stopPropagation()
            row.decreaseQuantity()
          }}
        >
          <IonIcon icon={remove} color='tertiary' />
          <IonRippleEffect type='unbounded' />
        </div>
        <div className='qty' hidden={!row.hasQuantity}>
          <IonLabel>{row.quantity}</IonLabel>
        </div>
        <div
          className='ion-activatable qty-button plus-button'
          hidden={!row.hasQuantity}
          onClick={(e) => {
            e.stopPropagation()
            row.increaseQuantity()
          }}
        >
          <IonIcon icon={add} color='tertiary' />
          <IonRippleEffect type='unbounded' />
        </div>
      </div>
    )
  }

  const renderCartIcon = () => {
    if (row.iconName !== 'cart') return
    return (
      <div
        className='icon-container checked'
        onClick={(e) => {
          e.stopPropagation()
          row.toggle()
        }}
      >
        <Feather name='circle' size={22} />
        <FontAwesomeIcon
          onClick={(e) => {
            e.stopPropagation()
            row.toggle()
          }}
          icon={faTasks}
          className='cart for-checkbox'
        />
      </div>
    )
  }

  const renderNotes = () => {
    if (!row.hasNotes) return
    return <div className='notes'>{row.notes}</div>
  }

  const renderGottenIcon = () => {
    if (!row.isGotten) return
    if (row.iconName === 'checkmark') return
    return (
      <div
        className='icon-container checked'
        onClick={(e) => {
          e.stopPropagation()
          row.toggle()
        }}
      >
        <Feather name='check-circle' size={22} />
      </div>
    )
  }

  const renderCheckmarkIcon = () => {
    if (row.iconName !== 'checkmark') return
    return (
      <div
        className='icon-container checked'
        onClick={(e) => {
          e.stopPropagation()
          row.toggle()
        }}
      >
        <Feather name='check-circle' size={22} />
      </div>
    )
  }

  const renderNotGottenIcon = () => {
    if (row.iconName === 'cart') return
    if (row.iconName === 'checkmark') return
    if (row.isGotten) return
    return (
      <div
        className='icon-container'
        onClick={(e) => {
          e.stopPropagation()
          row.toggle()
        }}
      >
        <Feather name='plus-circle' size={22} />
      </div>
    )
  }

  return (
    <Observer>
      {() => {
        return (
          <div
            key={String(row.key) + String(index) + row.rowType}
            style={style2}
            id='ItemRow'
            onClick={() => row.click()}
            className={row.className}
          >
            {renderGottenIcon()}
            {renderNotGottenIcon()}
            {renderCheckmarkIcon()}
            {renderCartIcon()}
            <div className='name'>
              {row.name}
              {renderNotes()}
            </div>
            {renderQtySection()}
          </div>
        )
      }}
    </Observer>
  )
}

export default observer(ItemRow)
