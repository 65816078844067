import * as signalR from '@microsoft/signalr'
import { AppStore } from '../stores/AppStore'
import { MessagePackHubProtocol } from '@microsoft/signalr-protocol-msgpack'
import * as Sentry from '@sentry/browser'

export class SignalRServiceV2 {
  private appStore: AppStore
  private connection: signalR.HubConnection
  private listeners = []

  constructor(appStore: AppStore) {
    this.appStore = appStore
  }

  public listenToServer() {
    if (!this.appStore.isLoggedIn) {
      console.log('SIGNALR SVC: NOT LOGGED IN')
      setTimeout(() => this.listenToServer(), 200)
      return
    }
    window.Offline.check()
    if (window.Offline.state === 'down') {
      console.log('SIGNALR SVC: INTERNET DOWN')
      setTimeout(() => this.listenToServer(), 2000)
      return
    }
    this.restartConnection()
  }

  public attachNewListener(updateType: string, callback: (e) => void, replace: boolean = false) {
    if (replace) this.removeListener(updateType)
    if (this.listeners.find((e) => e.updateType === updateType)) return
    this.listeners.push({ updateType, callback, attached: false })
    this.attachListeners()
  }

  private removeListener(updateType: string) {
    this.connection.off(updateType)
    const idx = this.listeners.findIndex((e) => e.updateType === updateType)
    this.listeners.splice(idx, 1)
  }

  private markAllUnattached() {
    this.listeners.forEach((e) => (e.attached = false))
  }

  private attachListeners() {
    if (!this.connection) return
    this.listeners
      .filter((e) => !e.attached)
      .forEach((listener) => {
        this.connection.on(listener.updateType.toLowerCase(), (ev) => {
          if (process.env.IS_DEV_MODE === '1') {
            console.log('received update: ' + listener.updateType + ':')
            console.log(ev)
          }
          listener.callback(ev)
        })
        if (process.env.IS_DEV_MODE === '1') {
          console.log('attached listener: ' + listener.updateType.toLowerCase())
        }
        listener.attached = true
      })
  }

  private restartConnection() {
    const url = process.env.REACT_APP_API_URL + '/appupdates'
    this.markAllUnattached()
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(url, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => this.appStore.token,
      })
      // .configureLogging(signalR.LogLevel.Trace)
      // .withHubProtocol(new MessagePackHubProtocol())
      .build()
    this.attachListeners()
    this.connection.on('send', (e) => {
      if (process.env.REACT_APP_IS_DEV_MODE !== '1') return
      console.log(e)
    })
    this.connection.onclose(() => this.listenToServer())
    this.connection
      .start()
      .then(() => this.connection.invoke('Echo', 'Hello: UPDATES'))
      .catch((e) => {
        console.error(e)
        setTimeout(() => this.listenToServer(), 1000)
      })
  }
}
