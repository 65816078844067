import { RootStore } from '../../../stores/RootStore'
import { Day } from '../../aggregate/Day'
import { MealCategoryRowVM } from '../list/MealCategoryRowVM'
import { computed, action, makeObservable } from 'mobx'
import { MealCategoryEditVM } from './MealCategoryEditVM'
import moment from 'moment'

export class DayRowVM {
  private rootStore: RootStore
  private editVM: MealCategoryEditVM
  private day: Day

  constructor(rootStore: RootStore, editVM: MealCategoryEditVM, day: Day) {
    this.rootStore = rootStore
    this.editVM = editVM
    this.day = day
    makeObservable(this)
  }

  @computed
  public get name(): string {
    return this.day.Name
  }

  @action
  public toggleChecked() {
    this.day.toggleChecked()
  }

  @action
  public setTime(val: string) {
    this.day.setTime(val)
  }

  @action
  public clearTime(): void {
    this.toggleSpecificTime()
  }

  @computed
  public get time(): string {
    if (!this.day.Enabled) return ''
    if (this.day.Time) return this.day.Time
    if (this.editVM.time) return this.editVM.time
    return ''
  }

  @computed
  public get timeAsDate(): Date {
    return moment(this.time, 'H:mm a').toDate()
  }

  @computed
  public get dayIndex(): number {
    return this.day.DayIndex
  }

  @computed
  public get enabled(): boolean {
    return this.day.Enabled
  }

  @computed
  public get dayLetter(): string {
    return this.day.DayLetter
  }

  @computed
  public get specificTime(): boolean {
    return this.day.SpecificTime
  }

  @action
  public toggleSpecificTime() {
    this.day.toggleSpecificTime()
    if (!this.time) this.day.setTime('12:00 pm')
  }
}
