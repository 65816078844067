import React from 'react'
import {
  IonList,
  IonItem,
  IonButton,
  IonModal,
  IonLabel,
  IonInput,
  IonCard,
  IonCardHeader,
  IonTitle,
  IonCardContent,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonFab,
  IonFabButton,
  IonGrid,
  IonRow,
  IonCol,
  IonSelect,
  IonSelectOption,
  IonItemDivider,
  IonTextarea,
} from '@ionic/react'
import { checkmark, close, arrowBack, add, checkmarkSharp } from 'ionicons/icons'
import { observer, inject } from 'mobx-react'
import ItemsList from '../../../items-select/views/ItemsSelectList'
import { RecipesStore } from 'src/recipes/store/RecipesStore'
import ItemsSelectModal from 'src/items-select/views/ItemsSelectModal'
import BackIcon from 'src/shared/BackIcon'

interface Props {
  recipesStore?: RecipesStore
}

const RecipeItemEditModal: React.FC<Props> = ({ recipesStore }) => {
  const { editVM } = recipesStore
  if (!editVM) return null
  const row = editVM.editingRecipeItem

  const handleSave = e => {
    e.preventDefault()
    row.save()
  }

  const renderSelectItemButton = () => {
    if (row.hasItem) return
    return (
      <IonRow>
        <IonCol>
          <div className='btn-container'>
            <div className='btn' onClick={() => row.openItemSelect()}>
              <IonIcon icon={add} size='small' />
              <div>Select Item</div>
            </div>
          </div>
        </IonCol>
      </IonRow>
    )
  }

  const renderSelectedItemButton = () => {
    if (!row.hasItem) return
    return (
      <IonRow>
        <IonCol>
          <div className='btn-container'>
            <div className='btn' onClick={() => row.clearItem()}>
              <IonIcon icon={close} size='small' />
              <div>{row.fullItemName}</div>
            </div>
          </div>
        </IonCol>
      </IonRow>
    )
  }

  const renderHeader = () => {
    if (!row) return null
    return (
      <IonHeader>
        <IonToolbar color='secondary'>
          <IonButtons slot='start'>
            <IonButton onClick={() => row.hideModal()}>
              <BackIcon />
            </IonButton>
          </IonButtons>
          <IonTitle>{row.modalTitle}</IonTitle>
          <IonButtons slot='end'>
            <IonButton onClick={() => row.cancel()}>
              <IonIcon icon={close} />
            </IonButton>
            <IonButton onClick={() => row.save()}>
              <IonIcon icon={checkmarkSharp} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
    )
  }

  const renderIngredient = () => {
    return (
      <IonRow>
        <IonCol sizeMd='12'>
          <IonItem lines={'full'}>
            <IonLabel position='floating'>Ingredient</IonLabel>
            <IonInput
              className='description-input'
              value={row.description}
              onIonChange={(e: any) => row.setDescription(e.target.value)}
            />
          </IonItem>
        </IonCol>
      </IonRow>
    )
  }

  const renderContent = () => {
    if (!row) return null
    return (
      <IonContent>
        <form onSubmit={e => handleSave(e)}>
          <IonGrid>
            {renderIngredient()}
            {/* <IonRow>
              <IonCol sizeMd='6' sizeXs='6'>
                <IonItem lines={'full'}>
                  <IonLabel position='floating'>Quantity</IonLabel>
                  <IonInput
                    type='number'
                    value={row.quantity.toString()}
                    onIonChange={(e: any) => row.setQuantity(e.target.value)}
                  />
                </IonItem>
              </IonCol>
              <IonCol sizeMd='6' sizeXs='6'>
                <IonItem lines={'full'}>
                  <IonLabel position='floating'>
                    Quantity Type: {row.parsedDescription && row.parsedDescription.unit}
                  </IonLabel>
                  <IonSelect
                    interface='popover'
                    key={row.itemGuid + '_' + row.selectedQuantityTypeName}
                    value={row.quantityTypeAbbr}
                    onIonChange={(e: any) => row.setQuantityType(e.target.value)}
                  >
                    {row.quantityOptions.map(e => (
                      <IonSelectOption key={e.abbr} value={e.abbr}>
                        {e.name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow> */}
            <IonRow>
              <IonCol id='ItemSelectPage_Content'>
                {renderSelectItemButton()}
                {renderSelectedItemButton()}
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </IonContent>
    )
  }

  const renderFab = () => {
    if (!row) return null
    return (
      <IonFab vertical='bottom' horizontal='end'>
        <IonFabButton
          color='tertiary'
          onClick={() => row.save()}
          style={{
            marginTop: '-60px',
            marginLeft: '-60px',
          }}
        >
          <IonIcon icon={checkmarkSharp} />
        </IonFabButton>
      </IonFab>
    )
  }

  return (
    <IonModal
      id='RecipeItemEditModal'
      isOpen={editVM.editingRecipeItemGuid !== null}
      showBackdrop={true}
      onDidDismiss={() => row && row.hideModal()}
    >
      {renderHeader()}
      {renderContent()}
      {renderFab()}
      {row && <ItemsSelectModal itemsList={row.itemsList} />}
    </IonModal>
  )
}

export default inject('recipesStore')(observer(RecipeItemEditModal))
