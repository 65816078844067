import * as convert from 'convert-units'
import { observable, computed, action } from 'mobx'

export class QuantityType {
  constructor(abbr: string) {
    this.abbr = abbr
  }

  @observable public abbr: string = ''
  @observable public value: number = 0

  @action
  public setValue(val: number) {
    this.value = val
  }

  @computed
  public get name(): string {
    // return this.abbr
    if (this.value > 1) return convert().describe(this.abbr).plural
    return convert().describe(this.abbr).singular
  }
}
