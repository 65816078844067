import React from 'react'
import { inject, observer } from 'mobx-react'
import { RecipesStore } from '../../store/RecipesStore'
import {
  IonList,
  IonLabel,
  IonItem,
  IonInput,
  IonCol,
  IonGrid,
  IonButton,
  IonIcon,
  IonButtons,
  IonRow,
  IonTextarea,
} from '@ionic/react'
import { open } from 'ionicons/icons'

interface Props {
  recipesStore?: RecipesStore
}

const DetailsForm: React.FC<Props> = ({ recipesStore }) => {
  const { editVM } = recipesStore
  if (!editVM) return null
  if (editVM.currentTabIndex !== '0') return null

  const renderName = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Name</IonLabel>
        <IonInput
          type='text'
          value={editVM.name}
          onIonChange={(e: any) => editVM.setName(e.target.value)}
          ref={(e) => editVM.disableAutoComplete(e)}
        />
      </IonItem>
    )
  }

  const renderLink = () => {
    return (
      <IonGrid>
        <IonRow>
          <IonCol sizeXs='10'>
            <IonItem lines={'inset'}>
              <IonLabel position='floating'>Recipe Link</IonLabel>
              <IonTextarea
                autoGrow
                value={editVM.linkUrl}
                onIonChange={(e: any) => editVM.setLinkUrl(e.target.value)}
                ref={(e) => editVM.disableAutoComplete(e)}
              />
            </IonItem>
          </IonCol>
          <IonCol sizeXs='2'>
            <IonButton size='default' color='light' onClick={() => editVM.openLink()}>
              <IonIcon icon={open} />
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
    )
  }

  const renderNotes = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Description</IonLabel>
        <IonTextarea
          value={editVM.notes}
          onIonChange={(e: any) => editVM.setNotes(e.target.value)}
          // ref={e => editVM.disableAutoComplete(e)}
          rows={4}
          autoGrow
        />
      </IonItem>
    )
  }

  return (
    <div id='DetailsForm'>
      <form autoComplete='off'>
        <IonList>
          {renderName()}
          {renderLink()}
          {renderNotes()}
        </IonList>
      </form>
    </div>
  )
}

export default inject('recipesStore')(observer(DetailsForm))
