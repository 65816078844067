import { ActionTypeEnum } from '../enum/ActionTypeEnum'
import { ICategoryDTO } from '../../categories/dtos/ICategoryDTO'
import { IListItemDTO } from '../../list-items/dtos/IListItemDTO'
import { IItemDTO } from '../../items/dtos/IItemDTO'
import { IRecipeCategoryDTO } from '../../recipe-categories/dtos/IRecipeCategoryDTO'
import { IMealCategoryDTO } from '../../meal-categories/dtos/IMealCategoryDTO'
import { IBoardDTO } from '../../boards/dtos/IBoardDTO'
import { IRecipeDTO } from '../../recipes/dto/IRecipeDTO'
import { IMealDTO } from '../../meals/dtos/IMealDTO'
import { nameOf } from '../../shared/nameOf'
import { serializable } from 'serializr'
import { makeObservable, observable } from 'mobx'

export class Action {
  static create(type: number, dto: any): Action {
    const action = new Action()
    action.type = type
    action.dtoString = JSON.stringify(dto)
    return action
  }

  constructor() {
    makeObservable(this)
  }

  @serializable public type: number = 0
  @serializable public dtoString: string = ''
  @serializable @observable public attempts: number = 0

  public get dto(): any {
    return JSON.parse(this.dtoString)
  }

  public get typeName(): string {
    if (this.type === ActionTypeEnum.ITEM_SAVE) return 'ITEM_SAVE'
    if (this.type === ActionTypeEnum.LISTITEM_SAVE) return 'LISTITEM_SAVE'
    if (this.type === ActionTypeEnum.CATEGORY_SAVE) return 'CATEGORY_SAVE'
    if (this.type === ActionTypeEnum.RECIPECATEGORY_SAVE) return 'RECIPECATEGORY_SAVE'
    if (this.type === ActionTypeEnum.MEALCATEGORY_SAVE) return 'MEALCATEGORY_SAVE'
    if (this.type === ActionTypeEnum.BOARD_SAVE) return 'BOARD_SAVE'
    if (this.type === ActionTypeEnum.RECIPE_SAVE) return 'RECIPE_SAVE'
    if (this.type === ActionTypeEnum.MEAL_SAVE) return 'MEAL_SAVE'
    return '(NOT SET)'
  }

  public get itemType(): string {
    return this.typeName.split('_')[0].toLowerCase()
  }

  public get keyName(): string {
    if (this.type === ActionTypeEnum.ITEM_SAVE) return nameOf<IItemDTO, string>(e => e.ItemGuid)
    if (this.type === ActionTypeEnum.LISTITEM_SAVE) return nameOf<IListItemDTO, string>(e => e.ListItemGuid)
    if (this.type === ActionTypeEnum.CATEGORY_SAVE) return nameOf<ICategoryDTO, string>(e => e.CategoryGuid)
    if (this.type === ActionTypeEnum.RECIPECATEGORY_SAVE)
      return nameOf<IRecipeCategoryDTO, string>(e => e.RecipeCategoryGuid)
    if (this.type === ActionTypeEnum.MEALCATEGORY_SAVE) return nameOf<IMealCategoryDTO, string>(e => e.MealCategoryGuid)
    if (this.type === ActionTypeEnum.BOARD_SAVE) return nameOf<IBoardDTO, string>(e => e.BoardGuid)
    if (this.type === ActionTypeEnum.RECIPE_SAVE) return nameOf<IRecipeDTO, string>(e => e.RecipeGuid)
    if (this.type === ActionTypeEnum.MEAL_SAVE) return nameOf<IMealDTO, string>(e => e.MealGuid)
    return '(NOT SET)'
  }

  public get key(): string {
    return this.dto[this.keyName]
  }
}
