import { ITheme } from './ITheme'

export const SYSTEM_THEMES: Array<ITheme> = [
  {
    name: 'default',
    primaryColor: '#0ba8b9',
    secondaryColor: '#0391b4',
    tertiaryColor: '#d94672',
    darkColor: '#222428',
    mediumColor: '#C8EAF1',
    lightColor: '#f4f5f8',
    boardColor: '#C8EAF1',
  },
  {
    name: 'dark',
    primaryColor: '#010001',
    secondaryColor: '#181718',
    tertiaryColor: '#1B998B',
    darkColor: '#071108',
    mediumColor: '#464546',
    lightColor: '#2F2E2F',
    boardColor: '#464546',
  },
  {
    name: 'system',
    primaryColor: '#0ba8b9',
    secondaryColor: '#0391b4',
    tertiaryColor: '#d94672',
    darkColor: '#222428',
    mediumColor: '#C8EAF1',
    lightColor: '#f4f5f8',
    boardColor: '#C8EAF1',
  },
]
