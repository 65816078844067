import React from 'react'
import { observer } from 'mobx-react'
import { IonAlert, IonAvatar, IonButton, IonIcon, IonItem, IonLabel } from '@ionic/react'
import { BoardUserRowVM } from '../view-models/BoardUserRowVM'
import { trash } from 'ionicons/icons'
import AvatarColors from '../../theme/AvatarColors'
import UserAvatar from 'react-user-avatar'

interface Props {
  row: BoardUserRowVM
}

const ListItemRow: React.FC<Props> = ({ row }) => {
  const renderDeleteConfirm = () => {
    return (
      <IonAlert
        isOpen={row.deleteConfirmShown}
        onDidDismiss={() => row.hideDeleteConfirm()}
        header={'Remove User'}
        message={'Are you sure you wish to remove this user?'}
        buttons={[
          {
            text: 'No',
            role: 'cancel',
            cssClass: 'primary',
            handler: () => row.hideDeleteConfirm(),
          },
          {
            text: 'Yes',
            handler: () => row.delete(),
          },
        ]}
      />
    )
  }

  const renderDeleteButton = () => {
    if (!row.canDelete) return
    return (
      <IonButton color='tertiary' onClick={() => row.showDeleteConfirm()}>
        <IonIcon icon={trash} />
      </IonButton>
    )
  }

  return (
    <>
      <IonItem lines={'inset'} key={row.key}>
        <IonAvatar slot={'start'}>
          <UserAvatar size='36' colors={AvatarColors} name={row.name} />
        </IonAvatar>
        <IonLabel>
          <h2>{row.name}</h2>
        </IonLabel>
        {renderDeleteButton()}
      </IonItem>
      {renderDeleteConfirm()}
    </>
  )
}

export default observer(ListItemRow)
