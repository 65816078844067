import React from 'react'
import { IonHeader, IonToolbar, IonTitle, IonButtons } from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { AppStore } from 'src/stores/AppStore'
import RecipeImportArea from './RecipeImportContent'

interface Props {
  appStore: AppStore
  history: any
}

@inject('appStore')
@observer
export default class RecipeImportsPage extends React.Component<Props> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.appStore.setCurrentRoute('/recipeimport')
  }

  handleGoBack() {
    this.props.appStore.handleGoBack(this.props.history)
  }

  render() {
    return (
      <>
        {this.props.appStore.showAppHeader && (
          <IonHeader>
            <IonToolbar />
          </IonHeader>
        )}

        <IonHeader id='RecipesListPage_Header'>
          <IonToolbar color='secondary'>
            <IonTitle>Import Recipe</IonTitle>
          </IonToolbar>
        </IonHeader>

        <RecipeImportArea />
      </>
    )
  }
}
