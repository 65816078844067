import { observable, action, computed, reaction, makeObservable } from 'mobx'
import { RootStore } from 'src/stores/RootStore'
import { MealCategoryEditVM } from '../view-models/edit/MealCategoryEditVM'
import { MealCategory } from '../aggregate/MealCategory'
import { MealCategoriesListVM } from '../view-models/list/MealCategoriesListVM'
import { IMealCategoryDTO } from '../dtos/IMealCategoryDTO'
import { DataStore } from '@elexient/elexiapp.bits.shared'
import AgentV2 from '../../AgentV2'
import { makePersistable } from 'mobx-persist-store'
import { deserialize } from 'serializr'
import * as DataStoreServiceWorker from '../../shared/DataStoreService.worker'

export class MealCategoriesStore extends DataStore<RootStore, MealCategory, IMealCategoryDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, MealCategory, AgentV2, 'MealCategories', 'MealCategory')
    super.setRecords(this.persistedRecords)
    makeObservable(this)
    makePersistable(this, { name: 'MealCategoriesStore', properties: ['persistedRecords'] }).then(
      action((st) => this.onHydrationCompleted(st?.isHydrated))
    )
  }

  @action
  public onHydrationCompleted(isHydated?: boolean) {
    this.persistedRecords = observable.array(this.persistedRecords.map((e) => makeObservable(deserialize(MealCategory, e))))
    this.setRecords(this.persistedRecords)
    this.isHydrated = Boolean(isHydated)
    if (process.env.NODE_ENV === 'test') this.isHydrated = true
    this.listVM = new MealCategoriesListVM(this.rootStore)
  }

  @observable public editVM: MealCategoryEditVM = null
  @observable public listVM: MealCategoriesListVM = null
  @observable.shallow public persistedRecords: Array<MealCategory> = []
  @observable public isHydrated: boolean = false

  @computed
  public get currentBoardRecords() {
    return this.persistedRecords.filter((e) => e.BoardId === this.rootStore.boardsStore.currentBoardId)
  }

  @action
  public lazyLoadEditVM(guid: string, attempts: number = 0) {
    if (attempts === 10) return
    if (guid === 'new') {
      this.editVM = new MealCategoryEditVM(
        this.rootStore,
        MealCategory.create(this.rootStore.boardsStore.currentBoardId, this.rootStore.userStore.user.AppUserId)
      )
      return
    }
    if (guid === 'empty') {
      this.editVM = null
      return
    }
    const foundCat = this.get(guid)
    if (!foundCat) {
      setTimeout(() => this.lazyLoadEditVM(guid, attempts++), 500)
      return
    }
    this.editVM = new MealCategoryEditVM(this.rootStore, foundCat.clone())
  }

  protected getWorker() {
    return (DataStoreServiceWorker as any)() as typeof DataStoreServiceWorker
  }
}
