import React from 'react'
import { observer } from 'mobx-react'
import { BoardInvitationRowVM } from '../view-models/BoardInvitationRowVM'
import { IonItem } from '@ionic/react'
import { MaterialCommunityIcons } from 'react-web-vector-icons'

interface Props {
  row: BoardInvitationRowVM
}

const ListItemRow: React.FC<Props> = ({ row }) => {
  return (
    <IonItem id='BoardInvitationRow' lines='full' onClick={() => row.openBoardInvitation()}>
      <MaterialCommunityIcons name='email-check-outline' size={16} />
      &nbsp; {row.boardName}
    </IonItem>
  )
}

export default observer(ListItemRow)
