import { observable, action, makeObservable } from 'mobx'
import generateUUID from '../../utils/UUID'
import { ICategoryDTO } from '../dto/ICategoryDTO'
import { serializable } from 'serializr'

export class Category implements ICategoryDTO {
  public static create(recipeCategoryGuid: string, categoryGuid: string = null) {
    const cat = new Category()
    cat.RecipeCategoryGuid = recipeCategoryGuid
    cat.CategoryGuid = categoryGuid
    if (!categoryGuid) cat.CategoryGuid = generateUUID()
    return cat
  }

  constructor() {
    makeObservable(this)
  }

  @serializable @observable public CategoryGuid: string = ''
  @serializable @observable public RecipeCategoryGuid: string = ''
  @serializable @observable public IsDeleted: boolean = false

  @action
  public toggleDeleted() {
    this.IsDeleted = !this.IsDeleted
  }
}
