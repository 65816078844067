import React from 'react'
import { IonTitle, IonButton, IonText } from '@ionic/react'
import { inject, observer } from 'mobx-react'
import { AuthStore } from 'src/login/store/AuthStore'
import GoogleSmallLogo from '../../assets/images/Google_Logo_400.png'
import '../styles/SignInFooter.scss'

interface Props {
  authStore?: AuthStore
}

const SignInFooter: React.FC<Props> = ({ authStore }) => {
  const { registerVM: vm } = authStore
  if (!vm) return null

  return (
    <div id='HaveAccountFooter'>
      <IonText className='header'>Already have an account?</IonText>
      <br/>
      <IonButton expand='full' type='button' buttonType='text' onClick={() => vm.goToLogin()}>
        <IonText className='sign-in-here'>Sign in here</IonText>
      </IonButton>
    </div>
  )
}

export default inject('authStore')(observer(SignInFooter))
