import { RootStore } from '../../../stores/RootStore'
import { computed, action, observable, makeObservable } from 'mobx'
import { RecipeEditVM } from './RecipeEditVM'
import { QuantityTypeVM } from './QuantityTypeVM'
import { getLines } from '../../../utils/GetLines'
import { Step } from '../../../recipes/aggregate/Step'

export class StepRowVM {
  private rootStore: RootStore
  private step: Step
  private editVM: RecipeEditVM
  private textbox: HTMLIonTextareaElement = null
  @observable private nativeTextbox: HTMLTextAreaElement
  private to: NodeJS.Timer

  constructor(rootStore: RootStore, editVM: RecipeEditVM, step: Step) {
    makeObservable(this)
    this.rootStore = rootStore
    this.step = step
    this.editVM = editVM
  }

  @observable public quantityOptions: Array<QuantityTypeVM> = []
  @observable public modalShown: boolean = false

  @computed
  public get stepGuid(): string {
    return this.step.StepGuid
  }

  @computed
  public get description(): string {
    return this.step.Description
  }

  @action
  public setDescription(val) {
    this.parseLines(val)
  }

  @action
  public parseLines(val) {
    let lines = getLines(val)
    if (lines.length > 1 && lines[0] === '') lines = [lines.join('')]
    const thisVal = lines[0]
    this.step.setDescription(thisVal)
    if (this.nativeTextbox) this.nativeTextbox.value = thisVal
    if (lines.length === 1) return
    lines.splice(0, 1)
    lines = lines.reverse()
    lines.forEach((e, idx) => {
      let doAdd = false
      if (e.trim() !== '') doAdd = true
      if (idx === lines.length - 1 && e === '') doAdd = true
      if (thisVal === '') doAdd = false
      if (this.nextRow && !this.nextRow.hasDescription && idx > 0) {
        this.nextRow.setDescription(e.trim())
        this.nextRow.setFocus()
        doAdd = false
      }
      if (doAdd) {
        this.editVM.addStep(e.trim(), this.rank + 1)
        setTimeout(() => this.nextRow.setFocus(), 1)
      }
    })
  }

  @action
  public combineWithPreviousRow() {
    this.previousRow.setDescription(this.previousRow.description + this.description)
    this.delete()
  }

  @computed
  public get rank(): number {
    return this.step.StepNumber
  }

  @action
  public setRank(val) {
    this.step.setStepNumber(val)
  }

  @computed
  public get hasDescription(): boolean {
    return this.step.Description !== ''
  }

  @computed
  public get key(): string {
    return this.step.StepGuid
  }

  @action
  public delete() {
    this.editVM.deleteStep(this.step.StepGuid)
    this.step.markAsDeleted()
  }

  @computed
  public get isDeleted(): boolean {
    return this.step.IsDeleted
  }

  @computed
  public get previousRow() {
    return this.editVM.steps.find(e => e.rank === this.rank - 1)
  }

  @computed
  public get nextRow() {
    return this.editVM.steps.find(e => e.rank === this.rank + 1)
  }

  @computed
  public get cursorAtStart(): boolean {
    return this.getCursorPosition() === 0
  }

  public getCursorPosition(): number {
    if (!this.nativeTextbox) return -1
    return this.nativeTextbox.selectionStart
  }

  @computed
  public get cursorAtEnd(): boolean {
    return this.getCursorPosition() === this.description.length
  }

  @action
  public moveCursorToEnd() {
    if (!this.nativeTextbox) return
    this.nativeTextbox.selectionStart = this.description.length
    this.nativeTextbox.selectionEnd = this.description.length
  }

  @action
  public moveCursorToStart() {
    if (!this.nativeTextbox) return
    this.nativeTextbox.selectionStart = 0
    this.nativeTextbox.selectionEnd = 0
  }

  @action
  public async setFocus() {
    if (!this.textbox) {
      setTimeout(() => this.setFocus(), 10)
      return
    }
    this.textbox.setFocus()
  }

  @computed
  public get isEmpty(): boolean {
    if (this.description && this.description !== '') return false
    return true
  }

  @action
  public save() {
    if (this.editVM.autoSave) this.editVM.save()
  }

  @action
  public cancel() {}

  @computed
  public get isValid() {
    return false
  }

  public async setTextbox(e: any) {
    this.textbox = e
    if (e) this.nativeTextbox = await this.textbox.getInputElement()
  }

  @computed
  public get editable() {
    return !this.editVM.bulkEditMode
  }
}
