import React from 'react'
import {
  IonContent,
  IonIcon,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonAlert,
  IonFab,
  IonFabButton,
} from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { AppStore } from 'src/stores/AppStore'
import { checkmark, trash, chevronBackOutline, arrowBack, trashOutline, checkmarkSharp } from 'ionicons/icons'
import { RootStore } from 'src/stores/RootStore'
import DetailsForm from './DetailsForm'
import TabButtons from './TabButtons'
import { ItemsStore } from '../../store/ItemsStore'
import CategoryNewModal from 'src/items-select/views/CategoryNewModal'
import BackIcon from 'src/shared/BackIcon'
import ItemDeleteConfirm from './ItemDeleteConfirm'

interface Props {
  itemsStore: ItemsStore
  appStore: AppStore
  rootStore: RootStore
  history: any
  match: any
}

@inject('itemsStore')
@inject('appStore')
@inject('rootStore')
@observer
export default class ItemEditPage extends React.Component<Props> {
  constructor(props) {
    super(props)
    this.props.appStore.setCurrentRoute('/itemedit/' + this.props.match.params['id'])
  }

  componentDidMount() {
    this.props.appStore.setCurrentRoute('/itemedit/' + this.props.match.params['id'])
    this.props.itemsStore.lazyLoadEditVM(this.props.match.params['id'])
  }

  handleGoBack() {
    this.props.appStore.handleGoBack(this.props.history)
  }

  renderDeleteConfirm() {
    const { editVM } = this.props.itemsStore
    if (!editVM) return null
    return (
      <ItemDeleteConfirm
        isOpen={editVM.deleteConfirmShown}
        onDidDismiss={() => editVM.hideDeleteConfirm()}
        onDeleteConfirmed={(shoppingListOnly) => editVM.delete(shoppingListOnly)}
        hasQuantity={editVM.hasQuantity}
        itemName={editVM.itemName}
      />
    )
  }

  render() {
    const { editVM } = this.props.itemsStore
    if (!editVM) return null

    return (
      <>
        {this.props.appStore.showAppHeader && (
          <IonHeader>
            <IonToolbar />
          </IonHeader>
        )}

        <IonHeader id='ItemEditPage_Header'>
          <IonToolbar color='secondary'>
            <IonButtons slot='start'>
              <IonButton onClick={() => editVM.back()}>
                <BackIcon />
              </IonButton>
            </IonButtons>
            <IonTitle>{editVM && editVM.pageTitle}</IonTitle>
            <IonButtons slot='end'>
              <IonButton onClick={() => editVM.showDeleteConfirm()}>
                <IonIcon icon={trashOutline} />
              </IonButton>
              <IonButton onClick={() => editVM.save()} disabled={!editVM.isValid}>
                <IonIcon icon={checkmarkSharp} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
          {/* <TabButtons /> */}
        </IonHeader>

        <IonContent
          id='ItemEditPage_Content'
          ref={(e) => editVM.setContentRef(e)}
          scrollY
          style={{ height: this.props.appStore.listHeight - 54 + 'px' }}
        >
          <DetailsForm />
          <br />
          <br />
          <br />
          <br />
          {this.renderDeleteConfirm()}
        </IonContent>

        <CategoryNewModal categoryNewVM={editVM.categoryNewVM} />

        <IonFab vertical='bottom' horizontal='end'>
          <IonFabButton
            color='tertiary'
            onClick={() => editVM.save()}
            disabled={!editVM.isValid}
            style={{
              marginTop: '-60px',
              marginLeft: '-60px',
            }}
          >
            <IonIcon icon={checkmarkSharp} />
          </IonFabButton>
        </IonFab>
      </>
    )
  }
}
