export default function capitalizeWords(input) {
  if (!input || input === '') return ''
  const words = input.split(' ')
  for (var i = 0, x = words.length; i < x; i++) words[i] = capitalizeWord(words[i])
  return words.join(' ')
}

function capitalizeWord(word) {
  if (word.length === 0) return ''
  let returnWord = word[0].toUpperCase() + word.substr(1)
  if (returnWord === 'Of') returnWord = 'of'
  return returnWord
}
