import agent from '../../Agent'
import agentV2 from '../../AgentV2'
import { RootStore } from '../../stores/RootStore'
import { IBoardDTO } from '../dtos/IBoardDTO'
import { ActionTypeEnum } from '../../actions/enum/ActionTypeEnum'
import { action } from 'mobx'
import { IJoinBoardResult } from '../interfaces/IJoinBoardResult'

export class BoardsService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @action
  public saveBoard(dto: IBoardDTO) {
    this.rootStore.boardsStore.updateFromServer(dto)
    this.rootStore.actionsStore.addAction(ActionTypeEnum.BOARD_SAVE, dto)
  }

  @action
  public async saveOnServer(dto: IBoardDTO) {
    const savedDTO = await agent.Boards.save(dto)
    this.rootStore.boardsStore.updateFromServer(savedDTO)
  }

  @action
  public async generateInvitationCodeOnServer(dto: IBoardDTO) {
    const savedDTO = await agentV2.Boards.generateInvitationCode(dto)
    this.rootStore.boardsStore.updateFromServer(savedDTO)
  }

  @action
  public async joinBoardOnServer(invitationCode: string): Promise<IJoinBoardResult> {
    const result = await agentV2.Boards.joinBoard(invitationCode)
    return result
  }
}
