import { observable, action, computed, makeObservable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { ItemEditVM } from '../../items/view-models/edit/ItemEditVM'
import { Category } from '../../categories/aggregate/Category'
import { CategoriesService } from '../../categories/services/CategoriesService'
import capitalizeWords from '../../utils/CaptilizeWords'

export class CategoryNewVM {
  private rootStore: RootStore
  private editVM: ItemEditVM
  private category: Category

  constructor(rootStore: RootStore, editVM: any) {
    makeObservable(this)
    this.rootStore = rootStore
    this.editVM = editVM
    this.category = Category.create(this.rootStore.boardsStore.currentBoardId)
  }

  @observable public shown: boolean = false

  @computed
  public get showBackdrop(): boolean {
    return this.rootStore.appStore.isSplitPaneVisible
  }

  @computed
  public get name(): string {
    return this.category.Name
  }

  @action
  public setName(val: string) {
    this.category.setName(capitalizeWords(val))
  }

  @computed
  public get isValid(): boolean {
    return this.name !== ''
  }

  @action
  public save() {
    const svc = new CategoriesService(this.rootStore)
    svc.save(this.category.toDTO())
    this.toggleShown()
    this.editVM.setCategory(this.category.CategoryGuid)
  }

  @action
  public cancel() {
    this.hide()
  }

  @action
  public toggleShown() {
    this.shown = !this.shown
  }

  @action
  public hide() {
    this.shown = false
  }
}
