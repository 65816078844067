import { RootStore } from 'src/stores/RootStore'
import { computed, action, observable, runInAction, makeObservable } from 'mobx'
import { Item } from 'src/items/aggregate/Item'
import { ItemsSelectListVM } from './ItemsSelectListVM'
import { serialize } from 'serializr'
import { RowVM } from '@elexient/elexiapp.bits.shared'

export class ItemRowVM extends RowVM<RootStore, ItemsSelectListVM, Item> {
  constructor(rootStore: RootStore, listVM: ItemsSelectListVM, record: Item) {
    super(rootStore, listVM, record, ItemRowVM)
    makeObservable(this)
  }

  @observable public adding: boolean = false
  @observable public renderVersion: number = 1

  public get item(): Item {
    return this.record
  }

  public get height() {
    if (this.isHidden) return 0
    return 45
  }

  @computed
  public get keyword(): string {
    return this.categoryName + this.name
  }

  @computed
  public get name(): string {
    if (!this.item) return ''
    return this.item.Name
  }

  @computed
  public get categoryGuid(): string {
    return this.item.CategoryGuid
  }

  @computed
  public get categoryName(): string {
    const foundCat = this.rootStore.categoriesStore.get(this.categoryGuid)
    if (!foundCat) return ''
    return foundCat.Name
  }

  @computed
  public get categoryColor(): string {
    const foundCat = this.rootStore.categoriesStore.get(this.categoryGuid)
    if (!foundCat) return ''
    return foundCat.Color
  }

  @computed
  public get itemGuid(): string {
    return this.item.ItemGuid
  }

  @computed
  public get quantity(): number {
    return this.listVM.parentVM.getItemQuantity(this.itemGuid)
  }

  @computed
  public get isGotten(): boolean {
    return this.listVM.parentVM.isGotten(this.itemGuid)
  }

  @computed
  public get hasQuantity(): boolean {
    return this.quantity !== 0
  }

  @computed
  public get showQty(): boolean {
    return this.listVM.showQty
  }

  @computed
  public get iconName(): string {
    // if (this.hasQuantity && this.isGotten && this.listVM.isPage) return 'checkmark'
    // if (this.hasQuantity && this.listVM.isPage) return 'cart'
    if (this.hasQuantity) return 'checkmark'
    // if (this.listVM.isPage) return 'add'
    return null
  }

  @action
  public toggle() {
    if (!this.hasQuantity) this.increaseQuantity()
    else this.listVM.parentVM.removeQuantity(this.itemGuid)
    runInAction(() => this.forceUpdate())
  }

  @action
  public increaseQuantity() {
    this.listVM.parentVM.increaseQuantity(this.itemGuid)
  }

  @action
  public decreaseQuantity() {
    this.listVM.parentVM.decreaseQuantity(this.itemGuid)
  }

  @action
  public click() {
    this.toggle()
  }

  @action
  public goToEditPage() {
    this.rootStore.appStore.history.push('/itemedit/' + this.itemGuid)
  }

  @computed
  public get className(): string {
    if (this.hasQuantity) return 'checked'
  }

  @computed
  public get hasNotes(): boolean {
    return this.notes && this.notes !== ''
  }

  @computed
  public get notes(): string {
    return this.item.Notes
  }

  @action
  public longPress() {
    this.listVM.openItemMenu()
  }

  @action
  private forceUpdate() {
    this.listVM.parentVM.reloadRows()
    setTimeout(() => this.renderVersion += 1, 1000)
  }

  @computed
  public get key(): string {
    return 'r' + String(this.index)  + String(this.renderVersion)
  }
}
