import React from 'react'
import { inject, observer } from 'mobx-react'
import { IonLabel, IonIcon, IonRippleEffect } from '@ionic/react'
import { add, remove, trashOutline } from 'ionicons/icons/'
import { MealItemVM } from 'src/meals/view-models/edit/MealItemVM'
import { faTasks } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Feather, MaterialCommunityIcons } from 'react-web-vector-icons'

interface Props {
  row: MealItemVM
}

const OtherItemRow: React.FC<Props> = ({ row }) => {
  const renderGottenIcon = () => {
    if (!row.isGotten) return
    if (row.iconName === 'checkmark') return
    return (
      <div
        className='icon-container checked'
        onClick={e => {
          e.stopPropagation()
          row.toggle()
        }}
      >
        <Feather name='check-circle' size={22} />
      </div>
    )
  }

  const renderCheckmarkIcon = () => {
    if (row.iconName !== 'checkmark') return
    return (
      <div
        className='icon-container checked'
        onClick={e => {
          e.stopPropagation()
          row.toggle()
        }}
      >
        <Feather name='check-circle' size={22} />
      </div>
    )
  }

  const renderLinkIcon = () => {
    if (row.iconName !== 'link') return
    return (
      <div
        className='icon-container checked'
        onClick={e => {
          e.stopPropagation()
          row.toggle()
        }}
      >
        <MaterialCommunityIcons name='link' size={18} />
      </div>
    )
  }

  const renderPlusIcon = () => {
    if (row.iconName === 'cart') return
    if (row.iconName === 'checkmark') return
    if (row.iconName === 'link') return
    if (row.isGotten) return
    return (
      <div
        className='icon-container'
        onClick={e => {
          e.stopPropagation()
          row.toggle()
        }}
      >
        <Feather name='plus-circle' size={22} />
      </div>
    )
  }

  const renderInListIcon = () => {
    if (row.iconName !== 'cart') return
    return (
      <div
        className='icon-container checked'
        onClick={e => {
          e.stopPropagation()
          row.toggle()
        }}
      >
        <Feather name='circle' size={22} />
        <FontAwesomeIcon
          onClick={e => {
            e.stopPropagation()
            row.toggle()
          }}
          icon={faTasks}
          className='cart for-checkbox'
        />
      </div>
    )
  }

  const renderCheckbox = () => {
    return (
      <>
        {renderGottenIcon()}
        {renderPlusIcon()}
        {renderCheckmarkIcon()}
        {renderInListIcon()}
        {renderLinkIcon()}
      </>
    )
  }

  const renderDeleteItemButton = () => {
    return (
      <div className='del-btn' onClick={() => row.delete()}>
        <IonIcon icon={trashOutline} size='small' />
      </div>
    )
  }

  return (
    <div id='ItemRow' style={{ borderLeftColor: row.categoryColor }} className={row.hasQuantity && !row.isCleared ? '' : 'checked'}>
      {renderCheckbox()}
      <div className='name'>{row.name}</div>
      {renderDeleteItemButton()}
    </div>
  )
}

export default inject('mealsStore')(observer(OtherItemRow))
