import { RootStore } from 'src/stores/RootStore'
import { computed, action, observable } from 'mobx'
import { ItemsSelectListVM } from './ItemsSelectListVM'
import { ItemNewVM } from '../../items/view-models/new/ItemNewVM'
import { NewRowVM } from '@elexient/elexiapp.bits.shared'

export class ItemNewRowVM extends NewRowVM<RootStore, ItemsSelectListVM> {
  @computed
  public get displayName(): string {
    if (this.listVM.filter === '') return 'New Item'
    return 'New Item: ' + this.listVM.filter
  }

  get color(): string {
    return ''
  }

  @action
  public openNewItemModal() {
    this.listVM.itemNewVM = new ItemNewVM(this.rootStore, {
      newItemTypeName: this.listVM.parentVM.type,
      backdropShown: true,
      defaultName: this.listVM.filter,
      onCompleted: (newName, itemGuid, doAdd, qty) => {
        this.listVM.setFilter(newName)
        this.listVM.reloadRows()
        if (doAdd) {
          this.listVM.parentVM.increaseQuantity(itemGuid, qty)
        }
      },
    })
    this.listVM.itemNewVM.show(this.listVM.filter)
  }
}
