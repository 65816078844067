import { RootStore } from '../../stores/RootStore'
import { computed } from 'mobx'
import { RecipeCategoryRowVM } from './RecipeCategoryRowVM'

export class RecipeCategoriesListVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @computed
  public get rows(): Array<RecipeCategoryRowVM> {
    return this.rootStore.recipeCategoriesStore.currentBoardRecords
      .sort((a, b) => (a.Name.toLowerCase() < b.Name.toLowerCase() ? -1 : 0))
      .map(e => new RecipeCategoryRowVM(this.rootStore, e))
  }
}
