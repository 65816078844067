import React from 'react'
import { IonSpinner } from '@ionic/react'
import Icon from '../assets/images/Icon_512.png'

const SplashScreen: React.FC = () => {
  return (
    <div id='SplashScreen'>
      <div id='LogoContainer'>
        <img src={Icon} alt='Grocery Board' />
        <div>
          <div id='g'>Grocery</div>
          <div id='b'>Board</div>
        </div>
      </div>
      <div id='SpinnerContainer'>
        <IonSpinner name='crescent' />
      </div>
    </div>
  )
}

export default SplashScreen
