import { observable, action, makeObservable } from 'mobx'
import generateUUID from '../../utils/UUID'
import { ICategoryDTO } from '../dtos/ICategoryDTO'
import { serialize, deserialize, serializable } from 'serializr'
import { IAggregrate } from '../../shared/IAggregate'

export class Category implements ICategoryDTO, IAggregrate {
  public static create(boardId, categoryGuid: string = null, name: string = null) {
    const cat = new Category()
    cat.CategoryGuid = categoryGuid
    if (!categoryGuid) cat.CategoryGuid = generateUUID()
    if (name) cat.Name = name
    cat.BoardId = boardId
    return cat
  }

  constructor() {
    makeObservable(this)
  }

  @serializable @observable public CategoryGuid: string = ''
  @serializable @observable public Name: string = ''
  @serializable @observable public BoardId: number = 0
  @serializable @observable public Color: string = ''
  @serializable @observable public IsDeleted: boolean = false
  public isOnServer: boolean = false

  @action
  public setName(val: string) {
    this.Name = val
  }

  @action
  public setColor(val: string) {
    this.Color = val
  }

  public markIsOnServer(): void {
    this.isOnServer = true
  }

  public markIsNotOnServer(): void {
    this.isOnServer = false
  }

  public clone(): Category {
    return deserialize(Category, serialize(this))
  }

  public toDTO(): ICategoryDTO {
    return serialize(this)
  }
}
