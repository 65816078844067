import { action, computed } from 'mobx'
import { SYSTEM_THEMES } from '../../theme-gen/aggregate/SystemThemes'
import { RootStore } from 'src/stores/RootStore'
import { ThemeRowVM } from './ThemeRowVM'

export class ThemesListVM {
  private rootStore: RootStore

  constructor(rootStore) {
    this.rootStore = rootStore
  }

  @computed
  public get selectedTheme(): string {
    return this.rootStore.appStore.theme
  }

  @computed
  public get themeOptions(): Array<ThemeRowVM> {
    return SYSTEM_THEMES.map(e => new ThemeRowVM(this.rootStore, this, e))
  }

  public setRootStore(store) {
    this.rootStore = store
  }
}
