import React from 'react'
import { inject, observer } from 'mobx-react'
import { MealsStore } from '../../store/MealsStore'
import { IonIcon, IonList, IonItem, IonPopover } from '@ionic/react'
import { trash, create, trashOutline } from 'ionicons/icons'

interface Props {
  mealsStore?: MealsStore
}

const RecipeMenu: React.FC<Props> = ({ mealsStore }) => {
  const { editVM } = mealsStore
  if (!editVM) return null

  return (
    <IonPopover
      id='RecipeMenu'
      isOpen={editVM.recipeMenuShown}
      event={editVM.recipeMenuEvent}
      onDidDismiss={() => editVM.hideRecipeMenu()}
    >
      <IonList>
        <IonItem lines='full' onClick={() => editVM.goToRecipe()}>
          <IonIcon icon={create} size='small' />
          Edit Recipe
        </IonItem>

        <IonItem lines='none' onClick={() => editVM.deleteRecipe()}>
          <IonIcon icon={trashOutline} size='small' />
          Delete
        </IonItem>
      </IonList>
    </IonPopover>
  )
}

export default inject('mealsStore')(observer(RecipeMenu))
