import React, { useState } from 'react'
import { IonButton, IonIcon, IonPopover, IonItem, IonItemDivider } from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { ListItemsStore } from 'src/list-items/store/ListItemsStore'
import { MaterialIcons } from 'react-web-vector-icons'
import { ellipsisVertical, refresh } from 'ionicons/icons'
import { EvilIcons } from 'react-web-vector-icons'
import { MealsStore } from '../../store/MealsStore'

interface Props {
  mealsStore?: MealsStore
}

const MealsListPageMoreMenu: React.FC<Props> = ({ mealsStore }) => {
  const vm = mealsStore.listVM

  return (
    <div id='MealsListPageMoreMenu'>
      <IonButton onClick={e => vm.openMenu(e)}>
        <IonIcon icon={ellipsisVertical} mode='ios' />
      </IonButton>

      <IonPopover
        id='MealsListPageMoreMenu_Content'
        isOpen={vm.menuShown}
        event={vm.event}
        onDidDismiss={() => vm.hideMenu()}
      >
        {/* <IonItem lines='full' onClick={() => vm.sync()}>
          <IonIcon icon={refresh} size={'small'} />
          &nbsp; Sync
        </IonItem> */}

        <IonItem lines='full' onClick={() => vm.goToSetup()}>
          <EvilIcons name='gear' size={18} />
          &nbsp; Meal Slots
        </IonItem>
      </IonPopover>
    </div>
  )
}

export default inject('mealsStore')(observer(MealsListPageMoreMenu))
