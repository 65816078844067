import React from 'react'
import { observer } from 'mobx-react'
import { CategoryRowVM } from '../../view-models/edit/CategoryRowVM'
import { IonItem, IonLabel, IonToggle } from '@ionic/react'

interface Props {
  row: CategoryRowVM
}

const CategoryRow: React.FC<Props> = ({ row }) => {
  return (
    <IonItem lines='inset' id='CategoryRow' onClick={() => row.toggleChecked()}>
      <IonLabel>{row.name}</IonLabel>
      <IonToggle slot='start' checked={row.isChecked} color='tertiary' />
    </IonItem>
  )
}

export default observer(CategoryRow)
