import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { CategoriesStore } from '../../store/CategoriesStore'
import { AppStore } from '../../../stores/AppStore'
import { IonList, IonItem, IonLabel, IonInput, IonButton, IonListHeader, IonDatetime, IonIcon } from '@ionic/react'
import { CirclePicker } from 'react-color'

interface Props {
  categoriesStore?: CategoriesStore
  appStore?: AppStore
}

const DetailsForm: React.FC<Props> = ({ categoriesStore, appStore }) => {
  const { editVM } = categoriesStore
  if (!editVM) return null
  if (appStore.bodyHeight === 0) return null

  return (
    <form
      id='DetailsForm'
      onSubmit={e => {
        e.preventDefault()
        editVM.save()
      }}
    >
      <IonList>
        <IonItem lines={'inset'}>
          <IonLabel position='floating'>Name</IonLabel>
          <IonInput type='text' value={editVM.name} onIonChange={(e: any) => editVM.setName(e.target.value)} />
        </IonItem>

        <IonItem lines='inset'>
          <IonLabel position='floating' className='forced-floating'>
            Color
          </IonLabel>
          <CirclePicker
            color={editVM.color}
            circleSize={20}
            width={'100%'}
            colors={[
              '#f44336',
              '#e91e63',
              '#9c27b0',
              '#673ab7',
              '#3f51b5',
              '#2196f3',
              '#03a9f4',
              '#00bcd4',
              '#009688',
              '#4caf50',
              '#8bc34a',
              '#cddc39',
              '#ffeb3b',
              '#ffc107',
              '#ff9800',
              '#ff5722',
              '#795548',
              '#607d8b',
            ]}
            onChange={e => editVM.setColor(e.hex)}
          />
        </IonItem>
      </IonList>
    </form>
  )
}

export default inject('categoriesStore', 'appStore')(observer(DetailsForm))
