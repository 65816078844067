import React from 'react'
import { inject } from 'mobx-react'
import { IonIcon } from '@ionic/react'
import { AppStore } from 'src/stores/AppStore'
import { chevronBackOutline, arrowBack } from 'ionicons/icons'

interface Props {
  appStore?: AppStore
}

const BackIcon: React.FC<Props> = ({ appStore }) => {
  return <IonIcon icon={appStore.isIos ? chevronBackOutline : arrowBack} />
}

export default inject('appStore')(BackIcon)
