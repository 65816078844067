import { observable, action, makeObservable } from 'mobx'
import { persist } from 'mobx-persist'
import generateUUID from '../../utils/UUID'

export class MealRecipe {
  public static create(recipeGuid: string): MealRecipe {
    const rcp = new MealRecipe()
    rcp.MealRecipeGuid = generateUUID()
    rcp.RecipeGuid = recipeGuid
    rcp.Rank = 1000
    return rcp
  }

  constructor() {
    makeObservable(this)
  }

  @persist @observable public MealRecipeGuid: string = ''
  @persist @observable public RecipeGuid: string = ''
  @persist @observable public Rank: number = 0
  @persist @observable public IsDeleted: boolean = false

  @action
  public setRank(val) {
    this.Rank = val
  }

  @action
  public markAsDeleted() {
    this.IsDeleted = true
  }
}
