import React from 'react'
import { observer } from 'mobx-react'
import { IonIcon, IonItem } from '@ionic/react'
import { chevronDown, chevronForward } from 'ionicons/icons'
import { ItemGroupRowVM } from '../../view-models/list/ItemGroupRowVM'

interface Props {
  row: ItemGroupRowVM
  style: any
  index: number
}

const ItemGroupRow: React.FC<Props> = ({ row, style }) => {
  const rowStyle = {
    borderLeftColor: row.color,
  }

  const renderRightIcon = () => {
    if (!row.isCollapsed) return
    return <IonIcon icon={chevronForward} />
  }

  const renderDownIcon = () => {
    if (row.isCollapsed) return
    return <IonIcon icon={chevronDown} />
  }

  return (
    <div style={style} id='ItemGroupRow' onClick={() => row.click()}>
      <div className='row' style={rowStyle}>
        <div className='name'>{row.name}</div>
        {renderRightIcon()}
        {renderDownIcon()}
      </div>
    </div>
  )
}

export default observer(ItemGroupRow)
