import React from 'react'
import {
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonText,
  IonBadge,
  IonSpinner,
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonItemDivider,
  IonTitle,
  IonIcon,
} from '@ionic/react'
import { inject, observer } from 'mobx-react'
import { AuthStore } from 'src/login/store/AuthStore'
import GoogleSmallLogo from '../../assets/images/Google_Logo_400.png'
import AppleSmallLogo from '../../assets/images/Apple_Logo_400.png'

interface Props {
  authStore?: AuthStore
}

const InputForm: React.FC<Props> = ({ authStore }) => {
  const { loginVM: vm } = authStore
  if (!vm) return

  const renderErrorMessage = () => {
    if (!vm.errorMsg) return
    return (
      <div className='error'>
        <IonBadge color='danger'>{vm.errorMsg}</IonBadge>
      </div>
    )
  }

  const renderSpinner = () => {
    if (!vm.showSpinner) return
    if (vm.isIos) return <IonSpinner />
    return <IonSpinner name='crescent' />
  }

  const renderGoogleSpinner = () => {
    if (!vm.showGoogleSpinner) return
    if (vm.isIos) return <IonSpinner color='secondary' />
    return <IonSpinner name='crescent' color='secondary' />
  }

  const renderAppleSpinner = () => {
    if (!vm.showAppleSpinner) return
    if (vm.isIos) return <IonSpinner color='secondary' />
    return <IonSpinner name='crescent' color='secondary' />
  }

  const renderGuestSpinner = () => {
    if (!vm.showGuestSpinner) return
    if (vm.isIos) return <IonSpinner color='secondary' />
    return <IonSpinner name='crescent' color='secondary' />
  }

  const renderContinueWithGoogle = () => {
    return (
      <IonButton
        className='google-button'
        expand='full'
        type='button'
        color='light'
        onClick={() => vm.continueWithGoogle()}
      >
        <img src={GoogleSmallLogo} alt='Google' />
        &nbsp; Continue with Google
        {renderGoogleSpinner()}
      </IonButton>
    )
  }

  const renderContinueWithApple = () => {
    if (!vm.canSignInWithApple) return
    return (
      <IonButton
        className='apple-button'
        expand='full'
        type='button'
        color='light'
        onClick={() => vm.continueWithApple()}
      >
        <img src={AppleSmallLogo} alt='Apple' />
        &nbsp; Continue with Apple
        {renderAppleSpinner()}
      </IonButton>
    )
  }

  const renderContinueAsGuest = () => {
    return (
      <IonButton
        className='apple-button'
        expand='full'
        type='button'
        color='light'
        onClick={() => vm.continueAsGuest()}
      >
        &nbsp; Continue as Guest
        {renderGuestSpinner()}
      </IonButton>
    )
  }

  const renderBoardInvitationInfo = () => {
    if (!vm.boardInvitation) return
    return (
      <IonCard className='board-info'>
        <IonCardContent>
          <strong>{vm.boardInvitation.FromName}</strong> has invited you to join the board &nbsp;
          <strong>{vm.boardInvitation.BoardName}</strong>
          <IonItem lines='none' onClick={() => vm.toggleAcceptBoardInvitation()}>
            <IonCheckbox checked={vm.acceptBoardInvitation} />
            &nbsp;&nbsp;Accept Invitation
          </IonItem>
        </IonCardContent>
      </IonCard>
    )
  }

  return (
    <>
      {renderBoardInvitationInfo()}

      <br />

      <IonCard>
        <IonCardContent>
          {renderContinueWithGoogle()}
          {renderContinueWithApple()}
          {renderContinueAsGuest()}

          <IonText className='or'>
            OR
          </IonText>

          <IonList>
            <IonItem>
              <IonLabel position='floating' className={vm.hasEmail ? 'forced-floating' : ''}>
                Email
              </IonLabel>
              <IonInput
                type='text'
                id='email'
                name='email'
                onKeyPress={e => {
                  if (e.charCode === 13) vm.submit()
                }}
                value={vm.email}
                onIonChange={(e: any) => vm.setEmail(e.target.value)}
              />
            </IonItem>

            <IonItem>
              <IonLabel position='floating' className={vm.hasEmail ? 'forced-floating' : ''}>
                Password
              </IonLabel>
              <IonInput
                type='password'
                id='password'
                name='password'
                value={vm.password}
                onKeyPress={e => {
                  if (e.charCode === 13) vm.submit()
                }}
                onIonChange={(e: any) => vm.setPassword(e.target.value)}
              />
            </IonItem>
          </IonList>

          <IonButton className='login-button' expand='full' type='submit' onClick={() => vm.submit()}>
            Log In
            {renderSpinner()}
          </IonButton>

          {renderErrorMessage()}

          <div className='forgot-password'>
            <IonButton expand='full' type='button' buttonType='text' onClick={() => vm.goToForgotPassword()}>
              <IonText className='forgot-password'>
                <h6>Forgot password?</h6>
              </IonText>
            </IonButton>
          </div>

          <br />

          <IonButton
            color='tertiary'
            className='register-button'
            expand='full'
            type='button'
            onClick={() => vm.goToRegister()}
          >
            Sign Up
          </IonButton>
        </IonCardContent>
      </IonCard>
    </>
  )
}

export default inject('authStore')(observer(InputForm))
