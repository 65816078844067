import agent from '../../Agent'
import { RootStore } from '../../stores/RootStore'
import { IRecipeDTO } from '../dto/IRecipeDTO'
import { ActionTypeEnum } from '../../actions/enum/ActionTypeEnum'
import { IRecipeItemDTO } from '../dto/IRecipeItemDTO'
import { IRecipeJobOptions } from '../interfaces/IRecipeItemParseJobOptions'
import { IRecipeJobResult } from '../interfaces/IRecipeItemJobResult'

export class RecipesService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public save(dto: IRecipeDTO) {
    this.rootStore.recipesStore.updateFromServer(dto)
    this.rootStore.actionsStore.addAction(ActionTypeEnum.RECIPE_SAVE, dto)
  }

  public async saveOnServer(dto: IRecipeDTO) {
    const savedDTO = await agent.Recipes.save(dto)
    this.rootStore.recipesStore.updateFromServer(savedDTO)
  }

  public async parseRecipeItem(dto: IRecipeItemDTO): Promise<IRecipeJobResult> {
    const opts: IRecipeJobOptions = {
      Description: dto.Description,
      RecipeItemGuid: dto.RecipeItemGuid,
      BoardId: this.rootStore.boardsStore.currentBoardId
    }
    return await agent.Recipes.parseRecipeItem(opts)
  }

  public delete(dto: IRecipeDTO) {
    dto.IsDeleted = true
    this.save(dto)
    this.rootStore.mealsStore.currentBoardRecords.forEach(e => {
      const hasRcp = e.MealRecipes.some(ee => ee.RecipeGuid === dto.RecipeGuid)
      if (hasRcp) e.deleteRecipe(dto.RecipeGuid)
    })
  }
}
