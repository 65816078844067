import React from 'react'
import { inject, observer } from 'mobx-react'
import { RecipesStore } from '../../store/RecipesStore'
import { IonLabel, IonSegment, IonSegmentButton, IonBadge, IonIcon } from '@ionic/react'
import { AppStore } from 'src/stores/AppStore'
import { list, informationCircle, compass, pricetags } from 'ionicons/icons'

interface Props {
  recipesStore?: RecipesStore
  appStore?: AppStore
}

const StepsList: React.FC<Props> = ({ recipesStore, appStore }) => {
  const { editVM } = recipesStore
  if (!editVM) return null

  return (
    <div id='TabButtons'>
      <IonSegment
        value={editVM.currentTabIndex}
        scrollable
        onIonChange={e => editVM.setCurrentTab(e.detail.value)}
        color={appStore.isIos ? null : 'tertiary'}
      >
        <IonSegmentButton value='0' className='info' layout='icon-top'>
          <IonIcon icon={informationCircle} />
          <IonLabel>Info</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value='1' layout='icon-top'>
          <IonIcon icon={list} />
          <IonLabel>Ingredients</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value='2' layout='icon-top'>
          <IonIcon icon={compass} />
          <IonLabel>Directions</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value='3' layout='icon-top'>
          <IonIcon icon={pricetags} />
          <IonLabel>Categories</IonLabel>
        </IonSegmentButton>
      </IonSegment>
    </div>
  )
}

export default inject('recipesStore', 'appStore')(observer(StepsList))
