import agent from '../../Agent'
import { RootStore } from '../../stores/RootStore'
import { IMealCategoryDTO } from '../dtos/IMealCategoryDTO'
import { ActionTypeEnum } from '../../actions/enum/ActionTypeEnum'

export class MealCategoriesService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public save(dto: IMealCategoryDTO) {
    this.rootStore.mealCategoriesStore.updateFromServer(dto)
    this.rootStore.actionsStore.addAction(ActionTypeEnum.MEALCATEGORY_SAVE, dto)
  }

  public async saveOnServer(dto: IMealCategoryDTO) {
    const savedDTO = await agent.MealCategories.save(dto)
    this.rootStore.mealCategoriesStore.updateFromServer(savedDTO)
  }

  public delete(dto: IMealCategoryDTO) {
    dto.IsDeleted = true
    this.save(dto)
  }
}
