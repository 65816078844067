import React from 'react'
import { inject, observer } from 'mobx-react'
import { MealCategoriesStore } from '../../store/MealCategoriesStore'
import { AppStore } from '../../../stores/AppStore'
import { IonItem, IonLabel, IonInput, IonIcon, IonRow, IonGrid, IonCol, IonButton } from '@ionic/react'
import DaysGrid from './DaysGrid'
import DefaultTimeRow from './DefaultTimeRow'
import { closeCircle } from 'ionicons/icons'
import { CirclePicker } from 'react-color'
import { TimePicker } from '@material-ui/pickers'
import VisibleRow from './VisibleRow'

interface Props {
  mealCategoriesStore?: MealCategoriesStore
  appStore?: AppStore
}

const DetailsForm: React.FC<Props> = ({ mealCategoriesStore, appStore }) => {
  const { editVM } = mealCategoriesStore
  // if (!editVM) return null
  // if (appStore.bodyHeight === 0) return null

  const renderName = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Name</IonLabel>
        <IonInput
          type='text'
          value={editVM.name}
          onIonChange={(e: any) => {
            editVM.setName(e.target.value)
          }}
        />
      </IonItem>
    )
  }

  return (
    <form
      id='DetailsForm'
      onSubmit={e => {
        e.preventDefault()
        editVM.save()
      }}
    >
      <IonGrid>
        <IonRow>
          <IonCol sizeXs='12'>{renderName()}</IonCol>
        </IonRow>

        <IonItem lines='inset'>
          <IonLabel position='floating' className='forced-floating'>
            Color
          </IonLabel>
          <CirclePicker
            color={editVM.color}
            circleSize={20}
            width={'100%'}
            colors={[
              '#f44336',
              '#e91e63',
              '#9c27b0',
              '#673ab7',
              '#3f51b5',
              '#2196f3',
              '#03a9f4',
              '#00bcd4',
              '#009688',
              '#4caf50',
              '#8bc34a',
              '#cddc39',
              '#ffeb3b',
              '#ffc107',
              '#ff9800',
              '#ff5722',
              '#795548',
              '#607d8b',
            ]}
            onChange={e => editVM.setColor(e.hex)}
          />
        </IonItem>
      </IonGrid>

      <VisibleRow editVM={editVM} />
      <DefaultTimeRow editVM={editVM} />

      <DaysGrid />
    </form>
  )
}

export default inject('mealCategoriesStore', 'appStore')(observer(DetailsForm))
