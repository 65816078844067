import React from 'react'
import { inject, observer } from 'mobx-react'
import { RecipesStore } from '../../store/RecipesStore'
import { IonIcon, IonItem, IonLabel, IonTextarea, IonButton } from '@ionic/react'
import { add } from 'ionicons/icons'
import StepRow from './StepRow'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

interface Props {
  recipesStore?: RecipesStore
}

const StepsList: React.FC<Props> = ({ recipesStore }) => {
  const { editVM } = recipesStore
  if (!editVM) return null
  if (editVM.currentTabIndex !== '2') return null

  const renderNoSteps = () => {
    if (editVM.steps.length !== 0) return
    return (
      <IonItem className='no-steps'>
        <IonLabel>No Steps</IonLabel>
      </IonItem>
    )
  }

  const renderNewStepRow = () => {
    return (
      <IonItem onClick={() => editVM.addStep()} className='new-step-row'>
        <IonIcon color='tertiary' icon={add} />
        &nbsp;
        <IonLabel>Add</IonLabel>
      </IonItem>
    )
  }

  const SortableItem = SortableElement(({ e, index }) => {
    return <StepRow row={e} key={'st' + e.key} />
  })

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div>
        {items.map((e, index) => (
          <SortableItem disabled={false} key={'ric' + e.key} index={index} e={e} />
        ))}
      </div>
    )
  })

  return (
    <div id='StepsList'>
      {renderNoSteps()}
      {/* <div class='name'>
        <ion-textarea
          class='description-input sc-ion-textarea-ios-h sc-ion-textarea-ios-s ios hydrated'
          placeholder='Type or paste steps'
          value='jkljkljlk'
        >
          <div class='textarea-wrapper sc-ion-textarea-ios' style='height: 16px;'>
            <textarea
              class='native-textarea sc-ion-textarea-ios'
              autocapitalize='none'
              name='ion-input-1'
              placeholder='Type or paste steps'
              rows='1'
              style='height: 16px;'
            >
              jkljkljlk
            </textarea>
          </div>
          <div class='textarea-wrapper sc-ion-textarea-ios'>
            <textarea
              class='native-textarea sc-ion-textarea-ios'
              autocapitalize='none'
              name='ion-input-3'
              placeholder='Type or paste steps'
            >
              jkljkljlk
            </textarea>
          </div>
        </ion-textarea>
      </div>
      <div class='move-btn'>
        <ion-icon
          icon="data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><path stroke-linecap='round' stroke-linejoin='round' stroke-width='44' d='M118 304h276M118 208h276' class='ionicon-fill-none'/></svg>"
          size='small'
          role='img'
          class='ios icon-small hydrated'
        />
      </div>
      <div class='del-btn'>
        <ion-icon
          icon="data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' class='ionicon' viewBox='0 0 512 512'><path d='M112 112l20 320c.95 18.49 14.4 32 32 32h184c17.67 0 30.87-13.51 32-32l20-320' stroke-linecap='round' stroke-linejoin='round' class='ionicon-fill-none ionicon-stroke-width'/><path stroke-linecap='round' stroke-miterlimit='10' d='M80 112h352' class='ionicon-stroke-width'/><path d='M192 112V72h0a23.93 23.93 0 0124-24h80a23.93 23.93 0 0124 24h0v40M256 176v224M184 176l8 224M328 176l-8 224' stroke-linecap='round' stroke-linejoin='round' class='ionicon-fill-none ionicon-stroke-width'/></svg>"
          size='small'
          role='img'
          class='ios icon-small hydrated'
        />
      </div> */}

      <SortableList
        hideSortableGhost
        useDragHandle
        helperClass='dragging'
        items={editVM.steps}
        onSortStart={(e, e1) => {
          // console.log(e)
          // console.log(e1)
          setTimeout(() => {
            // console.log(document.getElementsByClassName('dragging')[0].innerHTML)
          }, 1000)
        }}
        onSortEnd={({ oldIndex, newIndex }) => editVM.reorderSteps(oldIndex, newIndex)}
      />
      {renderNewStepRow()}
    </div>
  )
}

export default inject('recipesStore')(observer(StepsList))
