import React from 'react'
import { IonButton, IonHeader, IonToolbar, IonTitle, IonButtons } from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { AppStore } from 'src/stores/AppStore'
import { RootStore } from 'src/stores/RootStore'
import BackIcon from 'src/shared/BackIcon'
import SettingsPageContent from './SettingsPageContent'

interface Props {
  appStore: AppStore
  rootStore: RootStore
  history: any
}

@inject('appStore')
@inject('rootStore')
@observer
export default class SettingsPage extends React.Component<Props> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.appStore.setCurrentRoute('/settings')
  }

  handleGoBack() {
    this.props.appStore.handleGoBack(this.props.history)
  }

  render() {
    const renderBackButton = () => {
      if (this.props.appStore.isSplitPaneVisible) return
      return (
        <IonButtons slot='start'>
          <IonButton onClick={() => this.handleGoBack()}>
            <BackIcon />
          </IonButton>
        </IonButtons>
      )
    }

    return (
      <>
        {this.props.appStore.showAppHeader && (
          <IonHeader>
            <IonToolbar />
          </IonHeader>
        )}

        <IonHeader id='SettingsPage_Header'>
          <IonToolbar color='secondary'>
            {renderBackButton()}
            <IonTitle>Settings</IonTitle>
          </IonToolbar>
        </IonHeader>

        <SettingsPageContent />
      </>
    )
  }
}
