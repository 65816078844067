import { ActionsStore } from '../store/ActionsStore'
import { Action } from '../aggregate/Action'
import { ActionTypeEnum } from '../enum/ActionTypeEnum'
import { RootStore } from '../../stores/RootStore'
import { ItemsService } from '../../items/services/ItemsService'
import { ListItemsService } from '../../list-items/services/ListItemsService'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import * as Sentry from '@sentry/browser'
import { CategoriesService } from '../../categories/services/CategoriesService'
import { RecipeCategoriesService } from '../../recipe-categories/services/RecipeCategoriesService'
import { MealCategoriesService } from '../../meal-categories/services/MealCategoriesService'
import { RecipesService } from '../../recipes/services/RecipesService'
import { MealsService } from '../../meals/services/MealsService'
import { BoardsService } from '../../boards/service/BoardsService'
import { BoardInvitationsService } from '../../board-invitations/service/BoardInvitationsService'

export class ActionsService {
  private actionsStore: ActionsStore
  private rootStore: RootStore

  constructor(rootStore: RootStore, actionsStore: ActionsStore) {
    makeObservable(this)
    this.rootStore = rootStore
    this.actionsStore = actionsStore
  }

  @observable public isProcessing: boolean = false

  @computed
  private get actions(): Array<Action> {
    return this.actionsStore.actions
  }

  @action
  public async processActions() {
    if (window && window.Offline && window.Offline.state === 'down') {
      console.log('leaving bc internet is down')
      return
    }
    if (this.actions.length === 0) {
      // console.log('leaving bc no actions')
      return
    }
    if (this.isProcessing) {
      console.log('leaving bc processing')
      return
    }
    this.isProcessing = true
    const nextAction = this.actions[0]
    if (nextAction.attempts > 5) {
      this.actions.shift()
      this.isProcessing = false
      return
    }
    console.log('Processing action: ' + nextAction.typeName + '(' + nextAction.type + ') dto:' + nextAction.dtoString)
    let success = false
    try {
      if (nextAction.type === ActionTypeEnum.ITEM_SAVE) await this.processItemSave(nextAction)
      if (nextAction.type === ActionTypeEnum.LISTITEM_SAVE) await this.processListItemSave(nextAction)
      if (nextAction.type === ActionTypeEnum.CATEGORY_SAVE) await this.processCategorySave(nextAction)
      if (nextAction.type === ActionTypeEnum.RECIPECATEGORY_SAVE) await this.processRecipeCategorySave(nextAction)
      if (nextAction.type === ActionTypeEnum.MEALCATEGORY_SAVE) await this.processMealCategorySave(nextAction)
      if (nextAction.type === ActionTypeEnum.RECIPE_SAVE) await this.processRecipeSave(nextAction)
      if (nextAction.type === ActionTypeEnum.MEAL_SAVE) await this.processMealSave(nextAction)
      if (nextAction.type === ActionTypeEnum.BOARD_SAVE) await this.processBoardSave(nextAction)
      if (nextAction.type === ActionTypeEnum.BOARDINVITATION_SAVE) await this.processBoardInvitationSave(nextAction)
      success = true
    } catch (e) {
      this.isProcessing = false
      console.error(e)
      Sentry.captureException(e)
    }
    if (success) {
      runInAction(() => {
        this.actions.shift()
        this.isProcessing = false
      })
      console.log('calling next action')
      if (process.env.NODE_ENV === 'test') {
        await this.processActions()
        return
      }
      setTimeout(async () => await this.processActions(), 100)
    } else {
      runInAction(() => {
        nextAction.attempts += 1
        this.isProcessing = false
      })
    }
  }

  private async processItemSave(nextAction: Action) {
    console.log(nextAction)
    const svc = new ItemsService(this.rootStore)
    await svc.saveOnServer(nextAction.dto)
  }

  private async processListItemSave(nextAction: Action) {
    const svc = new ListItemsService(this.rootStore)
    await svc.saveOnServer(nextAction.dto)
  }

  private async processCategorySave(nextAction: Action) {
    const svc = new CategoriesService(this.rootStore)
    await svc.saveOnServer(nextAction.dto)
  }

  private async processRecipeCategorySave(nextAction: Action) {
    const svc = new RecipeCategoriesService(this.rootStore)
    await svc.saveOnServer(nextAction.dto)
  }

  private async processMealCategorySave(nextAction: Action) {
    const svc = new MealCategoriesService(this.rootStore)
    await svc.saveOnServer(nextAction.dto)
  }

  private async processRecipeSave(nextAction: Action) {
    const svc = new RecipesService(this.rootStore)
    await svc.saveOnServer(nextAction.dto)
  }

  private async processMealSave(nextAction: Action) {
    const svc = new MealsService(this.rootStore)
    await svc.saveOnServer(nextAction.dto)
  }

  private async processBoardSave(nextAction: Action) {
    const svc = new BoardsService(this.rootStore)
    await svc.saveOnServer(nextAction.dto)
  }

  private async processBoardInvitationSave(nextAction: Action) {
    const svc = new BoardInvitationsService(this.rootStore)
    await svc.saveOnServer(nextAction.dto)
  }
}
