import React from 'react'
import { IonContent, IonItem, IonIcon, IonButton, IonHeader, IonToolbar, IonTitle, IonButtons } from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { AppStore } from 'src/stores/AppStore'
import { CategoriesStore } from 'src/categories/store/CategoriesStore'
import { Category } from 'src/categories/aggregate/Category'
import { trash, add, arrowBack, trashOutline } from 'ionicons/icons'
import { RootStore } from 'src/stores/RootStore'
import CategoriesList from './CategoriesList'
import BackIcon from 'src/shared/BackIcon'

interface Props {
  categoriesStore: CategoriesStore
  appStore: AppStore
  rootStore: RootStore
  history: any
}

interface State {
  isNewModalOpen: boolean
  isEditModalOpen: boolean
  editCategoryGuid: string
  isDeleteConfirmShown: boolean
  deleteCategoryGuid: string
  deleteCategoryName: string
}

@inject('categoriesStore')
@inject('appStore')
@observer
export default class CategoriesListPage extends React.Component<Props, State> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.appStore.setCurrentRoute('/categorieslist')
  }

  handleAddButtonClick() {
    this.setState({ isNewModalOpen: true })
  }

  handleEditClick(categoryGuid) {
    this.setState({
      isEditModalOpen: true,
      editCategoryGuid: categoryGuid,
    })
  }

  handleGoBack() {
    this.props.appStore.handleGoBack(this.props.history)
  }

  openListItem(listItem) {
    this.props.history.push('/maintabs/listitemedit/' + listItem.ListItemGuid)
  }

  renderNoItemsMessage() {
    if (this.props.categoriesStore.currentBoardRecords.length > 1) return null // uncategorized is static
    return (
      <div className='no-items'>
        You have no categories set up!
        <br />
        Add some categories to start your Grocery Board journey...
        <br />
        <br />
        <IonButton slot='icon-only' type='button' onClick={() => this.props.appStore.navigateTo('/categoryedit/new')}>
          <IonIcon icon={add} />
        </IonButton>
      </div>
    )
  }

  render() {
    return (
      <>
        {this.props.appStore.showAppHeader && (
          <IonHeader>
            <IonToolbar />
          </IonHeader>
        )}

        <IonHeader id='CategoriesListPage_Header'>
          <IonToolbar color='secondary'>
            <IonButtons slot='start'>
              <IonButton onClick={() => this.handleGoBack()}>
                <BackIcon />
              </IonButton>
            </IonButtons>

            <IonTitle>Categories</IonTitle>

            <IonButtons slot='end'>
              <IonButton onClick={() => this.props.appStore.navigateTo('/categoryedit/new')}>
                <IonIcon icon={add} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent
          id='CategoriesListPage_Content'
          scrollY
          ref={e => this.props.appStore.applyScrollStyles(e)}
          style={{ height: this.props.appStore.listHeightPx }}
        >
          {this.renderNoItemsMessage()}
          <CategoriesList />
        </IonContent>
      </>
    )
  }
}
