import React from 'react'
import { IonToast } from '@ionic/react'
import { observer } from 'mobx-react'

interface Props {
  shown: boolean
  onDidDismiss: () => any
}

const OfflineToast: React.FC<Props> = ({ shown, onDidDismiss }) => {
  if (!shown) return null
  return (
    <IonToast
      isOpen={shown}
      onDidDismiss={() => onDidDismiss()}
      message='Offline. Please try again later.'
      duration={1500}
    />
  )
}
export default observer(OfflineToast)
