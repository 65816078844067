import React from 'react'
import { inject, observer } from 'mobx-react'
import {
  IonList,
  IonLabel,
  IonItem,
  IonInput,
  IonCheckbox,
  IonIcon,
  IonRippleEffect,
  IonSelect,
  IonSelectOption,
  IonText,
} from '@ionic/react'
import { ItemsStore } from '../../store/ItemsStore'
import { add, cart, checkmark, remove } from 'ionicons/icons/'
import { faTasks } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  itemsStore?: ItemsStore
}

const DetailsForm: React.FC<Props> = ({ itemsStore }) => {
  const { editVM } = itemsStore
  if (!editVM) return null
  if (editVM.currentTabIndex !== '0') return null

  const renderName = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Name</IonLabel>
        <IonInput
          type='text'
          name='jsaklfhdjkl'
          value={editVM.name}
          autocomplete='off'
          onIonChange={(e: any) => editVM.setName(e.target.value)}
          onKeyPress={(e) => e.charCode === 13 && editVM.isValid && editVM.save()}
          ref={(e) => editVM.disableAutoComplete(e)}
        />
      </IonItem>
    )
  }

  const renderCategory = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Category</IonLabel>
        <IonSelect
          interface='popover'
          value={editVM.categoryGuid}
          onIonChange={(e: any) => editVM.setCategory(e.target.value)}
        >
          {editVM.categories.map((e) => (
            <IonSelectOption
              key={e.CategoryGuid}
              value={e.CategoryGuid}
              // selected={e.CategoryGuid === editVM.categoryGuid}
            >
              {e.Name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    )
  }

  const renderInListIcon = () => {
    if (editVM.iconName !== 'cart') return
    return (
      <FontAwesomeIcon
        onClick={(e) => {
          e.stopPropagation()
          editVM.toggle()
        }}
        icon={faTasks}
        className='cart for-checkbox'
      />
    )
  }

  const renderIcon = () => {
    if (editVM.iconName === 'cart') return
    return (
      <IonIcon
        key={editVM.itemGuid + '_itc_' + editVM.hasQuantity.toString()}
        onClick={(e) => {
          e.stopPropagation()
          editVM.toggle()
        }}
        icon={
          editVM.iconName === 'cart'
            ? cart
            : editVM.iconName === 'add'
            ? add
            : editVM.iconName === 'checkmark'
            ? checkmark
            : null
        }
        className={editVM.iconName + ' for-checkbox'}
      />
    )
  }

  const renderQtySection = () => {
    if (!editVM.hasQuantity) return
    return (
      <>
        <div
          className='ion-activatable qty-button minus-button'
          hidden={!editVM.hasQuantity}
          onClick={(e) => {
            e.stopPropagation()
            editVM.decreaseQuantity()
          }}
        >
          <IonIcon icon={remove} color='tertiary' />
          <IonRippleEffect type='unbounded' />
        </div>
        <div className='qty' hidden={!editVM.hasQuantity}>
          <IonLabel>{editVM.quantity}</IonLabel>
        </div>
        <div
          className='ion-activatable qty-button plus-button'
          onClick={(e) => {
            e.stopPropagation()
            editVM.increaseQuantity()
          }}
        >
          <IonIcon icon={add} color='tertiary' />
          <IonRippleEffect type='unbounded' />
        </div>
      </>
    )
  }

  const renderAddToList = () => {
    return (
      <div
        id='ItemRow'
        style={{ borderLeftColor: editVM.categoryColor }}
        className={editVM.hasQuantity ? 'checked' : ''}
        onClick={() => editVM.toggle()}
      >
        <IonCheckbox mode='ios' checked={editVM.hasQuantity} color='light' />
        {renderInListIcon()}
        {renderIcon()}
        <div className='name'>{editVM.shoppingListInfo}</div>
        <div className='qty-section'>{renderQtySection()}</div>
      </div>
    )
  }

  const renderNotes = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Description</IonLabel>
        <IonInput
          type='text'
          value={editVM.notes}
          autocomplete='off'
          name='djsaklfn'
          maxlength={500}
          onIonChange={(e: any) => editVM.setNotes(e.target.value)}
          onKeyPress={(e) => e.charCode === 13 && editVM.isValid && editVM.save()}
          ref={(e) => editVM.disableAutoComplete(e)}
        />
      </IonItem>
    )
  }

  const renderShoppingListNotes = () => {
    if (!editVM.hasQuantity) return null
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Shopping List Notes</IonLabel>
        <IonInput
          type='text'
          autocomplete='off'
          value={editVM.listItemNotes}
          maxlength={500}
          onIonChange={(e: any) => editVM.setListItemNotes(e.target.value)}
          onKeyPress={(e) => e.charCode === 13 && editVM.isValid && editVM.save()}
          ref={(e) => editVM.disableAutoComplete(e)}
        />
      </IonItem>
    )
  }

  const renderFutureItemRows = () => {
    return editVM.futureItems.map((e, idx) => (
      <IonText className='future-item' key={idx + e.fullDescription}>
        {e.fullDescription}
      </IonText>
    ))
  }

  const renderNoFutureItems = () => {
    if (editVM.futureItems.length !== 0) return
    if (editVM.isNewItem) return
    return (
      <IonText>
        Nothing planned with <strong>{editVM.name}</strong>
      </IonText>
    )
  }

  const renderFutureItems = () => {
    return (
      <IonItem lines={'inset'} className='future-section'>
        <IonLabel position='floating' className='forced-floating'>
          Upcoming Needs
        </IonLabel>
        {renderFutureItemRows()}
        {renderNoFutureItems()}
      </IonItem>
    )
  }

  return (
    <div id='DetailsForm'>
      <form autoComplete='off'>
        <IonList>
          {renderName()}
          {renderCategory()}
          {renderNotes()}
          {renderAddToList()}
          {renderShoppingListNotes()}
          {renderFutureItems()}
        </IonList>
      </form>
    </div>
  )
}

export default inject('itemsStore')(observer(DetailsForm))
