import agent from '../../Agent'
import { RootStore } from '../../stores/RootStore'
import { ICategoryDTO } from '../dtos/ICategoryDTO'
import { ActionTypeEnum } from '../../actions/enum/ActionTypeEnum'
import { action } from 'mobx'

export class CategoriesService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public save(dto: ICategoryDTO) {
    this.rootStore.categoriesStore.updateFromServer(dto)
    this.rootStore.actionsStore.addAction(ActionTypeEnum.CATEGORY_SAVE, dto)
  }

  public async saveOnServer(dto: ICategoryDTO) {
    const savedDTO = await agent.Categories.save(dto)
    this.rootStore.categoriesStore.updateFromServer(savedDTO)
  }

  @action
  public delete(dto: ICategoryDTO) {
    dto.IsDeleted = true
    this.rootStore.itemsStore.currentBoardRecords
      .filter((e) => e.CategoryGuid === dto.CategoryGuid)
      .forEach((e) => e.setCategory('00000000-0000-0000-0000-000000000000'))
    this.rootStore.listItemsStore.currentBoardRecords
      .filter((e) => e.Item.CategoryGuid === dto.CategoryGuid)
      .forEach((e) => e.Item.clearCategory())
    this.rootStore.itemsStore.listVM.reloadRows()
    this.rootStore.listItemsStore.listVM.reloadRows()
    this.save(dto)
  }
}
