import agent from '../../Agent'
import { RootStore } from '../../stores/RootStore'
import { IItemDTO } from '../dtos/IItemDTO'
import { ListItemsService } from '../../list-items/services/ListItemsService'
import { ActionTypeEnum } from '../../actions/enum/ActionTypeEnum'
import { action } from 'mobx'

export class ItemsService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @action
  public saveItem(dto: IItemDTO) {
    this.rootStore.itemsStore.updateFromServer(dto)
    this.rootStore.actionsStore.addAction(ActionTypeEnum.ITEM_SAVE, dto)
  }

  @action
  public async saveOnServer(dto: IItemDTO) {
    const savedDTO = await agent.Items.save(dto)
    this.rootStore.itemsStore.updateFromServer(savedDTO)
  }

  public deleteItem(dto: IItemDTO) {
    dto.IsDeleted = true
    this.saveItem(dto)
    const listItemsSvc = new ListItemsService(this.rootStore)
    this.rootStore.listItemsStore.currentBoardRecords
      .filter(e => e.ItemGuid === dto.ItemGuid)
      .forEach(e => listItemsSvc.delete(e.toDTO()))
  }
}
