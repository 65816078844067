import React from 'react'
import { observer } from 'mobx-react'
import {
  IonItem,
  IonCard,
  IonCardTitle,
  IonCardContent,
  IonCol,
  IonCardHeader,
  IonChip,
  IonIcon,
  IonText,
  IonLabel,
  IonBadge,
} from '@ionic/react'
import { DayGroupVM } from '../../view-models/list/DayGroupVM'
import { add, checkmark } from 'ionicons/icons'
import MealSelectMenu from './MealSelectMenu'
import { MealCategoryRowVM } from '../../view-models/list/MealCategoryRowVM'

interface Props {
  row: DayGroupVM
}

const DayGroup: React.FC<Props> = ({ row }) => {
  const renderMeals = () => {
    return <div id='meals-container'>{renderMealCategories()}</div>
  }

  const renderMealCategories = () => {
    return row.availableMealCategories.map(cat => (
      <React.Fragment key={'row' + cat.mealCategoryGuid}>
        {renderPlannedMealsInCategory(cat)}
        {renderUnplannedMeal(cat)}
      </React.Fragment>
    ))
  }

  const renderPlannedMealsInCategory = (cat: MealCategoryRowVM) => {
    return cat.meals.map(e => (
      <div
        key={e.key}
        style={{
          borderLeftColor: e.mealCategoryColor,
          borderLeftWidth: '2px',
        }}
        className='meal'
        onClick={() => e.openMeal()}
      >
        <IonText>{e.description}</IonText>
        <IonText className='meal-category'>
          {e.mealCategoryTime}&nbsp;{e.mealCategoryName}
        </IonText>
      </div>
    ))
  }

  const renderUnplannedMeal = (cat: MealCategoryRowVM) => {
    if (cat.meals.length !== 0) return null
    return (
      <div className='unplanned-meal' key={cat.mealCategoryGuid} onClick={() => row.addMeal(cat)}>
        <IonText className='meal-category'>{cat.name}</IonText>
        <IonIcon icon={add} />
      </div>
    )
  }

  return (
    <IonCard id='DayGroup' className={row.isToday ? 'today' : row.isPrior ? 'prior' : ''}>
      <IonCardHeader color='board'>
        <IonCardTitle className='title-row'>
          <div className='day'>{row.dayOfWeek}</div>
          <div className='date'>{row.date}</div>
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent color='board'>
        {renderMeals()}
        <MealSelectMenu row={row} />
      </IonCardContent>
    </IonCard>
  )
}

export default observer(DayGroup)
