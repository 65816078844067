import { observable, action, reaction, computed, makeObservable } from 'mobx'
import { AppleSignInResponse } from '../interfaces/AppleSignInResponse'
import { IStore } from '@elexient/elexiapp.bits.shared'
import { RootStore } from '../../stores/RootStore'
import { LoginVM } from '../view-models/LoginVM'
import { RegisterVM } from '../../register/view-models/RegisterVM'
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'

export class AuthStore implements IStore {
  private rootStore: RootStore

  constructor(rootStore) {
    makeObservable(this)
    this.rootStore = rootStore
    this.loginVM = new LoginVM(this.rootStore)
  }

  @observable public loginVM: LoginVM = null
  @observable public registerVM: RegisterVM = null
  @observable public googleLoginInfo: any = null
  @observable public appleLoginInfo: AppleSignInResponse = null
  @observable public appleLoginInfoUserName: string = ''

  @action
  public loadRegistrationVM() {
    this.registerVM = new RegisterVM(this.rootStore)
  }

  @action
  public setGoogleLoginInfo(info: any) {
    this.googleLoginInfo = info
  }

  @action
  public setAppleLoginInfo(info: AppleSignInResponse) {
    this.appleLoginInfo = info
    if (!info) return
    this.appleLoginInfoUserName = info.givenName + ' ' + info.familyName
  }

  @action
  public logout() {
    if (!this.rootStore.appStore.isHydrated) {
      setTimeout(() => this.logout(), 100)
      return
    }
    this.rootStore.clearData()
    this.rootStore.appStore.themeGenVM.loadTheme('default')
    setTimeout(async () => await GoogleAuth.signOut(), 2000)
  }

  public clearData() {}
}
