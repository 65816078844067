import { MealsListVM } from './MealsListVM'
import { RootStore } from 'src/stores/RootStore'
import { computed, makeObservable, observable } from 'mobx'
import { MealChipVM } from './MealChipVM'
import { Moment } from 'moment'
import { MealCategory } from 'src/meal-categories/aggregate/MealCategory'

export class MealCategoryRowVM {
  private rootStore: RootStore
  private listVM: MealsListVM

  constructor(rootStore: RootStore, listVM: MealsListVM, mealCategoryGuid: string, meals: Array<MealChipVM>) {
    this.rootStore = rootStore
    this.mealCategoryGuid = mealCategoryGuid
    this.meals = meals
    this.listVM = listVM
    makeObservable(this)
  }

  public type: string = 'mealcategory'
  public mealCategoryGuid: string
  @observable public meals: MealChipVM[]

  @computed
  public get name(): string {
    if (!this.rootStore.mealCategoriesStore) return ''
    const foundCat = this.rootStore.mealCategoriesStore.get(this.mealCategoryGuid)
    if (foundCat) return foundCat.Name
    return ''
  }

  @computed
  public get visibleForThisUser(): boolean {
    if (!this.rootStore.mealCategoriesStore) return false
    if (!this.rootStore.userStore) return false
    if (!this.rootStore.userStore.user) return false
    if (!this.mealCategory) return false
    const foundVU = this.mealCategory.VisibleUsers.find(e => e.AppUserId === this.rootStore.userStore.user.AppUserId)
    if (!foundVU) return false
    return foundVU.Enabled
  }

  @computed
  public get mealCategory(): MealCategory {
    return this.rootStore.mealCategoriesStore.get(this.mealCategoryGuid)
  }

  public availableForThisWeekDay(checkDate: Moment): boolean {
    const weekdayIndex = checkDate.isoWeekday()
    const hasDay = this.mealCategory.Days.find(e => e.DayIndex === weekdayIndex && e.Enabled)
    if (!hasDay) return false
    return true
  }

  @computed
  public get rank(): number {
    if (!this.rootStore.mealCategoriesStore) return 0
    const foundCat = this.rootStore.mealCategoriesStore.get(this.mealCategoryGuid)
    if (foundCat) return foundCat.Rank
    return 0
  }
}
