import React from 'react'
import { IonContent, IonIcon, IonButton, IonHeader, IonToolbar, IonTitle, IonButtons } from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { AppStore } from 'src/stores/AppStore'
import { add, arrowBack } from 'ionicons/icons'
import { RootStore } from 'src/stores/RootStore'
import { RecipeCategoriesStore } from '../store/RecipeCategoriesStore'
import RecipeCategoriesList from './RecipeCategoriesList'
import BackIcon from 'src/shared/BackIcon'

interface Props {
  recipeCategoriesStore: RecipeCategoriesStore
  appStore: AppStore
  rootStore: RootStore
  history: any
}

@inject('recipeCategoriesStore')
@inject('appStore')
@inject('rootStore')
@observer
export default class RecipeCategoriesListPage extends React.Component<Props> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.appStore.setCurrentRoute('/recipecategorieslist')
  }

  handleAddButtonClick() {
    this.props.history.push('/recipecategoryedit/new')
  }

  handleGoBack() {
    this.props.appStore.handleGoBack(this.props.history)
  }

  renderNoCategoriesMessage() {
    const { listVM } = this.props.recipeCategoriesStore
    if (!listVM) return null
    if (listVM.rows.length > 0) return null

    return (
      <div className='no-items'>
        Add some recipe categories (chicken, dessert, etc)...
        <br />
        <br />
        <IonButton slot='icon-only' color='tertiary' type='button' onClick={() => this.handleAddButtonClick()}>
          <IonIcon icon={add} />
        </IonButton>
      </div>
    )
  }

  render() {
    return (
      <>
        {this.props.appStore.showAppHeader && (
          <IonHeader>
            <IonToolbar />
          </IonHeader>
        )}

        <IonHeader id='RecipeCategoriesListPage_Header'>
          <IonToolbar color='secondary'>
            <IonButtons slot='start'>
              <IonButton onClick={() => this.handleGoBack()}>
                <BackIcon />
              </IonButton>
            </IonButtons>

            <IonTitle>Recipe Categories</IonTitle>

            <IonButtons slot='end'>
              <IonButton onClick={this.handleAddButtonClick.bind(this)}>
                <IonIcon icon={add} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent
          id='RecipeCategoriesListPage_Content'
          ref={e => this.props.appStore.applyScrollStyles(e)}
          style={{ height: this.props.appStore.listHeightPx }}
        >
          {this.renderNoCategoriesMessage()}
          <RecipeCategoriesList />
        </IonContent>
      </>
    )
  }
}
