import React, { useEffect } from 'react'
import { IonTitle, IonToolbar, IonHeader, IonButtons, IonButton, IonContent } from '@ionic/react'
import { inject, Observer } from 'mobx-react'
import { AntDesign } from 'react-web-vector-icons'
import { AppStore } from 'src/stores/AppStore'
import { EvilIcons } from 'react-web-vector-icons'
import ItemNewModal from 'src/items/views/new/ItemNewModal'
import { ItemsStore } from '../../store/ItemsStore'
import ItemsList from './ItemsList'
import { faAngleDoubleDown, faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  itemsStore?: ItemsStore
  appStore?: AppStore
}

const ItemsListPage: React.FC<Props> = ({ itemsStore, appStore }) => {
  useEffect(() => {
    appStore.setCurrentRoute('/itemslist')
  })

  return (
    <Observer>
      {() => {
        const itemsList = itemsStore.listVM
        if (!itemsList) return null
        const vm = itemsList

        const renderNewModal = () => {
          if (!vm.itemNewVM) return
          return <ItemNewModal vm={itemsList.itemNewVM} />
        }

        const renderExpandAll = () => {
          if (!vm.startCollapsed) return
          return (
            <IonButton onClick={(e) => vm.expandCollapseAll()}>
              <FontAwesomeIcon icon={faAngleDoubleDown} />
            </IonButton>
          )
        }

        const renderCollapseAll = () => {
          if (vm.startCollapsed) return
          return (
            <IonButton onClick={(e) => vm.expandCollapseAll()}>
              <FontAwesomeIcon icon={faAngleDoubleUp} />
            </IonButton>
          )
        }

        return (
          <>
            <IonHeader>
              <IonToolbar />
            </IonHeader>

            <IonHeader id='ItemsListPage_Header'>
              <IonToolbar color='secondary'>
                <IonTitle>All Items</IonTitle>
                <IonButtons slot='end'>
                  {renderExpandAll()}
                  {renderCollapseAll()}
                  <IonButton onClick={() => vm.goToCategoriesSetup()}>
                    <EvilIcons name='gear' size={20} />
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>

            <IonToolbar id='ItemsSelect_Toolbar' color='secondary'>
              <input
                type='text'
                className='ion-input-md'
                placeholder='Search...'
                value={vm.typedFilter}
                onChange={(e: any) => vm.setFilter(e.target.value)}
              />

              {vm.hasFilter && (
                <div slot='end' onClick={() => vm.clearFilter()}>
                  <AntDesign name='closecircleo' size={18} />
                </div>
              )}
            </IonToolbar>

            <IonContent id='ItemSelectPage_Content' scrollY={false} style={{ height: appStore.listHeightPx }}>
              <ItemsList itemsList={vm} />
              {renderNewModal()}
            </IonContent>
          </>
        )
      }}
    </Observer>
  )
}

export default inject('itemsStore', 'appStore')(ItemsListPage)
