import { RootStore } from 'src/stores/RootStore'
import { computed, action, observable, makeObservable } from 'mobx'
import moment, { Moment } from 'moment'
import { MealCategoryRowVM } from './MealCategoryRowVM'
import { MealChipVM } from './MealChipVM'

export class DayGroupVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, forDate: Moment, meals: MealChipVM[], mealCategories: MealCategoryRowVM[]) {
    this.rootStore = rootStore
    this.forDate = forDate
    this.mealCategories = mealCategories.sort((a, b) => a.rank - b.rank)
    // this.meals = meals.sort((a, b) => a.rank - b.rank)
    makeObservable(this)
  }

  public type: string = 'daygroup'
  public forDate: Moment
  public mealCategories: MealCategoryRowVM[]
  // public meals: MealChipVM[]
  @observable public mealMenuShown: boolean = false

  @computed
  public get dayOfWeek(): string {
    return this.forDate.format('ddd')
  }

  @computed
  public get date(): string {
    return this.forDate.format('MMM D')
  }

  @computed
  public get isPrior(): boolean {
    return this.forDate.isBefore(moment().startOf('day'))
  }

  @computed
  public get isToday(): boolean {
    return this.forDate.format('MM/DD/YYYY') === moment().format('MM/DD/YYYY')
  }

  @action
  public addMeal(e: MealCategoryRowVM): void {
    this.hideMealMenu()
    setTimeout(() => {
      this.rootStore.appStore.history.push(
        '/mealedit/new/' + this.forDate.format('MMDDYYYY') + '/' + e.mealCategoryGuid
      )
    }, 200)
  }

  @computed
  public get availableMealCategories() {
    return this.mealCategories.filter((e) => e.visibleForThisUser && e.availableForThisWeekDay(this.forDate))
  }

  @action
  public hideMealMenu(): void {
    this.mealMenuShown = false
  }

  @action
  public goToMealsSetup(): void {
    this.hideMealMenu()
    setTimeout(() => {
      this.rootStore.appStore.history.push('/mealcategorieslist')
    }, 200)
  }
}
