import { RootStore } from 'src/stores/RootStore'
import { computed, action, observable } from 'mobx'
import { ThemesListVM } from './ThemesListVM'
import { ITheme } from '../../theme-gen/aggregate/ITheme'

export class ThemeRowVM {
  private rootStore: RootStore
  private listVM: ThemesListVM
  private theme: ITheme

  constructor(rootStore: RootStore, listVM: ThemesListVM, theme: ITheme) {
    this.rootStore = rootStore
    this.theme = theme
    this.listVM = listVM
  }

  @computed
  public get name(): string {
    if (this.theme.name === 'default') return 'Light'
    if (this.theme.name === 'system') return 'System Default' 
    return this.theme.name
  }

  @computed
  public get primaryColor(): string {
    if (this.theme.name === 'system') return '#c0c0c0'
    return this.theme.primaryColor
  }

  @action
  public select() {
    this.rootStore.appStore.themeGenVM.loadTheme(this.theme.name)
    if (!this.rootStore.appStore.isSplitPaneVisible) {
      this.rootStore.appStore.navigateTo('/')
    }
  }
}
