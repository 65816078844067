import { RootStore } from 'src/stores/RootStore'
import { computed } from 'mobx'
import { ListItemsListVM } from './ListItemsListVM'
import { GroupRowVM } from '@elexient/elexiapp.bits.shared'

export class ListItemGroupRowVM extends GroupRowVM<RootStore, ListItemsListVM> {
  public get categoryGuid(): string {
    const cat = this.rootStore.categoriesStore.currentBoardRecords.find((e) => e.Name === this.name)
    if (!cat) return undefined
    return cat.CategoryGuid
  }

  public get key(): string {
    return this.categoryGuid
  }

  @computed
  public get color(): string {
    if (!this.rootStore.categoriesStore) return ''
    const foundCategory = this.rootStore.categoriesStore.get(this.categoryGuid)
    if (!foundCategory) return ''
    return foundCategory.Color
  }
}
