import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import VirtualList from 'react-tiny-virtual-list'
import { AppStore } from 'src/stores/AppStore'
import ListItemRow from './CategoryRow'
import { CategoriesStore } from '../../store/CategoriesStore'
import { IonList } from '@ionic/react'

interface Props {
  categoriesStore?: CategoriesStore
  appStore?: AppStore
}

const CategoriesList: React.FC<Props> = ({ categoriesStore, appStore }) => {
  const listVM = categoriesStore.listVM
  if (!listVM) return null
  if (appStore.bodyHeight === 0) return null

  const renderListRows = () => {
    return listVM.rows.map((e, idx) => <ListItemRow key={'item' + idx} row={e} />)
  }

  return <IonList>{renderListRows()}</IonList>
}

export default inject('categoriesStore', 'appStore')(observer(CategoriesList))
