import { observable, action } from 'mobx'
import { persist } from 'mobx-persist'
import { serializable } from 'serializr'
import { IDayDTO } from '../dtos/IDayDTO'
import { IVisibleUserDTO } from '../dtos/IVisibleUserDTO'

export class VisibleUser implements IVisibleUserDTO {
  public static create(appUserId: number, enabled: boolean): VisibleUser {
    const vu = new VisibleUser()
    vu.AppUserId = appUserId
    vu.Enabled = enabled
    return vu
  }

  @serializable @observable AppUserId: number = 0
  @serializable @observable Enabled: boolean = false

  @action
  public setEnabled(val) {
    this.Enabled = val
  }

  @action
  public updateFromServer(vu: IVisibleUserDTO) {
    this.Enabled = vu.Enabled
  }

  @action
  public toggleEnabled() {
    this.Enabled = !this.Enabled
  }

  public toDTO(): IVisibleUserDTO {
    return JSON.parse(JSON.stringify(this))
  }
}
