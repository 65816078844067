import React from 'react'
import { IonModal, IonTitle, IonToolbar, IonHeader, IonButtons, IonButton, IonIcon, IonContent } from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { close, checkmarkSharp } from 'ionicons/icons'
import { AntDesign } from 'react-web-vector-icons'
import RecipeSelectList from './RecipeSelectList'
import { MealsStore } from 'src/meals/store/MealsStore'
import BackIcon from 'src/shared/BackIcon'

interface Props {
  mealsStore?: MealsStore
}

const RecipeSelectModal: React.FC<Props> = ({ mealsStore }) => {
  const { editVM } = mealsStore
  if (!editVM) return null
  const vm = editVM.recipeSelectList
  if (!vm) return null

  const renderClearButton = () => {
    if (!vm.hasFilter) return
    return (
      <div slot='end' onClick={() => vm.clearFilter()}>
        <AntDesign name='closecircleo' color='white' size={18} />
      </div>
    )
  }

  return (
    <IonModal
      id='RecipeSelectModal'
      isOpen={vm.shown}
      showBackdrop={true}
      onDidPresent={() => vm.modalShown()}
      onDidDismiss={() => vm.hide()}
    >
      <IonHeader>
        <IonToolbar color='secondary'>
          <IonButtons slot='start'>
            <IonButton onClick={() => vm.goBack()}>
              <BackIcon />
            </IonButton>
          </IonButtons>
          <IonTitle>Recipes</IonTitle>
          <IonButtons slot='end'>
            <IonButton onClick={() => vm.cancel()}>
              <IonIcon icon={close} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonToolbar id='ItemsSelect_Toolbar' color='secondary'>
        <input
          type='text'
          className='ion-input-md'
          placeholder='Search...'
          value={vm.typedFilter}
          onChange={(e: any) => vm.setFilter(e.target.value)}
        />
        {renderClearButton()}
      </IonToolbar>
      <IonContent scrollY={false}>
        <RecipeSelectList />
      </IonContent>
    </IonModal>
  )
}

export default inject('mealsStore')(observer(RecipeSelectModal))
