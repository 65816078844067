import React from 'react'
import { inject, observer } from 'mobx-react'
import { MealsStore } from '../../store/MealsStore'
import {
  IonLabel,
  IonCardHeader,
  IonCardContent,
  IonCard,
  IonIcon,
  IonList,
  IonCardTitle,
  IonTitle,
} from '@ionic/react'
import { ellipsisVertical } from 'ionicons/icons'
import { MealRecipeCardVM } from 'src/meals/view-models/edit/MealRecipeCardVM'
import RecipeItemRow from './RecipeItemRow'
import OtherItemRow from './OtherItemRow'

interface Props {
  mealsStore?: MealsStore
}

const RecipeCards: React.FC<Props> = ({ mealsStore }) => {
  const { editVM } = mealsStore
  if (!editVM) return null

  const renderRecipeCards = () => {
    return editVM.mealRecipes.map(e => renderCard(e))
  }

  const renderNoItems = (mr: MealRecipeCardVM) => {
    const mealItems = mr.getRecipeItems(mr.recipeGuid)
    if (mealItems.length !== 0) return null
    return (
      <IonCardContent className='no-items'>
        <IonLabel>No Items</IonLabel>
      </IonCardContent>
    )
  }

  const renderRecipeItems = (mr: MealRecipeCardVM) => {
    const recItems = mr.getRecipeItems(mr.recipeGuid)
    if (recItems.length === 0) return null
    return (
      <IonCardContent mode='md'>
        <IonList>
          {recItems.map(e => (
            <RecipeItemRow row={e} key={e.recipeItemGuid} />
          ))}
        </IonList>
      </IonCardContent>
    )
  }

  const renderCard = (mr: MealRecipeCardVM) => {
    return (
      <IonCard key={mr.mealRecipeGuid} mode='md'>
        <IonCardHeader color='medium'>
          <IonCardTitle color='medium'>
            <IonTitle>{mr.name}</IonTitle>
            <IonIcon icon={ellipsisVertical} className='more-menu-icon' onClick={e => editVM.showRecipeMenu(e, mr.recipeGuid)} />
          </IonCardTitle>
        </IonCardHeader>
        {renderNoItems(mr)}
        {renderRecipeItems(mr)}
      </IonCard>
    )
  }

  const renderOtherItemsCard = () => {
    if (!editVM.hasOtherItems) return null
    return (
      <IonCard mode='md'>
        <IonCardHeader color='medium'>
          <IonCardTitle color='medium'>
            <IonTitle>Other Items</IonTitle>
          </IonCardTitle>
        </IonCardHeader>
        {renderOtherItems()}
      </IonCard>
    )
  }

  const renderOtherItems = () => {
    return (
      <IonCardContent>
        <IonList>
          {editVM.otherItems.map(e => (
            <OtherItemRow row={e} key={e.mealItemGuid} />
          ))}
        </IonList>
      </IonCardContent>
    )
  }

  return (
    <div id='ItemsList'>
      <div id='items-container' className='wrapper'>
        {renderRecipeCards()}
        {renderOtherItemsCard()}
      </div>
    </div>
  )
}

export default inject('mealsStore')(observer(RecipeCards))
