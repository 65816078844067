import { observable, action, makeObservable } from 'mobx'
import { BoardUser } from './BoardUser'
import { BoardInvitation } from './BoardInvitation'
import { IBoardDTO } from '../dtos/IBoardDTO'
import generateUUID from '../../utils/UUID'
import { deserialize, list, object, serializable, serialize } from 'serializr'

export class Board implements IBoardDTO {
  static create() {
    const brd = new Board()
    brd.BoardGuid = generateUUID()
    return brd
  }

  constructor() {
    makeObservable(this)
  }

  @serializable @observable public BoardId: number = 0
  @serializable @observable public BoardGuid: string = ''
  @serializable @observable public Name: string = ''
  @serializable @observable public InvitationCode: string = null
  @serializable(list(object(BoardUser))) @observable public BoardUsers: Array<BoardUser> = []
  @serializable(list(object(BoardInvitation))) @observable public BoardInvitations: Array<BoardInvitation> = []
  @serializable @observable IsDeleted: boolean = false
  public isOnServer: boolean = false

  public markIsOnServer(): void {
    this.isOnServer = true
  }

  public markIsNotOnServer(): void {
    this.isOnServer = false
  }

  @action
  public setName(val) {
    this.Name = val
  }

  @action
  public markAsDeleted() {
    this.IsDeleted = true
  }

  public clone(): Board {
    return deserialize(Board, serialize(this))
  }

  public toDTO(): IBoardDTO {
    return serialize(this)
  }
}
