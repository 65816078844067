import { RootStore } from '../../../stores/RootStore'
import { Day } from '../../aggregate/Day'
import { MealCategoryRowVM } from './MealCategoryRowVM'
import { computed } from 'mobx'

export class DayVM {
  private rootStore: RootStore
  private rowVM: MealCategoryRowVM
  private day: Day

  constructor(rootStore: RootStore, rowVM: MealCategoryRowVM, day: Day) {
    this.rootStore = rootStore
    this.rowVM = rowVM
    this.day = day
  }

  @computed
  public get Time(): string {
    if (!this.day.Enabled) return ''
    if (this.day.Time && this.day.SpecificTime) return this.day.Time12Hr
    if (this.rowVM.time12Hr) return this.rowVM.time12Hr
    return ''
  }

  @computed
  public get DayIndex(): number {
    return this.day.DayIndex
  }

  @computed
  public get Enabled(): boolean {
    return this.day.Enabled
  }

  @computed
  public get DayLetter(): string {
    return this.day.DayLetter
  }
}
