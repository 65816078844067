import React from 'react'
import { IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonText } from '@ionic/react'
import { chevronForwardOutline } from 'ionicons/icons'
import { SettingsListVM } from '../view-models/SettingsListVM'
import { MaterialCommunityIcons, Entypo } from 'react-web-vector-icons'
import AppUpdateAvailableCard from '../../app-updates/views/AppUpdateAvailableCard'

interface Props {
  vm: SettingsListVM
}

const SettingsList: React.FC<Props> = ({ vm }) => {
  const renderCaret = () => {
    if (!vm.isIos) return
    return <IonIcon icon={chevronForwardOutline} />
  }

  const renderSettingsItems = () => {
    return (
      <>
        <IonListHeader>
          <IonLabel>Settings</IonLabel>
        </IonListHeader>
        <IonItem onClick={() => vm.goTo('/themeslist')}>
          <IonLabel>Theme</IonLabel>
          <IonText>{vm.themeMessage}</IonText>
          &nbsp;
          {renderCaret()}
        </IonItem>
        {/* <IonItem onClick={() => vm.goTo('/notifications')}>
          <IonLabel>Notifications</IonLabel>
          {renderCaret()}
        </IonItem> */}
      </>
    )
  }

  const renderBoardOptions = () => {
    return vm.boards.map((brd, idx) => (
      <IonItem onClick={() => vm.openBoard(brd.BoardGuid)} key={'brd' + idx}>
        {brd.Name}
      </IonItem>
    ))
  }

  const renderBoardSettingsItems = () => {
    return (
      <>
        <IonListHeader>
          <IonLabel>Boards</IonLabel>
        </IonListHeader>
        {renderBoardOptions()}
        <IonItem lines='full' onClick={() => vm.openNewBoardModal()}>
          Create New Board
        </IonItem>
        <IonItem lines='full' id='JoinBoardRow' onClick={() => vm.openJoinBoardModal()}>
          Join Another Board
        </IonItem>
      </>
    )
  }

  const renderAccountItems = () => {
    return (
      <>
        <IonListHeader>
          <IonLabel>Account</IonLabel>
        </IonListHeader>
        <IonItem onClick={() => vm.goTo('/accountsettings')}>
          <IonLabel>Edit Profile</IonLabel>
          {renderCaret()}
        </IonItem>
        {/* <IonItem onClick={() => vm.goTo('/changepassword')}>
          <IonLabel>Change Password</IonLabel>
          {renderCaret()}
        </IonItem> */}
        <IonItem onClick={() => vm.goTo('/accountsettings')}>
          <IonLabel>Login with Google</IonLabel>
          <IonText>{vm.googleConnectedMessage}</IonText>
          &nbsp;
          {renderCaret()}
        </IonItem>
        <IonItem onClick={() => vm.goTo('/accountsettings')}>
          <IonLabel>Login with Apple</IonLabel>
          <IonText>{vm.googleConnectedMessage}</IonText>
          &nbsp;
          {renderCaret()}
        </IonItem>
        <IonItem onClick={() => vm.logout()}>
          <IonLabel>Sign Out</IonLabel>
          {renderCaret()}
        </IonItem>
      </>
    )
  }

  const renderHelpItems = () => {
    return (
      <>
        <IonListHeader>
          <IonLabel>Help and Support</IonLabel>
        </IonListHeader>
        <IonItem onClick={() => vm.openFAQ()}>
          <IonLabel>Frequently Asked Questions</IonLabel>
          {renderCaret()}
        </IonItem>
        <IonItem onClick={() => vm.openContact()}>
          <IonLabel>Suggest a Feature</IonLabel>
          {renderCaret()}
        </IonItem>
        <IonItem onClick={() => vm.openWebsite()}>
          <IonLabel>Visit Website</IonLabel>
          {renderCaret()}
        </IonItem>
        <IonItem onClick={() => vm.openContact()}>
          <IonLabel>Report a Bug</IonLabel>
          {renderCaret()}
        </IonItem>
        {/* <IonItem onClick={() => vm.logout()}>
          <IonLabel>Leave a Review</IonLabel>
          {renderCaret()}
        </IonItem> */}
        <IonItem onClick={() => vm.openWebsite()}>
          <IonLabel>View All Apps</IonLabel>
          {renderCaret()}
        </IonItem>
      </>
    )
  }

  const renderAppUpdate = () => {
    return <AppUpdateAvailableCard />
  }

  return (
    <IonList key={vm.key}>
      {renderAppUpdate()}
      {renderAccountItems()}
      {renderSettingsItems()}
      {renderBoardSettingsItems()}
      {renderHelpItems()}
    </IonList>
  )
}

export default SettingsList
