import agent from '../../Agent'
import { RootStore } from '../../stores/RootStore'
import { IRecipeCategoryDTO } from '../dtos/IRecipeCategoryDTO'
import { ActionTypeEnum } from '../../actions/enum/ActionTypeEnum'
import { deserialize } from 'serializr'
import { RecipeCategory } from '../aggregate/RecipeCategory'

export class RecipeCategoriesService {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  public save(dto: IRecipeCategoryDTO) {
    this.rootStore.recipeCategoriesStore.updateFromServer(deserialize(RecipeCategory, dto))
    this.rootStore.actionsStore.addAction(ActionTypeEnum.RECIPECATEGORY_SAVE, dto)
  }

  public async saveOnServer(dto: IRecipeCategoryDTO) {
    const savedDTO = await agent.RecipeCategories.save(dto)
    this.rootStore.recipeCategoriesStore.updateFromServer(savedDTO)
  }

  public delete(dto: IRecipeCategoryDTO) {
    dto.IsDeleted = true
    this.save(dto)
    this.rootStore.recipesStore.currentBoardRecords.forEach(e => {
      const hasCat = e.Categories.some(ee => ee.RecipeCategoryGuid === dto.RecipeCategoryGuid)
      if (hasCat) {
        const cat = e.getCategoryForRecipeCategory(dto.RecipeCategoryGuid)
        e.deleteCategory(cat.CategoryGuid)
      }
    })
  }
}
