import React from 'react'
import { inject, observer } from 'mobx-react'
import { IonItem, IonLabel, IonIcon, IonToggle, IonButton, IonCard, IonCardContent } from '@ionic/react'
import { closeCircle } from 'ionicons/icons'
import { MealCategoryEditVM } from 'src/meal-categories/view-models/edit/MealCategoryEditVM'
import { TimePicker } from '@material-ui/pickers'
import { MealEditVM } from 'src/meals/view-models/edit/MealEditVM'

interface Props {
  editVM: MealEditVM
}

const PlannedTimeRow: React.FC<Props> = ({ editVM }) => {
  const renderTimePicker = () => {
    return (
      <TimePicker
        // minutesStep={15}
        disabled={!editVM.specificTime}
        value={editVM.localPlannedDateTime}
        onChange={e => editVM.setPlannedDateTime(e)}
        format='hh:mm a'
      />
    )
  }

  const renderClearIcon = () => {
    if (!editVM.specificTime) return
    return (
      <IonIcon
        onClick={e => {
          e.stopPropagation()
          editVM.toggleSpecificTime()
        }}
        color='tertiary'
        icon={closeCircle}
      />
    )
  }

  const renderContent = () => {
    if (!editVM.specificTime) return
    return (
      <div className='end' onClick={e => e.stopPropagation()} slot='end'>
        {renderTimePicker()}
        {renderClearIcon()}
      </div>
    )
  }

  return (
    <IonCard id='PlannedTimeRow'>
      <IonItem lines='none' onClick={() => editVM.toggleSpecificTime()}>
        <IonToggle slot='start' color='tertiary' checked={editVM.specificTime} />
        <IonLabel>Reminder</IonLabel>
        {renderContent()}
      </IonItem>
    </IonCard>
  )
}

export default observer(PlannedTimeRow)
