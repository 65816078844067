import { RootStore } from 'src/stores/RootStore'
import { computed, action, observable, runInAction, makeObservable } from 'mobx'
import { Item } from 'src/items/aggregate/Item'
import { ItemsListVM } from './ItemsListVM'
import { RowVM } from '@elexient/elexiapp.bits.shared'
import { ListItem } from '../../../list-items/aggregate/ListItem'
import { ListItemsService } from '../../../list-items/services/ListItemsService'
import { Category } from '../../../categories/aggregate/Category'
import { ItemsService } from '../../services/ItemsService'

export class ItemRowVM extends RowVM<RootStore, ItemsListVM, Item> {
  constructor(rootStore: RootStore, listVM: ItemsListVM, record: Item) {
    super(rootStore, listVM, record, ItemRowVM)
    makeObservable(this)
  }

  @observable public adding: boolean = false
  @observable public renderVersion: number = 1
  @observable public deleteConfirmShown: boolean = false

  @action
  public hideDeleteConfirm() {
    this.slidingRef.style.left = '0px'
    this.deleteConfirmShown = false
  }

  @action
  public showDeleteConfirm() {
    this.deleteConfirmShown = true
  }

  public get item(): Item {
    return this.record
  }

  public get height() {
    if (this.isHidden) return 0
    return 45
  }

  @computed
  public get keyword(): string {
    return this.categoryName + this.name
  }

  @computed
  public get name(): string {
    if (!this.item) return ''
    return this.item.Name
  }

  @computed
  public get categoryGuid(): string {
    return this.item.CategoryGuid
  }

  @computed
  private get category(): Category {
    return this.rootStore.categoriesStore.get(this.categoryGuid)
  }

  @computed
  public get categoryName(): string {
    if (!this.category) return ''
    return this.category.Name
  }

  @computed
  public get categoryColor(): string {
    if (!this.category) return ''
    return this.category.Color
  }

  @computed
  public get itemGuid(): string {
    return this.item.ItemGuid
  }

  private get listItem(): ListItem {
    return this.rootStore.listItemsStore.getByItemGuid(this.record.ItemGuid)
  }

  @computed
  public get quantity(): number {
    if (!this.listItem) return 0
    if (this.listItem.isCleared) return 0
    return this.listItem.Quantity
  }

  @computed
  public get isGotten(): boolean {
    if (!this.listItem) return false
    return this.listItem.isGotten
  }

  @computed
  public get hasQuantity(): boolean {
    return this.quantity !== 0
  }

  @computed
  public get iconName(): string {
    if (this.hasQuantity && this.isGotten) return 'checkmark'
    if (this.hasQuantity) return 'cart'
    return 'add'
  }

  @action
  public toggle() {
    if (!this.hasQuantity) {
      this.increaseQuantity()
    } else if (this.hasQuantity && !this.isGotten) {
      this.listItem.toggleGotten()
      this.saveListItem()
    } else if (this.hasQuantity && this.isGotten) {
      this.listItem.markAsCleared()
      this.saveListItem()
    } else this.deleteListItem()
    runInAction(() => this.forceUpdate())
  }

  @action
  private saveListItem() {
    const svc = new ListItemsService(this.rootStore)
    svc.save(this.listItem.toDTO())
  }

  @action
  private deleteListItem() {
    const svc = new ListItemsService(this.rootStore)
    svc.delete(this.listItem.toDTO())
  }

  @action
  public increaseQuantity() {
    if (!this.listItem) {
      const listItem = ListItem.create(this.rootStore.boardsStore.currentBoardId, this.itemGuid)
      listItem.increaseQuantity()
      const svc = new ListItemsService(this.rootStore)
      svc.save(listItem.toDTO())
      return
    }
    this.listItem.increaseQuantity()
    this.saveListItem()
  }

  @action
  public decreaseQuantity() {
    this.listItem.decreaseQuantity()
    this.saveListItem()
  }

  @action
  public click() {
    if (this.listVM.slidingElement === this.slidingRef && this.listVM.slidingElementDidMove) {
      return
    }
    this.goToEditPage()
  }

  @action
  public goToEditPage() {
    this.rootStore.appStore.history.push('/itemedit/' + this.itemGuid)
  }

  @computed
  public get className(): string {
    if (this.hasQuantity) return 'checked'
  }

  @computed
  public get hasNotes(): boolean {
    return this.notes && this.notes !== ''
  }

  @computed
  public get notes(): string {
    return this.item.Notes
  }

  @action
  public longPress() {
    this.listVM.openItemMenu()
  }

  @action
  private forceUpdate() {
    this.renderVersion += 1
  }

  @action
  public async delete(shoppingListOnly: boolean) {
    if (shoppingListOnly) {
      const listItemsSvc = new ListItemsService(this.rootStore)
      await listItemsSvc.delete(this.listItem.toDTO())
      this.slidingRef.style.left = '0px'
      return
    }
    const svc = new ItemsService(this.rootStore)
    await svc.deleteItem(this.item.toDTO())
  }

  @computed
  public get key(): string {
    return 'r' + this.index + String(this.renderVersion)
  }
}
