import { observable, action, computed, makeObservable } from 'mobx'
import generateUUID from '../../utils/UUID'
import { deserialize, serializable, serialize } from 'serializr'
import { IItemDTO } from '../dtos/IItemDTO'
import { IAggregrate } from '../../shared/IAggregate'

export class Item implements IItemDTO, IAggregrate {
  public static create(boardId: number, itemGuid: string = null) {
    const it = new Item()
    it.ItemGuid = itemGuid
    if (!it.ItemGuid) it.ItemGuid = generateUUID()
    it.BoardId = boardId
    return it
  }
  
  constructor() {
    makeObservable(this)
  }

  @serializable @observable public ItemGuid: string = ''
  @serializable @observable public BoardId: number = 0
  @serializable @observable public Name: string = ''
  @serializable @observable public Notes: string = ''
  @serializable @observable public CategoryGuid: string = '00000000-0000-0000-0000-000000000000'
  @serializable @observable public IsDeleted: boolean = false
  public isOnServer: boolean = false

  public markIsOnServer() {
    this.isOnServer = true
  }

  public markIsNotOnServer() {
    this.isOnServer = false
  }

  @computed
  public get hasNotes(): boolean {
    return this.Notes && this.Notes !== ''
  }

  @action
  public setName(val) {
    this.Name = val
  }

  @action
  public setNotes(val) {
    this.Notes = val
  }

  @action
  public setCategory(categoryGuid) {
    this.CategoryGuid = categoryGuid
  }

  public clone(): Item {
    return deserialize(Item, serialize(this))
  }

  public toDTO(): IItemDTO {
    return serialize(this)
  }
}
