import React from 'react'
import { inject, observer } from 'mobx-react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import MealCategoryRow from './MealCategoryRow'
import { MealCategoriesStore } from 'src/meal-categories/store/MealCategoriesStore'
import { IonGrid } from '@ionic/react'

interface Props {
  mealCategoriesStore?: MealCategoriesStore
}

const MealCategoriesList: React.FC<Props> = ({ mealCategoriesStore }) => {
  const { listVM } = mealCategoriesStore
  if (!listVM) return null

  const renderRow = (row, idx) => {
    return <MealCategoryRow key={'mcat' + idx} row={row} />
  }

  const SortableItem = SortableElement(({ value, index }) => {
    return <div>{renderRow(value, index)}</div>
  })

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div>
        {items.map((value, index) => (
          <SortableItem disabled={!listVM.sortMode} key={`item-${index}`} index={index} value={value} />
        ))}
      </div>
    )
  })

  const renderSortableList = () => {
    if (!listVM.sortMode) return
    return (
      <SortableList
        key={listVM.sortMode.valueOf.toString()}
        items={listVM.rowsOrdered}
        onSortEnd={({ oldIndex, newIndex }) => listVM.sortRows(oldIndex, newIndex)}
      />
    )
  }

  const renderNormalList = () => {
    if (listVM.sortMode) return
    return listVM.rowsOrdered.map((e, idx) => <MealCategoryRow key={'mcatd' + idx} row={e} />)
  }

  return (
    <IonGrid>
      {renderSortableList()}
      {renderNormalList()}
    </IonGrid>
  )
}
export default inject('mealCategoriesStore')(observer(MealCategoriesList))
