import React from 'react'
import { observer } from 'mobx-react'
import { IonCard, IonCardContent } from '@ionic/react'
import { ThemeRowVM } from '../view-models/ThemeRowVM'

interface Props {
  row: ThemeRowVM
}

const CategoryRow: React.FC<Props> = ({ row }) => {
  return (
    <IonCard id='ThemeRow' onClick={() => row.select()} style={{ borderLeftColor: row.primaryColor }}>
      <IonCardContent>{row.name}</IonCardContent>
    </IonCard>
  )
}

export default observer(CategoryRow)
