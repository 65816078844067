import React from 'react'
import { observer, inject } from 'mobx-react'
import { StepRowVM } from '../../view-models/edit/StepRowVM'
import { IonIcon, IonTextarea } from '@ionic/react'
import { trash, reorderTwo, trashOutline } from 'ionicons/icons'
import { RecipeEditVM } from 'src/recipes/view-models/edit/RecipeEditVM'
import { RecipesStore } from 'src/recipes/store/RecipesStore'
import { EvilIcons } from 'react-web-vector-icons'
import { SortableHandle } from 'react-sortable-hoc'

interface Props {
  row: StepRowVM
  editVM?: RecipeEditVM
  recipesStore?: RecipesStore
}

const StepRow: React.FC<Props> = ({ row, recipesStore }) => {

  const renderDeleteButton = () => {
    if (!row.editable) return
    return (
      <div className='del-btn' onClick={() => row.delete()}>
        <IonIcon icon={trashOutline} size='small' />
      </div>
    )
  }

  const renderDescriptionInput = () => {
    return (
      <div className='name'>
        <IonTextarea
          disabled={!row.editable}
          className='description-input'
          placeholder='Type or paste steps'
          value={row.description}
          rows={1}
          autoGrow={true}
          onIonChange={(e: any) => row.setDescription(e.target.value)}
          ref={e => row.setTextbox(e)}
          onKeyDown={e => {
            if (e.keyCode === 37 && row.cursorAtStart && row.previousRow) {
              row.previousRow.setFocus()
              row.previousRow.moveCursorToEnd()
              e.preventDefault()
              e.stopPropagation()
            }
            if (e.keyCode === 39 && row.cursorAtEnd && row.nextRow) {
              row.nextRow.setFocus()
              row.nextRow.moveCursorToStart()
              e.preventDefault()
              e.stopPropagation()
            }
            // if (e.charCode === 13) {
            //   editVM.addItem()
            //   e.preventDefault()
            //   e.stopPropagation()
            // }
          }}
          onKeyUp={e => {
            if (e.keyCode === 8 && row.cursorAtStart && row.previousRow) {
              row.previousRow.setFocus()
              row.previousRow.moveCursorToEnd()
              row.combineWithPreviousRow()
              e.preventDefault()
              e.stopPropagation()
            } else if (e.keyCode === 8 && !row.hasDescription) {
              if (row.previousRow) {
                row.previousRow.setFocus()
                row.previousRow.moveCursorToEnd()
                row.delete()
                e.preventDefault()
                e.stopPropagation()
              }
            }
          }}
          onKeyPress={e => {
            // console.log(e.keyCode)
            if (e.charCode === 13) {
              // editVM.addItem()
              // e.preventDefault()
              // e.stopPropagation()
            }
            // if (e.charCode === 8 && !row.hasDescription) row.delete()
          }}
        />
      </div>
    )
  }

  const Handle = SortableHandle(() => {
    return (
      <div className='move-btn'>
        <IonIcon icon={reorderTwo} size='small' />
      </div>
    )
  })

  return (
    <>
      <div id='StepRow'>
        {renderDescriptionInput()}
        <Handle />
        {renderDeleteButton()}
      </div>
    </>
  )
}

export default inject('recipesStore')(observer(StepRow))
