import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { RecipeImportsService } from '../services/RecipeImportsService'
import { IRecipeImportResult } from '../interfaces/IRecipeImportResult'

export class RecipeImportVM {
  private svc: RecipeImportsService

  constructor(private rootStore: RootStore) {
    makeObservable(this)
    this.svc = new RecipeImportsService(this.rootStore)
    this.rootStore.appStore.listenForSignalRUpdate('recipeimports', (e) => this.updateStatus(e))
    //TODO: TAKE INTO ACCOUNT JOB ID
  }

  @observable public url: string = ''
  @observable public status: string = ''
  @observable public errorMessage: string = ''

  @computed
  private get isValid(): boolean {
    return Boolean(this.url)
  }

  @action
  public setUrl(val: string) {
    this.url = val.trim()
  }

  public goBack() {
    this.rootStore.appStore.handleGoBack()
  }

  @computed
  public get isIos(): boolean {
    return this.rootStore.appStore.isIos
  }

  @computed
  public get importButtonDisabled(): boolean {
    return !this.isValid || this.isProcessing
  }

  @computed
  public get isProcessing(): boolean {
    return this.status === 'Queued'
  }

  @action
  private updateStatus(result: IRecipeImportResult) {
    this.status = result.Status
    if (result.Status === 'Queued') {
      this.errorMessage = ''
    }
    if (result.Status === 'Error') {
      this.status = 'Error'
      this.errorMessage = result.ErrorMessage
    }
    if (result.Status !== 'Success') return
    this.rootStore.appStore.navigateTo('/recipeedit/' + result.RecipeGuid + '?fromimport=1')
  }

  public async submit() {
    const result = await this.svc.import(this.url)
    this.updateStatus(result)
  }
}
