import { RootStore } from 'src/stores/RootStore'
import { computed, action, observable, runInAction, makeObservable } from 'mobx'
import { ItemsListVM } from './ItemsListVM'
import { NewRowVM } from '@elexient/elexiapp.bits.shared'
import { ItemNewVM } from '../new/ItemNewVM'
import { ListItem } from '../../../list-items/aggregate/ListItem'
import { ListItemsService } from '../../../list-items/services/ListItemsService'

export class ItemNewRowVM extends NewRowVM<RootStore, ItemsListVM> {
  @computed
  public get displayName(): string {
    if (this.listVM.filter === '') return 'New Item'
    return 'New Item: ' + this.listVM.filter
  }

  get color(): string {
    return ''
  }

  @action
  public openNewItemModal() {
    runInAction(() => {
      this.listVM.itemNewVM = new ItemNewVM(this.rootStore, {
        newItemTypeName: 'Shopping List',
        backdropShown: true,
        defaultName: this.listVM.filter,
        onCompleted: (newName, itemGuid, doAdd, qty) => {
          this.listVM.setFilter(newName)
          this.listVM.reloadRows()
          if (doAdd) {
            const listItem = ListItem.create(this.rootStore.boardsStore.currentBoardId, itemGuid)
            listItem.increaseQuantity(qty)
            const svc = new ListItemsService(this.rootStore)
            svc.save(listItem.toDTO())
          }
        },
      })
    })
    this.listVM.itemNewVM.show(this.listVM.filter)
  }
}
