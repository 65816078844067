import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import { AppStore } from 'src/stores/AppStore'
import ListItemRow from './ThemeRow'
import { IonList } from '@ionic/react'

interface Props {
  appStore?: AppStore
}

const ThemesList: React.FC<Props> = ({ appStore }) => {
  const listVM = appStore.themesListVM
  if (!listVM) return null
  if (appStore.bodyHeight === 0) return null

  const renderListRows = () => {
    return listVM.themeOptions.map((e, idx) => <ListItemRow key={'thm' + idx} row={e} />)
  }

  return <IonList>{renderListRows()}</IonList>
}

export default inject('appStore')(observer(ThemesList))
