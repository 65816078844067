import React from 'react'
import { IonContent, IonPage } from '@ionic/react'
import { inject } from 'mobx-react'
import { AppStore } from 'src/stores/AppStore'
import { AuthStore } from 'src/login/store/AuthStore'
import RegisterForm from './RegisterForm'
import Logo512 from '../../assets/images/Logo_512.png'
import '../styles/RegisterPage.scss'
import WelcomeHeader from './WelcomeHeader'
import SignInFooter from './SignInFooter'

interface Props {
  appStore: AppStore
  authStore: AuthStore
  history: any
}

@inject('appStore')
@inject('authStore')
export default class RegisterPage extends React.Component<Props, any> {
  componentDidMount() {
    this.props.authStore.logout()
    this.props.appStore.setCurrentRoute('/register')
    this.props.authStore.loadRegistrationVM()
    this.props.appStore.themeGenVM.loadTheme('default')
  }

  render() {
    return (
      <IonPage id='RegisterPage'>
        <IonContent>
          <div id='LogoContainer'>
            <img src={Logo512} alt='Grocery Board' />
          </div>
          <WelcomeHeader />
          <RegisterForm />
          <SignInFooter />
        </IonContent>
      </IonPage>
    )
  }
}
