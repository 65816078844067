import { persist } from 'mobx-persist'
import { observable, action, makeObservable } from 'mobx'
import { serialize, deserialize } from 'serializr'
import { IBoardInvitationDTO } from '../dto/IBoardInvitationDTO'

export default class BoardInvitation {
  public static create(boardGuid) {
    const inv = new BoardInvitation()
    inv.BoardGuid = boardGuid
    return inv
  }

  constructor() {
    makeObservable(this)
  }

  @persist @observable public BoardInvitationId: number = 0
  @persist @observable public BoardInvitationGuid: string = ''
  @persist @observable public FromName: string = ''
  @persist @observable public ToEmailAddress: string = ''
  @persist @observable public FromAppUserId: number = 0
  @persist @observable public BoardName: string = ''
  @persist @observable public BoardGuid: string = ''
  @persist @observable IsDeleted: boolean = false
  public isOnServer: boolean = false

  @action
  public setToEmailAddress(val) {
    this.ToEmailAddress = val
  }

  public markIsOnServer(): void {
    this.isOnServer = true
  }

  public markIsNotOnServer(): void {
    this.isOnServer = false
  }

  public clone(): BoardInvitation {
    return deserialize(BoardInvitation, serialize(this))
  }

  public toDTO(): IBoardInvitationDTO {
    return serialize(this)
  }
}
