import { RootStore } from 'src/stores/RootStore'
import { computed, action, observable } from 'mobx'
import { AppStore } from 'src/stores/AppStore'

export class SideMenuVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @computed
  private get appStore(): AppStore {
    return this.rootStore.appStore
  }

  @computed
  public get currentRoute(): string {
    // return window.location.pathname.toLowerCase()
    return this.appStore.currentRoute
  }

  @computed
  private get isMealdit(): boolean {
    return this.currentRoute.indexOf('/mealedit') >= 0
  }

  @computed
  private get isRecipeEdit(): boolean {
    return this.currentRoute.indexOf('/recipeedit') >= 0
  }

  @computed
  private get isRecipesList(): boolean {
    return this.currentRoute.indexOf('/recipeslist') >= 0
  }

  @computed
  private get isSettingsPage(): boolean {
    return this.currentRoute.indexOf('/settings') >= 0
  }

  @computed
  private get isBoardSettingsPage(): boolean {
    return this.currentRoute.indexOf('/boardsettings') >= 0
  }

  @computed
  private get isMealsList(): boolean {
    return this.currentRoute.indexOf('/mealslist') >= 0
  }

  @computed
  private get isMealCategoryEdit(): boolean {
    return this.currentRoute.indexOf('/mealcategoryedit') >= 0
  }

  @computed
  private get isHomePage(): boolean {
    return this.currentRoute === '' || this.currentRoute === '/'
  }

  @computed
  private get isItemEditPage(): boolean {
    return this.currentRoute.indexOf('/itemedit') >= 0
  }

  @computed
  private get isThemeEditPage(): boolean {
    return this.currentRoute.indexOf('/themegen') >= 0
  }

  @computed
  private get isThemesList(): boolean {
    return this.currentRoute.indexOf('/themeslist') >= 0
  }

  @computed
  private get isItemsList(): boolean {
    return this.currentRoute.indexOf('/itemslist') >= 0
  }

  @computed
  private get isCategoriesList(): boolean {
    return this.currentRoute.indexOf('/categorieslist') >= 0
  }

  @computed
  private get isRecipeCategoriesList(): boolean {
    return this.currentRoute.indexOf('/recipecategorieslist') >= 0
  }

  @computed
  private get isMealCategoriesList(): boolean {
    return this.currentRoute.indexOf('/mealcategorieslist') >= 0
  }

  @computed
  private get isCategoryEdit(): boolean {
    return this.currentRoute.indexOf('/categoryedit') >= 0
  }

  @computed
  private get isAccountEdit(): boolean {
    return this.currentRoute.indexOf('/accountsettings') >= 0
  }

  @computed
  private get isRecipeCategoryEdit(): boolean {
    return this.currentRoute.indexOf('/recipecategoryedit') >= 0
  }

  @computed
  private get section(): string {
    let section = ''
    if (this.currentRoute === '/itemslistmain') section = 'items'
    if (this.currentRoute === '/categorieslist') section = 'categories'
    if (this.currentRoute === '/mealcategorieslist') section = 'meals'
    if (this.isRecipeCategoriesList) section = 'recipes'
    if (this.isRecipeCategoryEdit) section = 'recipes'
    if (this.isMealCategoryEdit) section = 'meals'
    if (this.isMealsList) section = 'meals'
    if (this.isMealdit) section = 'meals'
    if (this.isRecipesList) section = 'recipes'
    if (this.isRecipeEdit) section = 'recipes'
    if (this.isHomePage) section = 'shopping-list'
    if (this.isItemEditPage) section = 'shopping-list'
    if (this.appStore.lastRoute === '/itemslist' && this.isItemEditPage) section = 'items'
    if (this.isItemsList) section = 'items'
    if (this.isThemesList) section = 'settings'
    if (this.isThemeEditPage) section = 'settings'
    if (this.isBoardSettingsPage) section = 'settings'
    if (this.isSettingsPage) section = 'settings'
    if (this.isCategoriesList) section = 'categories'
    if (this.isCategoryEdit) section = 'categories'
    if (this.isMealCategoriesList) section = 'meals'
    if (this.isAccountEdit) section = 'settings'
    return section
  }

  @computed
  public get isMealsSection(): boolean {
    return this.section === 'meals'
  }

  @computed
  public get isAccountSettingsSection(): boolean {
    return this.section === 'account'
  }

  @computed
  public get isShoppingListSection(): boolean {
    return this.section === 'shopping-list'
  }

  @computed
  public get isThemesSection(): boolean {
    return this.section === 'themes'
  }

  @computed
  public get isThemeEditSection(): boolean {
    return this.section === 'theme-edit'
  }

  @computed
  public get isItemsSection(): boolean {
    return this.section === 'items'
  }

  @computed
  public get isSettingsSection(): boolean {
    return this.section === 'settings'
  }

  @computed
  public get isBoardSettingsSection(): boolean {
    return this.section === 'board-settings'
  }

  @computed
  public get isRecipesSection(): boolean {
    return this.section === 'recipes'
  }

  @computed
  public get isCategoriesSettingsSection(): boolean {
    return this.section === 'categories'
  }

  @computed
  public get isRecipeCategoriesSettingsSection(): boolean {
    return this.section === 'recipe-categories'
  }

  @computed
  public get isMealCategoriesSettingsSection(): boolean {
    return this.section === 'meal-categories'
  }

  @computed
  public get showThemeEditor(): boolean {
    if (!this.rootStore.userStore.user) return false
    // return false // temp for mockups
    if (this.rootStore.userStore.user.EmailAddress === 'lisamhoward7@gmail.com') return true
    if (this.rootStore.userStore.user.EmailAddress === 'mark@elexient.com') return true
    return false
  }

  public applyScrollStyles(e: HTMLIonContentElement) {
    this.rootStore.appStore.applyScrollStyles(e)
  }
}
