import { Capacitor } from '@capacitor/core'
import { observable, action, makeObservable } from 'mobx'
import SubscribedTopic from './aggregate/SubscribedTopic'
import { RootStore } from 'src/stores/RootStore'
import { FCM } from '@capacitor-community/fcm'
import * as Sentry from '@sentry/browser'
import { makePersistable } from 'mobx-persist-store'
import { deserialize } from 'serializr'
import { ActionPerformed, PushNotifications, PushNotificationSchema } from '@capacitor/push-notifications'
import { LocalNotifications, LocalNotificationSchema } from '@capacitor/local-notifications'

export class NotificationsStore {
  private rootStore: RootStore
  private hasPermission: boolean
  private loaded: boolean

  constructor(rootStore: RootStore) {
    makeObservable(this)
    makePersistable(this, { name: 'NotificationsStore', properties: ['subscribedTopics'] }).then((st) => {
      let isHydrated = false
      if (process.env.NODE_ENV === 'test') isHydrated = true
      if (st && st.isHydrated) isHydrated = true
      if (isHydrated) this.onHydrationCompleted()
    })
    this.rootStore = rootStore
  }

  @action
  public onHydrationCompleted() {
    this.subscribedTopics = observable.array(
      this.subscribedTopics.map((e) => makeObservable(deserialize(SubscribedTopic, e)))
    )
  }

  @observable public subscribedTopics: Array<SubscribedTopic> = []

  public async ensurePermissions() {
    try {
      let permStatus = await PushNotifications.checkPermissions()
      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions()
      }
      if (permStatus.receive !== 'granted') {
        throw new Error('User denied permissions!')
      }
      this.hasPermission = true
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
      this.hasPermission = false
    }
  }

  private async manageTopics() {
    if (!this.hasPermission) return
    const validTopic = this.getValidTopic()
    for (let topic of this.subscribedTopics) {
      if (topic.path !== validTopic) {
        await FCM.unsubscribeFrom({ topic: topic.path })
        this.deleteTopic(topic.path)
      }
    }
    const result = await FCM.subscribeTo({ topic: validTopic })
    this.saveSubscribedTopic(validTopic)
  }

  public async unsubscribeFromAllTopics() {
    if (!this.hasPermission) return
    for (let topic of this.subscribedTopics) {
      await FCM.unsubscribeFrom({ topic: topic.path })
      this.deleteTopic(topic.path)
    }
  }

  private getValidTopic(): string {
    let appMode = 'prod'
    if (process.env.REACT_APP_IS_DEV_MODE === '1') appMode = 'dev'
    return appMode + '_user_' + this.rootStore.userStore.user.IdentityId
  }

  @action
  private saveSubscribedTopic(topicPath) {
    const foundTopic = this.subscribedTopics.find((e) => e.path === topicPath)
    if (foundTopic) return
    this.subscribedTopics.push(SubscribedTopic.create(topicPath))
  }

  @action
  public async processNotifications() {
    if (Capacitor.getPlatform() === 'web') return
    if (!this.rootStore.userStore.user) {
      setTimeout(() => this.processNotifications(), 500)
      return
    }
    if (this.loaded) return
    await this.ensurePermissions()
    await this.ensureFcmToken()
    await this.manageTopics()
    this.listenForNotification()
    this.loaded = true
  }

  private async ensureFcmToken() {
    await PushNotifications.register()
  }

  private listenForNotification() {
    PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
      console.log('received message while app is in foreground')
      console.log(notification)
      this.showLocalNotification(notification)
    })

    PushNotifications.addListener('registrationError', (error: any) => {
      this.hasPermission = false
    })

    PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
      console.log('Push action performed: ' + JSON.stringify(notification))
    })
  }

  private showLocalNotification(notification: PushNotificationSchema) {
    const notif: LocalNotificationSchema = {
      title: notification.title,
      body: notification.body,
      id: 1,
      schedule: { at: new Date(Date.now() + 1000) },
      sound: null,
      attachments: null,
      actionTypeId: '',
      smallIcon: 'ic_notification',
      extra: {
        icon: 'res://drawable-xhdpi/ic_notification.png',
        smallIcon: 'res://drawable-xhdpi/ic_notification.png',
      },
    }

    LocalNotifications.schedule({
      notifications: [notif],
    })
  }

  @action
  public deleteTopic(path) {
    this.subscribedTopics.splice(this.getTopicIndex(path), 1)
  }

  private getTopicIndex(path): number {
    return this.subscribedTopics.findIndex((e) => e.path === path)
  }
}
