import { observable, action, runInAction, computed, makeObservable } from 'mobx'
import agent from '../../Agent'
import _ from 'lodash'
import { User } from '../aggregate/User'
import { UserEditVM } from '../view-models/UserEditVM'
import ResetPasswordForm from '../view-models/ResetPasswordForm'
import { RootStore } from '../../stores/RootStore'
import { makePersistable } from 'mobx-persist-store'
import { deserialize } from 'serializr'
import { ForgotPasswordVM } from '../view-models/ForgotPasswordVM'
import { Device } from '@capacitor/device'
import { App } from '@capacitor/app'

export class UserStore {
  private rootStore: RootStore

  constructor(rootStore) {
    makeObservable(this)
    makePersistable(this, { name: 'UserStore', properties: ['user'] }).then((st) => {
      let isHydrated = false
      if (process.env.NODE_ENV === 'test') isHydrated = true
      if (st && st.isHydrated) isHydrated = true
      if (isHydrated) this.onHydrationCompleted()
    })
    this.rootStore = rootStore
  }

  public onHydrationCompleted() {
    this.forgotPasswordVM = new ForgotPasswordVM(this.rootStore)
  }

  @observable public userEditVM: UserEditVM
  @observable public forgotPasswordVM: ForgotPasswordVM
  @observable public resetPasswordForm: ResetPasswordForm
  @observable public user: User = undefined

  @action
  public async loadUser() {
    const deviceInfo = await this.getDeviceInfo()
    const appInfo = await this.getAppInfo()
    try {
      const response = await agent.Users.getInfoV3({ deviceInfo, appInfo })
      if (response) {
        runInAction(() => {
          this.updateUserFromServer(response.AppUser)
          this.loadUserEditVM()
          this.rootStore.appStore.setToken(response.Token)
          this.rootStore.appStore.setHasUpdate(response.HasUpdate)
        })
      }
    } catch (e) {
      console.log(JSON.stringify(e))
      if (JSON.stringify(e).includes(':401')) {
        this.rootStore.authStore.logout()
        this.rootStore.appStore.history.push('/login')
      }
    }
  }

  private async getDeviceInfo() {
    try {
      let info = await Device.getInfo()
      const id = await Device.getId()
      return { ...info, id }
    } catch (e) {
      return { error: JSON.stringify(e) }
    }
  }

  private async getAppInfo() {
    try {
      const info = await App.getInfo()
      return info
    } catch (e) {
      return { error: JSON.stringify(e) }
    }
  }

  private updateUserFromServer(fromUser) {
    this.user = deserialize(User, fromUser)
    this.rootStore.appStore.firebaseSvc.setUserInfo(fromUser.IdentityId, fromUser.EmailAddress, fromUser.Name)
    return this.user
  }

  @action
  public loadResetPasswordForm(token: string) {
    if (!token) return
    this.resetPasswordForm = new ResetPasswordForm(this.rootStore, token)
  }

  @action
  public loadUserEditVM() {
    if (!this.user) {
      setTimeout(() => this.loadUserEditVM(), 1000)
      return
    }
    this.userEditVM = new UserEditVM(this.rootStore, this.user)
  }

  public clearData() {
    this.user = new User()
  }
}
