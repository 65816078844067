import React from 'react'
import {
  IonContent,
  IonIcon,
  IonButton,
  IonRefresher,
  IonRefresherContent,
  IonFabButton,
  IonFab,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
} from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { AppStore } from 'src/stores/AppStore'
import ListItemsListPageMoreMenu from './ListItemsListPageMoreMenu'
import { add } from 'ionicons/icons'
import { ListItemsStore } from 'src/list-items/store/ListItemsStore'
import ListItemsList from './ListItemsList'
import { EvilIcons } from 'react-web-vector-icons'
import ListItemsListPTR from './ListItemsListPTR'
import OfflineToast from '../../../shared/OfflineToast'

interface Props {
  listItemsStore: ListItemsStore
  appStore: AppStore
  history: any
}

@inject('listItemsStore')
@inject('appStore')
@observer
export default class ListItemsListPage extends React.Component<Props, any> {
  componentDidMount() {
    this.props.appStore.setCurrentRoute('/')
  }

  renderNoItemsMessage() {
    const listVM = this.props.listItemsStore.listVM
    if (!listVM) return null
    if (listVM.allRows.length > 2) return null
    return (
      <div className='no-items'>
        Your shopping list is empty!
        <br />
        Surely, there is something you need...
        <br />
        <br />
        <IonButton slot='icon-only' type='button' color='tertiary' onClick={() => listVM.goToItemsSelect()}>
          <IonIcon icon={add} />
        </IonButton>
      </div>
    )
  }

  render() {
    const listVM = this.props.listItemsStore.listVM
    if (!listVM) return null
    return (
      <>
        <IonHeader>
          <IonToolbar />
        </IonHeader>

        <IonHeader id='ListItemsListPage_Header'>
          <IonToolbar color='secondary'>
            <IonTitle>Shopping List</IonTitle>
            <IonButtons slot='end'>
              <ListItemsListPageMoreMenu />
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent
          id='ListItemsListPage_Content'
          style={{ height: this.props.appStore.listHeightPx }}
          scrollY={false}
        >
          <OfflineToast shown={listVM.offlineToastShown} onDidDismiss={() => listVM.hideOfflineToast()} />
          <ListItemsListPTR />
          {this.renderNoItemsMessage()}
          <ListItemsList />
        </IonContent>

        <IonFab vertical='bottom' horizontal='end'>
          <IonFabButton
            color='tertiary'
            onClick={() => listVM.goToItemsSelect()}
            style={{ marginTop: this.props.appStore.fabOffset }}
          >
            <IonIcon icon={add} />
          </IonFabButton>
        </IonFab>
      </>
    )
  }
}
