import { makeObservable, observable } from 'mobx'
import { IBoardUserDTO } from '../dtos/IBoardUserDTO'
import { AppUser } from './AppUser'
import { object, serializable } from 'serializr'

export class BoardUser implements IBoardUserDTO {
  constructor() {
    makeObservable(this)
  }

  @serializable(object(AppUser)) @observable AppUser: AppUser = null
  @serializable @observable AppUserId: number
  @serializable @observable BoardUserId: string
  @serializable @observable IsDeleted: boolean
}
