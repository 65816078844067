import { RootStore } from 'src/stores/RootStore'
import { computed, makeObservable } from 'mobx'
import { RecipeSelectListVM } from './RecipeSelectListVM'
import { GroupRowVM } from '@elexient/elexiapp.bits.shared'

export class RecipeGroupRowVM extends GroupRowVM<RootStore, RecipeSelectListVM> {
  public color: string = ''

  @computed
  public get displayName(): string {
    return this.name
  }
}
