import { observable, action, computed, makeObservable } from 'mobx'
import { RootStore } from 'src/stores/RootStore'
import { RecipeCategoryEditVM } from '../view-models/RecipeCategoryEditVM'
import { RecipeCategory } from '../aggregate/RecipeCategory'
import { RecipeCategoriesListVM } from '../view-models/RecipeCategoriesListVM'
import { IRecipeCategoryDTO } from '../dtos/IRecipeCategoryDTO'
import { DataStore } from '@elexient/elexiapp.bits.shared'
import AgentV2 from '../../AgentV2'
import { makePersistable } from 'mobx-persist-store'
import { deserialize } from 'serializr'
import * as DataStoreServiceWorker from '../../shared/DataStoreService.worker'

export class RecipeCategoriesStore extends DataStore<RootStore, RecipeCategory, IRecipeCategoryDTO> {
  constructor(rootStore: RootStore) {
    super(rootStore, RecipeCategory, AgentV2, 'RecipeCategories', 'RecipeCategory')
    super.setRecords(this.persistedRecords)
    makeObservable(this)
    makePersistable(this, { name: 'RecipeCategoriesStore', properties: ['persistedRecords'] }).then(
      action((st) => this.onHydrationCompleted(st?.isHydrated))
    )
  }

  @action
  public onHydrationCompleted(isHydated?: boolean) {
    this.persistedRecords = observable.array(
      this.persistedRecords.map((e) => makeObservable(deserialize(RecipeCategory, e)))
    )
    this.setRecords(this.persistedRecords)
    this.isHydrated = Boolean(isHydated)
    if (process.env.NODE_ENV === 'test') this.isHydrated = true
    this.listVM = new RecipeCategoriesListVM(this.rootStore)
  }

  @observable.shallow public persistedRecords: Array<RecipeCategory> = []
  @observable public editVM: RecipeCategoryEditVM = null
  @observable public listVM: RecipeCategoriesListVM = null
  @observable public isHydrated: boolean = false

  @computed
  public get currentBoardRecords() {
    return this.persistedRecords.filter((e) => e.BoardId === this.rootStore.boardsStore.currentBoardId)
  }

  @action
  public lazyLoadEditVM(guid: string, attempts: number = 0) {
    if (attempts === 10) return
    if (guid === 'new') {
      this.editVM = new RecipeCategoryEditVM(
        this.rootStore,
        RecipeCategory.create(this.rootStore.boardsStore.currentBoardId)
      )
    } else if (guid === 'empty') {
      this.editVM = null
    } else {
      const foundCat = this.get(guid)
      if (!foundCat) setTimeout(() => this.lazyLoadEditVM(guid, attempts++), 500)
      else this.editVM = new RecipeCategoryEditVM(this.rootStore, foundCat.clone())
    }
  }

  protected getWorker() {
    return (DataStoreServiceWorker as any)() as typeof DataStoreServiceWorker
  }
}
