import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import RecipeCategoryRow from './RecipeCategoryRow'
import { RecipeCategoriesStore } from '../store/RecipeCategoriesStore'
import { AppStore } from 'src/stores/AppStore'
import { IonList, IonItem, IonLabel, IonInput, IonButton } from '@ionic/react'

interface Props {
  recipeCategoriesStore?: RecipeCategoriesStore
  appStore?: AppStore
}

const RecipeCategoryEditForm: React.FC<Props> = ({ recipeCategoriesStore, appStore }) => {
  const { editVM } = recipeCategoriesStore
  if (!editVM) return null
  if (appStore.bodyHeight === 0) return null

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        editVM.save()
      }}
    >
      <IonList>
        <IonItem lines={'inset'}>
          <IonLabel position='floating'>Name</IonLabel>
          <IonInput
            type='text'
            value={editVM.name}
            onIonChange={(e: any) => {
              editVM.setName(e.target.value)
            }}
          />
        </IonItem>
      </IonList>
    </form>
  )
}

export default inject('recipeCategoriesStore', 'appStore')(observer(RecipeCategoryEditForm))
