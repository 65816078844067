import React, { useEffect } from 'react'
import {
  IonTitle,
  IonToolbar,
  IonHeader,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent,
  IonFab,
  IonFabButton,
} from '@ionic/react'
import { inject, Observer } from 'mobx-react'
import ItemsList from './ItemsList'
import { checkmarkSharp, addSharp, arrowUpSharp } from 'ionicons/icons'
import { AntDesign } from 'react-web-vector-icons'
import { AppStore } from 'src/stores/AppStore'
import { EvilIcons } from 'react-web-vector-icons'
import BackIcon from 'src/shared/BackIcon'
import { ItemsStore } from '../../store/ItemsStore'
import ItemNewModal from '../new/ItemNewModal'
import { faAngleDoubleDown, faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface Props {
  itemsStore?: ItemsStore
  appStore?: AppStore
}

const ItemsSelectPage: React.FC<Props> = ({ itemsStore, appStore }) => {
  useEffect(() => {
    appStore.setCurrentRoute('/itemsselect')
    // setTimeout(() => itemsStore.listVM.checkForNoItems(), 200)
  })

  return (
    <Observer>
      {() => {
        const vm = itemsStore.listVM
        if (!vm) return null

        const renderFabIcon = () => {
          return (
            <Observer>
              {() => {
                if (!vm.hasRows) return <IonIcon icon={addSharp} />
                return <IonIcon icon={checkmarkSharp} />
              }}
            </Observer>
          )
        }

        const renderNewModal = () => {
          if (!vm.itemNewVM) return
          return <ItemNewModal vm={vm.itemNewVM} />
        }

        const renderToTopFab = () => {
          if (vm.atTop) return
          return (
            <IonFab vertical='bottom' horizontal='end' id='ToTopFab'>
              <IonFabButton
                color='light'
                onClick={() => vm.scrollToTop()}
                style={{
                  marginTop: '-120px',
                  marginLeft: '-50px',
                }}
              >
                <IonIcon icon={arrowUpSharp} />
              </IonFabButton>
            </IonFab>
          )
        }

        const renderExpandAll = () => {
          if (!vm.startCollapsed) return
          return (
            <IonButton onClick={(e) => vm.expandCollapseAll()}>
              <FontAwesomeIcon icon={faAngleDoubleDown} />
            </IonButton>
          )
        }

        const renderCollapseAll = () => {
          if (vm.startCollapsed) return
          return (
            <IonButton onClick={(e) => vm.expandCollapseAll()}>
              <FontAwesomeIcon icon={faAngleDoubleUp} />
            </IonButton>
          )
        }

        return (
          <>
            {appStore.showAppHeader && (
              <IonHeader>
                <IonToolbar />
              </IonHeader>
            )}
            <IonHeader id='ItemsSelectPage_Header'>
              <IonToolbar color='secondary'>
                <IonButtons slot='start'>
                  <IonButton onClick={() => vm.goBack()}>
                    <BackIcon />
                  </IonButton>
                </IonButtons>
                <IonTitle>Add Items</IonTitle>
                <IonButtons slot='end'>
                  {renderCollapseAll()}
                  {renderExpandAll()}
                  <IonButton onClick={() => vm.goToCategoriesSetup()}>
                    <EvilIcons name='gear' size={20} />
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>

            <IonToolbar id='ItemsSelect_Toolbar' color='secondary'>
              <input
                type='text'
                className='ion-input-md'
                placeholder={vm.searchPromptText}
                value={vm.typedFilter}
                onChange={(e: any) => vm.setFilter(e.target.value)}
                ref={(e) => vm.setSearchTextbox(e)}
              />

              {vm.hasFilter && (
                <div slot='end' onClick={() => vm.clearFilter()}>
                  <AntDesign name='closecircleo' size={18} />
                </div>
              )}
            </IonToolbar>
            <IonContent id='ItemSelectPage_Content' scrollY={false} style={{ height: appStore.listHeightPx }}>
              <ItemsList itemsList={vm} />
              {renderNewModal()}
            </IonContent>
            {/* {renderToTopFab()} */}
            <IonFab vertical='bottom' horizontal='end'>
              <IonFabButton
                color='tertiary'
                onClick={() => vm.done()}
                style={{
                  marginTop: '-60px',
                  marginLeft: '-60px',
                }}
              >
                {renderFabIcon()}
              </IonFabButton>
            </IonFab>
          </>
        )
      }}
    </Observer>
  )
}

export default inject('itemsStore', 'appStore')(ItemsSelectPage)
