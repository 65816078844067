import { makeObservable, observable } from 'mobx'
import { serializable } from 'serializr'

export default class SubscribedTopic {

  static create(path) {
    const topic = new SubscribedTopic()
    topic.path = path
    return topic
  }

  constructor() {
    makeObservable(this)
  }

  @serializable @observable path: string = ''

}