import { observable, makeObservable } from 'mobx'
import { IBoardInvitationDTO } from '../dtos/IBoardInvitationDTO'
import { serializable } from 'serializr'

export class BoardInvitation implements IBoardInvitationDTO {
  constructor() {
    makeObservable(this)
  }

  @serializable @observable BoardInvitationGuid: string = ''
  @serializable @observable ToEmailAddress: string = ''
  @serializable @observable StatusId: Number = 0
  @serializable @observable FromAppUserId: Number = 0
}
