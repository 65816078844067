import { RootStore } from 'src/stores/RootStore'
import { computed, observable, action, makeObservable } from 'mobx'
import { MealEditVM } from './MealEditVM'
import { MealItem } from 'src/meals/aggregate/MealItem'
import { ListItemsListVM } from 'src/list-items/view-models/ListItemsListVM'
import { RecipeItem } from 'src/recipes/aggregate/RecipeItem'
import { MealRecipeCardVM } from './MealRecipeCardVM'

export class RecipeItemRowVM {
  private rootStore: RootStore
  private recipeItem: RecipeItem
  private mealRecipeCardVM: MealRecipeCardVM

  constructor(rootStore: RootStore, editVM: MealEditVM, mealRecipeCardVM: MealRecipeCardVM, recipeItem: RecipeItem) {
    makeObservable(this)
    this.rootStore = rootStore
    this.recipeItem = recipeItem
    this.mealRecipeCardVM = mealRecipeCardVM
  }

  @observable public inConfirmation: boolean = false

  @computed
  public get listVM(): ListItemsListVM {
    return this.rootStore.listItemsStore.listVM
  }

  @computed
  public get quantity(): number {
    return this.listVM.getItemQuantity(this.itemGuid)
  }

  @computed
  public get hasQuantity(): boolean {
    return this.quantity !== 0
  }

  @computed
  public get isGotten(): boolean {
    return this.listVM.isGotten(this.itemGuid)
  }

  @computed
  public get isCleared(): boolean {
    return this.listVM.isCleared(this.itemGuid)
  }

  @action
  public toggle() {
    if (!this.hasItem) {
      this.showEditModal(true)
      return
    }
    if (!this.hasQuantity) this.increaseQuantity()
    else if (this.hasQuantity && !this.isGotten) this.listVM.toggleGotten(this.itemGuid)
    else if (this.hasQuantity && this.isGotten) this.listVM.markAsCleared(this.itemGuid)
    else this.listVM.removeQuantity(this.itemGuid)
  }

  @action
  public increaseQuantity() {
    this.listVM.increaseQuantity(this.itemGuid)
  }

  @action
  public decreaseQuantity() {
    this.listVM.decreaseQuantity(this.itemGuid)
  }

  @action
  public removeQuantity() {
    this.listVM.removeQuantity(this.itemGuid)
  }

  @computed
  public get description(): string {
    return this.recipeItem.Description
  }

  @computed
  public get itemName(): string {
    if (!this.rootStore.itemsStore) return ''
    const foundItem = this.rootStore.itemsStore.get(this.recipeItem.ItemGuid)
    if (!foundItem) return ''
    return foundItem.Name
  }

  @computed
  public get categoryColor(): string {
    if (!this.rootStore.categoriesStore) return ''
    const foundItem = this.rootStore.itemsStore.get(this.itemGuid)
    if (!foundItem) return ''
    const foundCat = this.rootStore.categoriesStore.get(foundItem.CategoryGuid)
    if (!foundCat) return ''
    return foundCat.Color
  }

  @computed
  public get categoryName(): string {
    if (!this.rootStore.categoriesStore) return ''
    const foundItem = this.rootStore.itemsStore.get(this.itemGuid)
    if (!foundItem) return ''
    const foundCat = this.rootStore.categoriesStore.get(foundItem.CategoryGuid)
    if (!foundCat) return ''
    return foundCat.Name
  }

  @computed
  public get sortName(): string {
    return this.categoryName + this.itemName
  }

  @computed
  public get hasItem(): boolean {
    return this.recipeItem.hasItem
  }

  @computed
  public get recipeItemGuid(): string {
    return this.recipeItem.RecipeItemGuid
  }

  @computed
  public get iconName(): string {
    if (!this.hasItem) return 'link'
    if (this.isGotten) return 'checkmark'
    if (this.hasQuantity && !this.isGotten && !this.isCleared) return 'cart'
    return 'add'
  }

  @action
  public goToEditPage() {
    this.rootStore.appStore.history.push('/itemedit/' + this.itemGuid)
  }

  @computed
  public get itemGuid(): string {
    return this.recipeItem.ItemGuid
  }

  @computed
  public get rank(): number {
    return this.recipeItem.Rank
  }

  @action
  public delete() {
    this.recipeItem.markAsDeleted()
  }

  @action
  public markForConfirmation(): void {
    this.inConfirmation = true
  }

  @action
  public doneConfirmation(): void {
    this.inConfirmation = false
  }

  @computed
  public get recipeGuid(): string {
    return this.mealRecipeCardVM.recipeGuid
  }

  @action
  public showEditModal(openItemSelect: boolean = false) {
    const editVM = this.rootStore.recipesStore.loadEditVM(this.recipeGuid)
    editVM.setEditingRecipeItem(this.recipeItemGuid)
    if (openItemSelect) {
      setTimeout(() => editVM.editingRecipeItem.openItemSelect(), 10)
    }
    editVM.enableAutoSave()
  }
}
