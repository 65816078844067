import React from 'react'
import { observer } from 'mobx-react'
import { IonCheckbox, IonIcon } from '@ionic/react'
import { add } from 'ionicons/icons'
import { RecipeNewRowVM } from 'src/meals/view-models/edit/RecipeNewRowVM'

interface Props {
  row: RecipeNewRowVM
  style: any
}

const RecipeNewRow: React.FC<Props> = ({ row, style }) => {
  return (
    <div style={style} id='RecipeNewRow' onClick={() => row.click()}>
      <div className='btn'>
        <IonIcon icon={add} size='small' />
        <div>{row.displayName}</div>
      </div>
      <div className='filler' />
    </div>
  )
}

export default observer(RecipeNewRow)
