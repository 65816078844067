import React from 'react'
import {
  IonList,
  IonItem,
  IonButton,
  IonModal,
  IonLabel,
  IonInput,
  IonTitle,
  IonContent,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonFab,
  IonFabButton,
  IonSelectOption,
  IonSelect,
  IonGrid,
  IonRow,
  IonCol,
  IonRippleEffect,
  IonCheckbox,
} from '@ionic/react'
import { observer } from 'mobx-react'
import { add, remove, close, checkmarkSharp } from 'ionicons/icons'
import CategoryNewModal from '../../../items-select/views/CategoryNewModal'
import BackIcon from 'src/shared/BackIcon'
import { Feather } from 'react-web-vector-icons'
import { ItemNewVM } from '../../view-models/new/ItemNewVM'

interface Props {
  vm: ItemNewVM
}

const ItemNewModal: React.FC<Props> = ({ vm }) => {
  const handleSave = e => {
    e.preventDefault()
    vm.save()
  }

  const renderCategory = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Category</IonLabel>
        <IonSelect
          interface='popover'
          value={vm.categoryGuid}
          onIonChange={(e: any) => vm.setCategory(e.target.value)}
        >
          {vm.categories.map(e => (
            <IonSelectOption
              key={e.CategoryGuid}
              value={e.CategoryGuid}
              // defaultValue={e.CategoryGuid === itemNewVM.categoryGuid}
            >
              {e.Name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    )
  }
  
  const renderName = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Name</IonLabel>
        <IonInput
          type='text'
          autocapitalize='word'
          autofocus
          value={vm.name}
          onIonChange={(e: any) => vm.setName(e.target.value)}
          ref={(e) => vm.disableAutoComplete(e)}
        />
      </IonItem>
    )
  }

  const renderIcon = () => {
    if (!vm.doAdd) return
    return (
      <div
        className='icon-container checked'
        onClick={e => {
          e.stopPropagation()
          vm.toggleDoAddItem()
        }}
      >
        <Feather name='check-circle' size={22} />
      </div>
    )
  }

  const renderCheckedIcon = () => {
    if (vm.doAdd) return
    return (
      <div
        className='icon-container'
        onClick={e => {
          e.stopPropagation()
          vm.toggleDoAddItem()
        }}
      >
        <Feather name='circle' size={22} />
      </div>
    )
  }

  const renderCheckbox = () => {
    return <IonCheckbox mode='ios' checked={vm.doAdd} color='light' />
  }

  const renderAddToList = () => {
    if (!vm.addToShown) return
    return (
      <IonGrid>
        <IonRow>
          <IonCol sizeXs='8'>
            <IonItem lines={'inset'} className='add-section' onClick={() => vm.toggleDoAddItem()}>
              {renderCheckbox()}
              {renderIcon()}
              {renderCheckedIcon()}
              <IonLabel>Add to {vm.newItemTypeName}</IonLabel>
            </IonItem>
          </IonCol>
          <IonCol sizeXs='4'>
            <div className='qty-section'>
              {vm.doAdd && (
                <>
                  <div
                    className='ion-activatable qty-button minus-button'
                    hidden={!vm.hasQuantity}
                    onClick={e => {
                      e.stopPropagation()
                      vm.decreaseQuantity()
                    }}
                  >
                    <IonIcon icon={remove} color='tertiary' />
                    <IonRippleEffect type='unbounded' />
                  </div>
                  <div className='qty' hidden={!vm.hasQuantity}>
                    <IonLabel>{vm.quantity}</IonLabel>
                  </div>
                  <div
                    className='ion-activatable qty-button plus-button'
                    onClick={e => {
                      e.stopPropagation()
                      vm.increaseQuantity()
                    }}
                  >
                    <IonIcon icon={add} color='tertiary' />
                    <IonRippleEffect type='unbounded' />
                  </div>
                </>
              )}
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    )
  }

  return (
    <>
      <IonModal
        id='ItemNewModal'
        isOpen={vm.shown}
        showBackdrop={vm.backdropShown}
        onDidDismiss={() => vm.hide()}
      >
        <IonHeader id='ItemNewModal_Header'>
          <IonToolbar color='secondary'>
            <IonButtons slot='start'>
              <IonButton onClick={() => vm.cancel()}>
                <BackIcon />
              </IonButton>
            </IonButtons>
            <IonTitle>New Item</IonTitle>
            <IonButtons slot='end'>
              <IonButton onClick={() => vm.cancel()}>
                <IonIcon icon={close} />
              </IonButton>
              <IonButton onClick={() => vm.save()} disabled={!vm.isValid}>
                <IonIcon icon={checkmarkSharp} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <form onSubmit={e => handleSave(e)}>
            <IonList>
              {renderName()}
              {renderCategory()}
              {renderAddToList()}
            </IonList>
          </form>
        </IonContent>
        <IonFab vertical='bottom' horizontal='end'>
          <IonFabButton
            disabled={!vm.isValid}
            color='tertiary'
            onClick={() => vm.save()}
            style={{
              marginTop: '-60px',
              marginLeft: '-60px',
            }}
          >
            <IonIcon icon={checkmarkSharp} />
          </IonFabButton>
        </IonFab>
      </IonModal>
      <CategoryNewModal categoryNewVM={vm.categoryNewVM} />
    </>
  )
}

export default observer(ItemNewModal)
