import React from 'react'
import { IonTitle, IonPopover, IonList, IonItem } from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { EvilIcons, MaterialCommunityIcons, Entypo } from 'react-web-vector-icons'
import BoardInvitationAcceptModal from 'src/board-invitations/views/BoardInvitationAcceptModal'
import { BoardsStore } from '../store/BoardsStore'
import BoardInvitationRow from './BoardInvitationRow'

interface Props {
  boardsStore?: BoardsStore
}

const BoardsMenu: React.FC<Props> = ({ boardsStore }) => {
  const { boardsMenuVM: vm } = boardsStore
  if (!vm) return null

  const handleAddBoardClick = () => {
    vm.startNewBoard()
  }

  const handleMenuButtonClick = (event) => {
    event.persist()
    vm.open(event)
  }

  const handleBoardClick = (brd) => {
    boardsStore.setCurrentBoard(brd.BoardId)
    vm.close()
  }

  const renderBoardOptions = () => {
    return vm.boards.map((brd, idx) => (
      <IonItem lines='full' onClick={() => handleBoardClick(brd)} key={'brd' + idx}>
        <MaterialCommunityIcons name='bulletin-board' size={16} />
        &nbsp; {brd.Name}
      </IonItem>
    ))
  }

  const renderBoardInvitations = () => {
    return vm.boardInvitations.map((row) => <BoardInvitationRow row={row} key={row.key} />)
  }

  const renderAddBoard = () => {
    return (
      <IonItem lines='full' onClick={() => handleAddBoardClick()}>
        <MaterialCommunityIcons name='plus' size={16} />
        &nbsp; New Board
      </IonItem>
    )
  }

  const renderJoinBoard = () => {
    return (
      <IonItem lines='full' id='JoinBoardRow' onClick={() => vm.openJoinBoardModal()}>
        <Entypo name='shareable' size={14} />
        &nbsp; Join Another Board
      </IonItem>
    )
  }

  const renderInvitionsCountForNonIos = () => {
    if (vm.isIos) return
    if (vm.pendingBoardInvitations === 0) return
    return <div className='items-count'>{vm.pendingBoardInvitations}</div>
  }

  const renderInvitionsCountForIos = () => {
    if (!vm.isIos) return
    if (vm.pendingBoardInvitations === 0) return
    return <div className='items-count'>{vm.pendingBoardInvitations}</div>
  }

  const renderTitleForIos = () => {
    if (!vm.isIos) return
    return (
      <IonTitle unselectable='on'>
        {vm.currentBoardName}
        <EvilIcons name='chevron-down' color='white' size={18} />
        {renderInvitionsCountForIos()}
      </IonTitle>
    )
  }

  const renderTitleForNormal = () => {
    if (vm.isIos) return
    return (
      <div unselectable='on' className='normal'>
        <IonTitle>{vm.currentBoardName}</IonTitle>
        <EvilIcons name='chevron-down' color='white' size={18} />
        {renderInvitionsCountForIos()}
      </div>
    )
  }

  return (
    <div id='BoardsMenu'>
      <div onClick={(e) => handleMenuButtonClick(e)} className='title-menu'>
        {renderTitleForNormal()}
        {renderTitleForIos()}
        {renderInvitionsCountForNonIos()}
      </div>

      <IonPopover id='BoardsMenu_Content' isOpen={vm.isMenuOpen} event={event} onDidDismiss={() => vm.close()}>
        <IonList>
          {renderBoardOptions()}
          {renderBoardInvitations()}
          {renderAddBoard()}
          {renderJoinBoard()}
        </IonList>
      </IonPopover>

      <BoardInvitationAcceptModal />
    </div>
  )
}

export default inject('boardsStore')(observer(BoardsMenu))
