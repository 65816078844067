import React from 'react'
import { IonContent, IonPage } from '@ionic/react'
import { inject } from 'mobx-react'
import { AppStore } from 'src/stores/AppStore'
import { AuthStore } from 'src/login/store/AuthStore'
import '../styles/LoginPage.css'
import InputForm from './LoginForm'
import Logo512 from '../../assets/images/Logo_512.png'
import { Capacitor } from '@capacitor/core'
import { SplashScreen } from '@capacitor/splash-screen'

interface Props {
  appStore: AppStore
  authStore: AuthStore
  history: any
}

@inject('appStore')
@inject('authStore')
export default class LoginPage extends React.Component<Props, any> {
  componentDidMount() {
    if (Capacitor.getPlatform() !== 'web') SplashScreen.hide()
    this.props.appStore.setCurrentRoute('/login')
    if (this.props.appStore.isLoggedIn) {
      this.props.history.push('/')
    }
    this.props.appStore.themeGenVM.loadTheme('default')
  }

  render() {
    return (
      <IonPage id='LoginPage'>
        <IonContent>
          <div id='LogoContainer'>
            <img src={Logo512} alt='Grocery Board' />
          </div>
          <InputForm />
        </IonContent>
      </IonPage>
    )
  }
}
