import React from 'react'
import { inject, Observer, observer } from 'mobx-react'
import { AppStore } from 'src/stores/AppStore'
import ItemRow from './ItemRow'
import ItemGroupRow from './ItemGroupRow'
import { List } from '@elexient/elexiapp.bits.shared'
import { ItemsListVM } from '../../view-models/list/ItemsListVM'
import { ItemRowVM } from '../../view-models/list/ItemRowVM'
import { ItemGroupRowVM } from '../../view-models/list/ItemGroupRowVM'
import { ItemNewRowVM } from '../../view-models/list/ItemNewRowVM'
import ItemNewRow from './ItemNewRow'

interface Props {
  itemsList: ItemsListVM
  appStore?: AppStore
}

const ItemsList: React.FC<Props> = ({ itemsList, appStore }) => {
  const listVM = itemsList

  const renderNormalRow = (row: ItemRowVM, index, style) => {
    return (
      <Observer key={row.key + String(index) + row.rowType + row.name}>
        {() => <ItemRow key={row.key} row={row} index={index} style={style} />}
      </Observer>
    )
  }

  const renderGroupRow = (row: ItemGroupRowVM, index, style) => {
    return <ItemGroupRow key={'grp' + index} row={row} index={index} style={style} />
  }

  const renderNewRow = (row: ItemNewRowVM, index, style) => {
    return (
      <Observer key={row.key + String(index) + row.rowType + row.name}>
        {() => <ItemNewRow key={'new' + index} row={row} index={index} style={style} />}
      </Observer>
    )
  }

  return (
    <List
      listVM={listVM}
      normalRow={(row, index, style) => renderNormalRow(row, index, style)}
      groupRow={(row, index, style) => renderGroupRow(row, index, style)}
      newRow={(row, index, style) => renderNewRow(row, index, style)}
      onRef={(e) => appStore.applyScrollStyles(e)}
    />
  )
}

export default inject('appStore')(observer(ItemsList))
