import React, { useState } from 'react'
import { inject, observer } from 'mobx-react'
import RecipeCategoryRow from './RecipeCategoryRow'
import { RecipeCategoriesStore } from '../store/RecipeCategoriesStore'
import { AppStore } from 'src/stores/AppStore'
import { IonList } from '@ionic/react'

interface Props {
  recipeCategoriesStore?: RecipeCategoriesStore
  appStore?: AppStore
}

const RecipeCategoriesList: React.FC<Props> = ({ recipeCategoriesStore, appStore }) => {
  const { listVM } = recipeCategoriesStore
  if (!listVM) return null
  if (appStore.bodyHeight === 0) return null

  const renderListRows = () => {
    return listVM.rows.map((e, idx) => <RecipeCategoryRow key={'rr' + idx} row={e} />)
  }

  return <IonList>{renderListRows()}</IonList>
}

export default inject('recipeCategoriesStore', 'appStore')(observer(RecipeCategoriesList))
