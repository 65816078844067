import React from 'react'
import {
  IonContent,
  IonIcon,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonAlert,
  IonFabButton,
  IonFab,
} from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { AppStore } from 'src/stores/AppStore'
import { trash, checkmark, arrowBack, trashOutline, checkmarkSharp } from 'ionicons/icons'
import { RootStore } from 'src/stores/RootStore'
import { RecipeCategoriesStore } from '../store/RecipeCategoriesStore'
import { match } from 'react-router'
import RecipeCategoryEditForm from './RecipeCategoryEditForm'
import BackIcon from 'src/shared/BackIcon'

interface Props {
  recipeCategoriesStore: RecipeCategoriesStore
  appStore: AppStore
  rootStore: RootStore
  history: any
  match: match
}

@inject('recipeCategoriesStore')
@inject('appStore')
@inject('rootStore')
@observer
export default class RecipeCategoryEditPage extends React.Component<Props> {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.appStore.setCurrentRoute('/recipecategoryedit/' + this.props.match.params['id'])
    this.props.recipeCategoriesStore.lazyLoadEditVM(this.props.match.params['id'])
  }

  componentWillUnmount() {
    this.props.recipeCategoriesStore.lazyLoadEditVM('empty')
  }

  handleGoBack() {
    this.props.appStore.handleGoBack(this.props.history)
  }

  renderDeleteConfirm() {
    const { editVM } = this.props.recipeCategoriesStore
    if (!editVM) return null
    return (
      <IonAlert
        isOpen={editVM.deleteConfirmShown}
        onDidDismiss={() => editVM.hideDeleteConfirm()}
        header={'Delete Recipe Category'}
        message={'Are you sure you wish to delete ' + editVM.name}
        buttons={[
          {
            text: 'No',
            role: 'cancel',
            cssClass: 'primary',
            handler: () => editVM.hideDeleteConfirm(),
          },
          {
            text: 'Yes',
            handler: () => editVM.delete(),
          },
        ]}
      />
    )
  }

  render() {
    const { editVM } = this.props.recipeCategoriesStore
    if (!editVM) return null

    return (
      <>
        {this.props.appStore.showAppHeader && (
          <IonHeader>
            <IonToolbar />
          </IonHeader>
        )}

        <IonHeader id='RecipeCategoryEditPage_Header'>
          <IonToolbar color='secondary'>
            <IonButtons slot='start'>
              <IonButton onClick={this.handleGoBack.bind(this)}>
                <BackIcon />
              </IonButton>
            </IonButtons>

            <IonTitle>{editVM.pageTitle}</IonTitle>
            <IonButtons slot='end'>
              {!editVM.isNew && (
                <IonButton onClick={() => editVM.showDeleteConfirm()}>
                  <IonIcon icon={trashOutline} />
                </IonButton>
              )}
              <IonButton disabled={!editVM.isValid} onClick={() => editVM.save()}>
                <IonIcon icon={checkmarkSharp} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent id='RecipeCategoryEditPage_Content'>
          <RecipeCategoryEditForm />
        </IonContent>

        <IonFab vertical='bottom' horizontal='end'>
          <IonFabButton
            color='tertiary'
            onClick={() => editVM.save()}
            disabled={!editVM.isValid}
            style={{
              marginTop: '-60px',
              marginLeft: '-60px',
            }}
          >
            <IonIcon icon={checkmarkSharp} />
          </IonFabButton>
        </IonFab>

        {this.renderDeleteConfirm()}
      </>
    )
  }
}
