import { RootStore } from 'src/stores/RootStore'
import { computed, observable, autorun, action, reaction, makeObservable } from 'mobx'
import { RecipeCategory } from '../aggregate/RecipeCategory'
import { RecipeCategoriesService } from '../services/RecipeCategoriesService'

export class RecipeCategoryEditVM {
  private rootStore: RootStore

  constructor(rootStore: RootStore, recipeCategory: RecipeCategory) {
    makeObservable(this)
    this.rootStore = rootStore
    this.recipeCategory = recipeCategory
  }

  @observable public recipeCategory: RecipeCategory = null
  @observable public deleteConfirmShown: boolean = false

  @computed
  public get isNew(): boolean {
    return this.recipeCategory.isNew
  }

  @action
  public setName(val) {
    this.recipeCategory.setName(val)
  }

  @computed
  public get name(): string {
    return this.recipeCategory.Name
  }

  @computed
  public get isValid(): boolean {
    if (!this.name || this.name === '') return false
    return true
  }

  @action
  public save() {
    const svc = new RecipeCategoriesService(this.rootStore)
    svc.save(this.recipeCategory.toDTO())
    this.rootStore.appStore.history.push('/recipecategorieslist')
    this.recipeCategory = RecipeCategory.create(this.rootStore.boardsStore.currentBoardId)
  }

  @action
  public showDeleteConfirm() {
    this.deleteConfirmShown = true
  }

  @computed
  public get pageTitle(): string {
    return this.recipeCategory.isNew ? 'New Recipe Category' : this.recipeCategory.Name
  }

  @action
  public hideDeleteConfirm() {
    this.deleteConfirmShown = false
  }

  public delete() {
    const svc = new RecipeCategoriesService(this.rootStore)
    svc.delete(this.recipeCategory.toDTO())
    this.rootStore.appStore.handleGoBack()
  }
}
