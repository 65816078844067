import React from 'react'
import { IonContent, IonButton, IonHeader, IonToolbar, IonTitle, IonButtons } from '@ionic/react'
import { observer, inject } from 'mobx-react'
import { AppStore } from 'src/stores/AppStore'
import BackIcon from 'src/shared/BackIcon'
import ThemesList from './ThemesList'

interface Props {
  appStore: AppStore
  history: any
}

@inject('appStore')
@observer
export default class ThemesListPage extends React.Component<Props> {
  componentDidMount() {
    this.props.appStore.setCurrentRoute('/themeslist')
  }

  handleGoBack() {
    this.props.appStore.handleGoBack(this.props.history)
  }

  render() {
    return (
      <>
        {this.props.appStore.showAppHeader && (
          <IonHeader>
            <IonToolbar />
          </IonHeader>
        )}

        <IonHeader id='ThemesListPage_Header'>
          <IonToolbar color='secondary'>
            <IonButtons slot='start'>
              <IonButton onClick={() => this.handleGoBack()}>
                <BackIcon />
              </IonButton>
            </IonButtons>

            <IonTitle>Select Theme</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent
          id='ThemesListPage_Content'
          ref={e => this.props.appStore.applyScrollStyles(e)}
          scrollY
          style={{ height: this.props.appStore.listHeightPx }}
        >
          <ThemesList />
        </IonContent>
      </>
    )
  }
}
