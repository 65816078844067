import React from 'react'
import { observer } from 'mobx-react'
import { RecipeCategoryRowVM } from '../view-models/RecipeCategoryRowVM'
import { IonItem, IonCardContent, IonCard } from '@ionic/react'

interface Props {
  row: RecipeCategoryRowVM
}

const RecipeCategoryRow: React.FC<Props> = ({ row }) => {
  return (
    <IonCard onClick={() => row.rowClick()} id='RecipeCategoryRow'>
      <IonCardContent>{row.Name}</IonCardContent>
    </IonCard>
  )
}

export default observer(RecipeCategoryRow)
