import { observable, action, computed, makeObservable } from 'mobx'
import { RootStore } from 'src/stores/RootStore'
import agent from '../../Agent'

export class ForgotPasswordVM {
  constructor(rootStore: RootStore) {
    makeObservable(this)
    this.rootStore = rootStore
  }

  rootStore: RootStore

  @observable email: string = ''
  @observable errorMessages: Array<string> = []
  @observable public success: boolean = false
  @observable public isProcessing: boolean = false

  @computed
  public get isIos(): boolean {
    return this.rootStore.appStore.isIos
  }

  @computed public get isValid() {
    if (this.email === '') return false
    return true
  }

  @action
  public setEmail(val) {
    this.email = val
  }

  @action
  public async save() {
    this.success = false
    this.isProcessing = true
    const email = document.getElementById('email2').getElementsByTagName('input')[0].value
    this.setEmail(email)
    this.errorMessages = []
    const form = {
      Email: this.email,
    }
    const success = await this.saveOnServer(form)
    this.isProcessing = false
    if (success) {
      this.success = true
      setTimeout(() => {
        this.rootStore.appStore.navigateTo('/login')
        this.success = false
      }, 2000)
    }
  }

  private async saveOnServer(form) {
    try {
      const results: Array<string> = await agent.Users.forgotPassword(form)
      if (results[0] === 'Success' && results.length === 1) {
        return true
      } else {
        results.forEach(e => {
          this.errorMessages.push(e)
        })
      }
    } catch (e) {
      console.error(e)
    }
    return false
  }
}
