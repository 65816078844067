import { observable, action, computed, makeObservable } from 'mobx'
import { RootStore } from '../../stores/RootStore'
import { Board } from '../aggregate/Board'
import { BoardsService } from '../service/BoardsService'

export class BoardNewModalVM {
  private rootStore: RootStore
  private nameInputRef: HTMLIonInputElement
  private focused: boolean

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeObservable(this)
  }

  @observable public isOpen: boolean = false
  @observable private board: Board

  @computed
  public get name(): string {
    return this.board.Name
  }

  @action
  public setName(val) {
    this.board.setName(val)
  }

  public setNameInputRef(e: HTMLIonInputElement): void {
    this.nameInputRef = e
    if (this.focused) return
    setTimeout(() => e.setFocus(), 1000)
    this.focused = true
  }

  @action
  public open() {
    this.isOpen = true
    this.board = Board.create()
    this.focused = false
  }

  @action
  public close() {
    this.isOpen = false
  }

  @computed
  public get isValid(): boolean {
    if (!this.board.Name || this.board.Name === '') return false
    return true
  }

  @action
  public save() {
    const svc = new BoardsService(this.rootStore)
    svc.saveBoard(this.board.toDTO())
    this.close()
    this.rootStore.boardsStore.setCurrentBoardFromNewBoardGuid(this.board.BoardGuid)
    this.rootStore.appStore.navigateTo('/boardsettings')
  }
}
