import React from 'react'
import { IonTitle, IonButton, IonSpinner, IonText } from '@ionic/react'
import { inject, observer } from 'mobx-react'
import { AuthStore } from 'src/login/store/AuthStore'
import GoogleSmallLogo from '../../assets/images/Google_Logo_400.png'
import AppleSmallLogo from '../../assets/images/Apple_Logo_400.png'
import '../styles/WelcomeHeader.scss'

interface Props {
  authStore?: AuthStore
}

const WelcomeHeader: React.FC<Props> = ({ authStore }) => {
  const { registerVM: vm } = authStore
  if (!vm) return null

  const renderGoogleHeader = () => {
    if (!vm.hasGoogleAccount) return
    if (vm.isCheckingGoogleLogin) return
    return (
      <>
        <IonText className='looks-new'>Welcome! Let's get you set up.</IonText>
        <br/>
        <IonText className='for-google'>
          <img src={GoogleSmallLogo} alt='Google' />
          Connected to: <span>{vm.googleLoginEmail}</span>
        </IonText>
      </>
    )
  }

  const renderGoogleSpinner = () => {
    if (!vm.isCheckingGoogleLogin) return
    if (vm.isIos) {
      return (
        <div className='spinner'>
          <IonSpinner name='crescent' />
        </div>
      )
    }
    return (
      <div className='spinner'>
        <IonSpinner name='crescent' />
      </div>
    )
  }

  const renderAppleHeader = () => {
    if (!vm.hasAppleAccount) return
    if (vm.isCheckingAppleLogin) return
    return (
      <>
        <IonText className='looks-new'>Welcome! Let's get you set up.</IonText>
        <br/>
        <IonText className='for-apple'>
          <img src={AppleSmallLogo} alt='Apple' />
          Connected to: <span>{vm.appleLoginEmail}</span>
        </IonText>
      </>
    )
  }

  const renderAppleSpinner = () => {
    if (!vm.isCheckingAppleLogin) return
    if (vm.isIos) {
      return (
        <div className='spinner'>
          <IonSpinner name='crescent' />
        </div>
      )
    }
    return (
      <div className='spinner'>
        <IonSpinner name='crescent' />
      </div>
    )
  }

  const renderDefaultHeader = () => {
    if (vm.hasExternalAccount) return
    return (
      <>
        <IonText className='looks-new'>Welcome! Let's get you set up.</IonText>
      </>
    )
  }

  return (
    <div id='WelcomeHeader'>
      {renderDefaultHeader()}
      {renderGoogleHeader()}
      {renderGoogleSpinner()}
      {renderAppleHeader()}
      {renderAppleSpinner()}
    </div>
  )
}

export default inject('authStore')(observer(WelcomeHeader))
