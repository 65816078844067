import { action, computed } from 'mobx'
import { NewRowVM } from '@elexient/elexiapp.bits.shared'
import { RecipesListVM } from './RecipesListVM'
import { RootStore } from '../../../stores/RootStore'

export class RecipeNewRowVM extends NewRowVM<RootStore, RecipesListVM> {
  
  @action
  public click(): void {
    this.rootStore.appStore.navigateTo('/recipeedit/new/?name=' + escape(this.listVM.filter))
  }

  @computed
  public get displayName(): string {
    if (this.listVM.filter === '') return 'New Recipe'
    return 'New Recipe: ' + this.listVM.filter
  }

  get color(): string {
    return ''
  }
}
