import { RootStore } from '../../stores/RootStore'
import { action, computed, makeObservable, observable } from 'mobx'
import { BoardUser } from '../aggregate/BoardUser'
import { BoardEditVM } from './BoardEditVM'

export class BoardUserRowVM {
  private rootStore: RootStore
  private boardUser: BoardUser
  private parentVM: BoardEditVM

  constructor(rootStore: RootStore, parentVM: BoardEditVM, usr: BoardUser) {
    this.rootStore = rootStore
    this.boardUser = usr
    this.parentVM = parentVM
    makeObservable(this)
  }

  @observable public deleteConfirmShown: boolean

  @computed
  public get key(): string {
    return 'usr' + this.boardUser.BoardUserId
  }

  @computed
  public get name(): string {
    return this.boardUser.AppUser.Name
  }

  @action
  public showDeleteConfirm(): void {
    this.deleteConfirmShown = true
  }

  @action
  public hideDeleteConfirm(): void {
    this.deleteConfirmShown = false
  }

  @action
  public delete(): void {
    this.boardUser.IsDeleted = true
    this.parentVM.save()
  }

  @computed
  public get canDelete(): boolean {
    return this.boardUser.AppUser.AppUserId !== this.rootStore.userStore.user.AppUserId
  }
}
