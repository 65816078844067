import { RootStore } from 'src/stores/RootStore'
import { computed, action, observable, makeObservable } from 'mobx'
import { CategoriesListVM } from './CategoriesListVM'
import { Category } from '../aggregate/Category'

export class CategoryRowVM {
  private rootStore: RootStore
  private category: Category
  private listVM: CategoriesListVM

  constructor(rootStore: RootStore, listVM: CategoriesListVM, category: Category) {
    this.rootStore = rootStore
    this.category = category
    this.listVM = listVM
    makeObservable(this)
  }

  public type: string = 'item'
  @observable public keepUp: boolean = false
  @observable public keepDown: boolean = false

  @computed
  public get name(): string {
    return this.category.Name
  }

  @computed
  public get color(): string {
    return this.category.Color
  }

  @computed
  public get categoryGuid(): string {
    return this.category.CategoryGuid
  }

  @action
  public openCategory() {
    this.rootStore.appStore.history.push('/categoryedit/' + this.categoryGuid)
  }
}
