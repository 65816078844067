import React from 'react'
import { inject, observer } from 'mobx-react'
import { MealsStore } from '../../store/MealsStore'
import {
  IonLabel,
  IonItem,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonGrid,
  IonRow,
  IonCol,
  IonToggle,
} from '@ionic/react'
import { DatePicker, TimePicker } from '@material-ui/pickers'
import PlannedTimeRow from './PlannedTimeRow'

interface Props {
  mealsStore?: MealsStore
}

const DetailsForm: React.FC<Props> = ({ mealsStore }) => {
  const { editVM } = mealsStore
  if (!editVM) return null

  const renderMealCategoryOptions = () => {
    const options = editVM.mealCategories.map(e => (
      <IonSelectOption key={'mc' + e.MealCategoryGuid} value={e.MealCategoryGuid}>
        {e.Name}
      </IonSelectOption>
    ))
    return options
  }

  const renderMealCategoryRow = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Meal</IonLabel>
        <IonSelect
          interface='popover'
          value={editVM.mealCategoryGuid}
          onIonChange={(e: any) => editVM.setMealCategory(e.target.value)}
        >
          {renderMealCategoryOptions()}
        </IonSelect>
      </IonItem>
    )
  }

  const renderDateRow = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating' className='forced-floating'>
          Date
        </IonLabel>
        <DatePicker
          value={editVM.localPlannedDateTime}
          onChange={e => editVM.setPlannedDateTime(e)}
          format='dddd MMM D'
        />
      </IonItem>
    )
  }

  const renderDescriptionRow = () => {
    return (
      <IonItem lines={'inset'}>
        <IonLabel position='floating'>Description</IonLabel>
        <IonInput
          type='text'
          value={editVM.description}
          onIonChange={(e: any) => editVM.setDescription(e.target.value)}
          onKeyPress={e => e.charCode === 13 && editVM.isValid && editVM.save()}
          maxlength={200}
        />
      </IonItem>
    )
  }

  return (
    <div id='DetailsForm'>
      <IonGrid>
        <IonRow>
          <IonCol sizeMd='6' sizeXs='6'>
            {renderDateRow()}
          </IonCol>
          <IonCol sizeMd='6' sizeXs='6'>
            {renderMealCategoryRow()}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol sizeXs='12'>{renderDescriptionRow()}</IonCol>
        </IonRow>
      </IonGrid>
      <PlannedTimeRow editVM={editVM} />
    </div>
  )
}

export default inject('mealsStore')(observer(DetailsForm))
