import { IActionsStore } from '@elexient/elexiapp.bits.shared'
import { observable, action, computed, makeObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'
import { deserialize } from 'serializr'
import { RootStore } from '../../stores/RootStore'
import { Action } from '../aggregate/Action'
import { ActionsService } from '../service/ActionsService'

export class ActionsStore implements IActionsStore {
  private rootStore: RootStore

  constructor(rootStore: RootStore) {
    makeObservable(this)
    makePersistable(this, { name: 'ActionsStore', properties: ['actions'] }).then(
      action((st) => this.onHydrationCompleted(st?.isHydrated))
    )
    this.rootStore = rootStore
    this.actionsSvc = new ActionsService(rootStore, this)
  }

  @action
  public onHydrationCompleted(isHydated?: boolean) {
    this.actions = observable.array(this.actions.map((e) => makeObservable(deserialize(Action, e))))
    // this.setRecords(this.actions)
    this.isHydrated = Boolean(isHydated)
    if (process.env.NODE_ENV === 'test') this.isHydrated = true
  }

  @observable public actions: Action[] = []
  @observable public isHydrated: boolean = false
  public actionsSvc: ActionsService = null

  @computed
  public get hasActions() {
    return this.actions.length !== 0
  }

  public hasPendingActions(itemType: string) {
    const pendingAction = this.actions.find((e) => e.itemType === itemType.toLowerCase())
    return Boolean(pendingAction)
  }

  public hasPendingActionsByTypeId(type: number) {
    return this.actions.find((e) => e.type === type)
  }

  @action
  public async addAction(type: number, dto: any) {
    const newAction = Action.create(type, dto)
    // remove existing
    while (true) {
      const idx = this.actions.findIndex((e) => e.type === type && e.key === newAction.key)
      if (idx > -1) this.actions.splice(idx, 1)
      else break
    }
    this.actions.push(newAction)
    setTimeout(async () => await this.actionsSvc.processActions(), 500)
  }

  @action
  public clearData() {
    this.actions = []
  }
}
